import {useCallback} from "react";
import { useNavigate } from "react-router-dom";
import styles from "./BigData.module.css";
import Navbar from "../components/Navbar";
import Jumpscare from "../addons/Jumpscare";
import Carousel from "../addons/Carousel";
import {Tooltip, useMediaQuery} from "@mui/material";
import {useSetPopupState} from "../addons/ContactPopup";
import Footer from "../components/Footer";
import {useExamplesLinks} from "../addons/scalaExamples";
import AnimatedExamples from "../components/AnimatedExamples";

const shotsData = [
  {
    src: "/shot-1.svg",
    alt: "Screenshot",
    className: styles.zrzutEkranu20240226O172
  },
]

const technologiesData = [
  {
    src: "/Logos2.svg",
    alt: "Companies Banner",
    className: styles.technologiesBanner
  }
]

const servicesData = [
  {
    title: 'ETL and Data Integration services',
    description: 'We handle ETL processes to prepare and transform data for analysis.'
  },
  {
    title: 'Advanced Big Data Analytics and Management',
    description: 'Scalable solutions with Hadoop, Spark, IoT integration.'
  },
  {
    title: 'Business Intelligence and Data Visualization',
    description: 'Intuitive dashboards, reports, interactive visualizations.'
  },
  {
    title: 'Machine Learning and AI',
    description: 'Predictive modeling, RPA automation, AI chatbots.'
  }
]

const expertiseData = [
  {
    title: 'Expertise in Hadoop, Spark, and More',
    description: 'Our team has extensive knowledge and experience in various big data technologies and platforms, including Hadoop, Spark, and more.'
  },
  {
    title: 'Optimize Your Data with Cutting-Edge Solutions',
    description: 'We offer innovative solutions to help you harness the power of big data and maximize its potential for your business.'
  },
  {
    title: 'Unlock the Value of Your Data with Advanced Analytics',
    description: 'Our advanced analytics capabilities enable you to gain valuable insights from your big data and make data-driven decisions.'
  }
]

const animatedIcons = [
  {
    name: 'Kafka',
    src: '/kafka_hex.svg',
    top: 78.4,
    left: 73
  },
  {
    name: 'Python',
    src: '/python_hex.svg',
    top: 36.5,
    left: 207
  },
  // {
  //   name: 'Ilum',
  //   src: '/ilum_hex.svg',
  //   top: 158.15,
  //   left: 168
  // },
  {
    name: 'Spark',
    src: '/spark_hex.svg',
    top: 158.15,
    left: 168
  },
  {
    name: 'Hadoop',
    src: '/hadoop_hex.svg',
    top: 116.43,
    left: 279
  },
  {
    name: 'AWS',
    src: '/aws_hex.svg',
    top: 193.3,
    left: 62.88
  },
  {
    name: 'Scala',
    src: '/scala_hex.svg',
    top: 273.23,
    left: 74.92
  },
  // {
  //   name: 'Streamnest',
  //   src: '/circle_hex.svg',
  //   top: 263.77,
  //   left: 208.74
  // },
  {
    name: 'Iceberg',
    src: '/iceberg_hex.svg',
    top: 263.77,
    left: 208.74
  },
  {
    name: 'Redis',
    src: '/box_hex.svg',
    top: 263.77,
    left: 327.69
  }
]

const bigDataSolutions = [
  "Data Analysis and Processing",
  "Machine Learning and AI",
  "Big Data System Deployments",
  "Business Intelligence",
  "Data Visualization"
]

const BigData = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  const openPopup = useSetPopupState()

  const mediaQuery1120 = useMediaQuery('(max-width: 1123px)')
  const mediaQuery835 =  useMediaQuery('(max-width: 835px)')
  const mediaQuery860 =  useMediaQuery('(max-width: 863px)')
  const mediaQuery400 =  useMediaQuery('(max-width: 399px)')
  const mediaQuery530 =  useMediaQuery('(max-width: 527px)')

  const examplesLinks = useExamplesLinks()

  return (
    <>
      <div className={styles.bigData}>
        <section className={styles.head}>
          <Navbar
            frame11965="/frame-11965@2x.png"
            onLogoClick={onLogoClick}
          />
          <div className={styles.header}>
            <div className={styles.content8}>
              <div className={styles.container1}>
                <div className={styles.content9}>
                  <Jumpscare transitionTime={0.5}>
                    <h1 className={styles.mediumLengthHeroContainer}>
                      <span>{`Use the `}</span>
                      <span className={styles.power}>Power</span>
                      <span>{` of `}</span>
                      <span className={styles.power}>Big Data</span>
                      <span> Solutions</span>
                    </h1>
                  </Jumpscare>
                </div>
              </div>
              <div className={styles.column2}>
                <div className={styles.column3}>
                  { bigDataSolutions.map((solution, index) => (
                    <div className={styles.pageTwo} key={index}>
                      <ul className={styles.dataAnalysisAndProcessing}>
                        <Jumpscare delay={mediaQuery835 ? 0 : (index + 1) * 0.25}>
                          <li>{solution}</li>
                        </Jumpscare>
                      </ul>
                    </div>
                  )) }
                </div>
              </div>
            </div>
            <div className={styles.content10}>
              {examplesLinks.length <= 0 ? (
                <Carousel
                  data={shotsData}
                  dataRepeat={5}
                  itemWidth={716}
                  ItemComponent={'img'}
                />
              ) : (
                <AnimatedExamples
                  links={examplesLinks}
                />
              )}
            </div>
          </div>
        </section>
        <section className={styles.content11}>
          <div className={styles.frame12048container}>
            <div className={styles.content12}>
              <div className={styles.content13}>
                <div className={styles.headingWrapper}>
                  <Jumpscare>
                    <h2 className={styles.heading}>
                      {mediaQuery400 ? <>
                        We harness the force of big data
                      </> : <>
                        We harness the dynamic force of various big data solutions
                        to unlock limitless possibilities.
                      </>}
                    </h2>
                  </Jumpscare>
                </div>
              </div>
            </div>
            <div className={styles.content14}>
              <div className={styles.headingWrapper}>
                <Jumpscare>
                  <h2 className={styles.heading1}>
                    Experience real-time interaction with Spark sessions from UI
                    and API, seamless debugging, all within a unified space, and
                    benefit from managed Spark clusters using Ilum and Streamnest.
                  </h2>
                </Jumpscare>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.content15}>
          <Carousel
            data={technologiesData}
            dataRepeat={2}
            itemWidth={1440}
            ItemComponent={'img'}
          />
        </div>
        <section className={styles.services1}>
          <div className={styles.container2}>
            <div className={styles.container3}>
              <div className={styles.container4}>
                <div className={styles.row1}>
                  { servicesData.map(({ title, description }, index) => (
                    <div className={styles.contentTop} key={index}>
                      <Jumpscare delay={mediaQuery860 ? 0 : index * 0.25}>
                        <div className={styles.content17}>
                          <div className={styles.content17}>
                            <div className={styles.services3}>
                              <img
                                className={styles.servicesChild}
                                alt=""
                                src="/group-11604.svg"
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.content18}>
                          <div className={styles.heading2}>
                            {title}
                          </div>
                          <div className={styles.text}>
                            {description}
                          </div>
                        </div>
                      </Jumpscare>
                    </div>
                  )) }
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.bigData2}>
          <div className={styles.content25}>
            <div className={styles.row2}>
              { expertiseData.map(({ title, description }, index) => (
                <div className={styles.column4} key={index}>
                  <Jumpscare delay={mediaQuery1120 ? 0 : index * 0.5}>
                    <div className={styles.content26}>
                      <img
                        className={styles.letsIconscheckFill}
                        alt=""
                        src="/tick.svg"
                      />
                      <b className={styles.heading6}>
                        {title}
                      </b>
                      <div className={styles.text4}>
                        {description}
                      </div>
                    </div>
                  </Jumpscare>
                </div>
              )) }
            </div>
            <div className={styles.row3}>
              <Jumpscare variant={'left'}>
                <button className={styles.cardStart} onClick={() => openPopup({
                  title: 'Start a project with us',
                  description: 'Let\'s talk about your business needs'
                })}>
                  <div className={styles.content29}>
                    <div className={styles.content26}>
                      <div className={styles.content30}>
                        <button className={styles.button15}>
                          <img
                            className={styles.iconChevronDown}
                            alt=""
                            src="/solarmaparrowrightbroken.svg"
                          />
                        </button>
                      </div>
                      <div className={styles.content31}>
                        <div className={styles.heading9}>
                          <p className={styles.machineLearning}>
                            Start a project
                          </p>
                          <p className={styles.machineLearning}>with us</p>
                        </div>
                        <div className={styles.text7}>
                          Let’s talk. Tell us what you need and we will support
                          your business and deliver the best solutions.
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </Jumpscare>
              <div className={styles.inTodaysDataDrivenContainer}>
                <Jumpscare variant={'bottom'}>
                  <span
                    className={styles.inTodaysDataDriven}
                  >{`In today's data-driven world, businesses rely on robust solutions to extract insights, streamline operations, and enhance decision-making processes. `}</span>
                  <span className={styles.ourComprehensiveSuite}>
                    Our comprehensive suite of data solutions encompasses various
                    aspects, from data analysis and processing to the deployment
                    of advanced technologies like Big Data systems, business
                    intelligence, and artificial intelligence.
                  </span>
                </Jumpscare>
              </div>
            </div>
          </div>
        </div>
        <section className={styles.content32}>
          <div className={styles.content33}>
            <div className={styles.content34}>
              <div className={styles.row4}>
                <Jumpscare>
                  <h2 className={styles.heading10}>
                    Transforming Data into Insights and Intelligence
                  </h2>
                </Jumpscare>
                <Jumpscare>
                  <div className={styles.text8}>
                    At our firm, we specialize in ETL processes, extracting,
                    transforming, and loading data to provide comprehensive
                    analysis and reporting solutions. With our advanced Big Data
                    and AI/ML technologies, we help businesses turn raw data into
                    valuable insights and actionable intelligence.
                  </div>
                </Jumpscare>
                <Jumpscare variant={'left'}>
                  <div className={styles.text9}>
                    <ul className={styles.dataAnalysisAndProcessing}>
                      <li>Data-driven decision-making for better business outcomes</li>
                    </ul>
                  </div>
                </Jumpscare>
                <Jumpscare variant={'left'}>
                  <div className={styles.text9}>
                    <ul className={styles.dataAnalysisAndProcessing}>
                      <li>Big Data and AI/ML Solutions</li>
                    </ul>
                  </div>
                </Jumpscare>
                <Jumpscare variant={'left'}>
                  <div className={styles.text9}>
                    <ul className={styles.dataAnalysisAndProcessing}>
                      <li>Transforming Data into Insights and Intelligence</li>
                    </ul>
                  </div>
                </Jumpscare>
              </div>
              <div className={styles.row5}>
                <h2 className={styles.heading11}>
                  <Jumpscare>Crafting a Powerful Data Strategy for Success</Jumpscare>
                </h2>
                <div className={styles.text12}>
                  <Jumpscare>In today's data-driven business environment, a well-executed
                    data strategy is absolutely essential. A strong data strategy
                    not only ensures the integrity and security of your data but
                    also unlocks its full potential value. At our firm, we
                    specialize in crafting and executing robust data strategies
                    that empower businesses to make informed decisions, achieve
                    operational excellence, and drive sustainable success.</Jumpscare>
                </div>
              </div>
            </div>
            <div className={styles.animatedMapContainer}>
              {/*<Jumpscare*/}
              {/*  variant={'right'}*/}
              {/*  transitionTime={0.5}*/}
              {/*  transitionDistance={100}*/}
              {/*  threshold={0.35}*/}
              {/*>*/}
                <img
                  className={styles.contentChild}
                  alt=""
                  src="/Hex.svg"
                />
              {/*</Jumpscare>*/}
              { animatedIcons.map(({ name, src, top, left }, index) => (
                <Tooltip
                  key={index}
                  title={name}
                  enterTouchDelay={0}
                >
                  <div style={{
                    position: 'absolute',
                    top: `${top}px`,
                    left: `${left}px`,
                  }}>
                    <Jumpscare
                      variant='bottom'
                      transitionTime={0.25}
                      transitionDistance={20}
                      threshold={0.9}
                    >
                      <img
                        className={styles.animatedMapIcon}
                        src={src}
                      />
                    </Jumpscare>
                  </div>
                </Tooltip>
              )) }
            </div>
          </div>
        </section>
        <div className={styles.bigData3}>
          <div className={styles.sectionTitle}>
            <div className={styles.subheading}>Efficient</div>
            <div className={styles.content35}>
              <b className={styles.heading12}>
                <Jumpscare>Specializing in Big Data Technologies and Platforms</Jumpscare>
              </b>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.content36}>
              <Jumpscare delay={0} stretch>
                <div className={styles.content26}>
                  <img
                    className={styles.eosIconsbigData}
                    alt=""
                    src="/eos-icons_big-data.svg"
                  />
                  <div className={styles.content31}>
                    <h5 className={styles.heading13}>
                      Big Data Mastery
                    </h5>
                  </div>
                </div>
              </Jumpscare>
            </div>
            <div className={styles.content36}>
              <Jumpscare delay={mediaQuery530 ? 0 : 0.5} stretch>
                <div className={styles.content26}>
                  <img
                    className={styles.eosIconsbigData}
                    alt=""
                    src="/academicons_open-data.svg"
                  />
                  <div className={styles.content31}>
                    <h5 className={styles.heading13}>
                      Data Solutions Specialization
                    </h5>
                  </div>
                </div>
              </Jumpscare>
            </div>
          </div>
        </div>
        <section className={styles.cta}>
          <div className={styles.column7}>
            <div className={styles.row4}>
              <h2 className={styles.heading15}>
                <Jumpscare>
                  Transforming businesses through innovative technology
                </Jumpscare>
              </h2>
              <div className={styles.text15}>
                <Jumpscare>
                  We are here to help you optimize your IT infrastructure and find
                  the perfect solutions for your business.
                </Jumpscare>
              </div>
            </div>
            <Jumpscare>
              <div className={styles.actions1}>
                <button className={styles.button16} onClick={() => openPopup({
                  title: 'Transform Your Business',
                  description: 'We are here to help you optimize your IT infrastructure'
                })}>
                  <div className={styles.button1}>Contact Us</div>
                </button>
              </div>
            </Jumpscare>
          </div>
        </section>
        <Footer
          frame11965='/frame-1196510@2x.png'
          onLogoClick={onLogoClick}
          onIconLinkedInClick={onIconLinkedInClick}
        />
      </div>
    </>
  );
};

export default BigData;
