import Services from "../components/Services";
import Layout from "../components/Layout";
import About from "../components/About";
import styles from "./Mentoring.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";
import {openMenteeForm, openMentorForm} from "../addons/mentoring";

const Mentoring = () => {
  const mediaQuery1435 = useMediaQuery('(max-width: 1435px)')

  return (
    <>
      <div className={styles.mentoring}>
        <Jumpscare stretch variant="left">
          <section className={styles.header1Web}>
            <div className={styles.column}>
              <div className={styles.columnInner}>
                <div className={styles.mediumLengthHeroHeadlineGoParent}>
                  <Jumpscare delay={0.5}>
                    <h1 className={styles.mediumLengthHero}>{`BIG Data  `}</h1>
                  </Jumpscare>
                  <div className={styles.ellipseWrapper}>
                    <Jumpscare delay={0.75}>
                      <div className={styles.frameChild} />
                    </Jumpscare>
                  </div>
                  <Jumpscare delay={1}>
                    <h1
                      className={styles.mediumLengthHero1}
                    >{`BIG Challenges  `}</h1>
                  </Jumpscare>
                </div>
              </div>
              <Jumpscare delay={1.5}>
                <div className={styles.secondColumn}>
                  <div className={styles.circleParent}>
                    <div className={styles.circle}>
                      <img
                        className={styles.icon}
                        loading="lazy"
                        alt=""
    /* src="/1@2x.png" */
    src="/circle.svg"
                      />
                      <h1
                        className={styles.mediumLengthHero2}
                      >{`Mentoring `}</h1>
                    </div>
                    <div className={styles.mediumLengthHeroHeadlineGoWrapper}>
                      <h1 className={styles.mediumLengthHero3}>Programm</h1>
                    </div>
                  </div>
                </div>
              </Jumpscare>
              <div className={styles.thirdColumn}>
                <Jumpscare stretch delay={2}>
                  <div className={styles.title}>
                    <div className={styles.mediumLengthHeroHeadlineGoContainer}>
                      <h1 className={styles.mediumLengthHero4}>for BIG REAL</h1>
                    </div>
                    <div className={styles.element}>
                      <img
                        className={styles.highlightYellowIcon}
                        loading="lazy"
                        alt=""
                        src="/highlight-yellow.svg"
                      />
                      <h1 className={styles.mediumLengthHero5}>Success</h1>
                    </div>
                  </div>
                </Jumpscare>
                <div className={styles.container}>
                  <div className={styles.actions}>
                    <div className={styles.ghostPlace}>
                      <div
                        className={styles.button}
                        onClick={openMenteeForm}
                      >
                        <div className={styles.button1}>
                          <span>{`Sign Up as a `}</span>
                          <span className={styles.mentee}>Mentee</span>
                        </div>
                      </div>
                      <div
                        className={styles.button2}
                        onClick={openMentorForm}
                      >
                        <div className={styles.button3}>
                          <span>{`I Want to be a `}</span>
                          <span className={styles.mentor}><b>Mentor</b></span>
                        </div>
                      </div>
                    </div>
                    <Jumpscare delay={2.75}>
                      <img
                        className={styles.joinNowIconClack}
                        alt=""
                        src="/join-now-icon-clack.svg"
                      />
                    </Jumpscare>
                  </div>
                  <div className={styles.actions1}>
                    <div className={styles.buttons}>
                      <Jumpscare delay={2.25}>
                        <button
                          className={styles.button4}
                          onClick={openMenteeForm}
                        >
                          <div className={styles.button5}>
                            <span
                              className={styles.signUpAs}
                            >{`Sign Up as a `}</span>
                            <span className={styles.mentee1}>Mentee</span>
                          </div>
                        </button>
                      </Jumpscare>
                      <Jumpscare delay={2.5}>
                        <button
                          className={styles.button6}
                          onClick={openMentorForm}
                        >
                          <div className={styles.button7}>
                            I Want to be a <b>Mentor</b>
                          </div>
                        </button>
                      </Jumpscare>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Jumpscare>
        <div className={styles.header1Mobile}>
          <div className={styles.column1}>
            <div className={styles.contentParent}>
              <div className={styles.content}>
                <div className={styles.frameParent}>
                  <div className={styles.mediumLengthHeroHeadlineGoGroup}>
                    <Jumpscare>
                      <div
                        className={styles.mediumLengthHero6}
                      >{`BIG Data`}</div>
                    </Jumpscare>
                    <Jumpscare delay={0.25}>
                      <div className={styles.frameItem} />
                    </Jumpscare>
                  </div>
                  <div className={styles.mediumLengthHeroHeadlineGoFrame}>
                    <Jumpscare delay={0.5}>
                      <div
                        className={styles.mediumLengthHero7}
                      >{`BIG Challenges  `}</div>
                    </Jumpscare>
                  </div>
                </div>
              </div>
              <Jumpscare delay={1}>
                <div className={styles.frameGroup}>
                  <div className={styles.parent}>
                    <img className={styles.icon1} alt="" src="/circle.svg" />
                    <div className={styles.mediumLengthHero8}>{`Mentoring`}</div>
                  </div>
                  <div className={styles.mediumLengthHero9}>Programm</div>
                </div>
              </Jumpscare>
              <Jumpscare delay={1.5}>
                <div className={styles.frameContainer}>
                  <div className={styles.frameDiv}>
                    <div className={styles.mediumLengthHero10}>for BIG REAL</div>
                  </div>
                  <div className={styles.vectorParent}>
                    <img className={styles.vectorIcon} alt="" /* src="/vector.svg" */ src="/highlight-yellow.svg" />
                    <div className={styles.mediumLengthHero11}>Success</div>
                  </div>
                </div>
              </Jumpscare>
            </div>
            <div className={styles.actions2}>
              <Jumpscare delay={2}>
                <div
                  className={styles.button8}
                  onClick={openMenteeForm}
                >
                  <div className={styles.button9}>Sign Up as a <b>Mentee</b></div>
                </div>
              </Jumpscare>
              <Jumpscare delay={2.25}>
                <div
                  className={styles.button10}
                  onClick={openMentorForm}
                >
                  <div className={styles.button11}>I Want to be a <b>Mentor</b></div>
                </div>
              </Jumpscare>
              <Jumpscare delay={2.5}>
                <img
                  className={styles.mobileVIcon}
                  alt=""
                  src="/mobile-v@2x.png"
                />
              </Jumpscare>
            </div>
          </div>
        </div>
        <section className={styles.joinUs}>
          <div className={styles.sectionTitle}>
            <div className={styles.content1}>
              <Jumpscare>
                <h1 className={styles.heading}>
                  What's the deal with joining us?
                </h1>
              </Jumpscare>
              <Jumpscare threshold={0.7}>
                <div className={styles.migrationDescription}>
                  <p className={styles.bigDataBigChallengesB}>
                    <span className={styles.bigDataBigChallengesB1}>
                      <span className={styles.big}>BIG</span>
                      <span className={styles.data}>{` Data –﻿ `}</span>
                      <span className={styles.big1}>BIG</span>
                      <span
                        className={styles.challenges}
                      >{` Challenges – `}</span>
                      <span className={styles.big2}>BIG</span>
                      <span
                        className={styles.mentoringProgram}
                      >{` Mentoring Program  for your `}</span>
                      <span className={styles.big3}>BIG</span>
                      <span className={styles.real}>{` REAL `}</span>
                      <span className={styles.success}>Success</span>
                    </span>
                    <span className={styles.span}>
                      <span className={styles.span1}>{` `}</span>
                    </span>
                  </p>
                  <p className={styles.isAUniqueMentoringProgram}>
                    <span className={styles.isAUniqueMentoringProgram1}>
                      <span className={styles.isAUnique}>
                        is a UNIQUE Mentoring Program on the market for
                        professionals working in the area of DATA! The Mentoring
                        Program includes all direction related with DATA: data
                        management, data analytics, data collection, building ML
                        models, data compliance, master data and others.
                      </span>
                    </span>
                  </p>
                </div>
              </Jumpscare>
            </div>
          </div>
          <Jumpscare>
            <h1 className={styles.heading1}>
              <span>FREE</span>
              <span
                className={styles.mentoringProgramFor}
              >{` Mentoring Program for `}</span>
              <span>DATA</span>
              <span className={styles.world}> world !</span>
            </h1>
          </Jumpscare>
          <div className={styles.row}>
            <Jumpscare threshold={0.7}>
              <div className={styles.column2}>
                <img
                  className={styles.iconamooncheckBold}
                  loading="lazy"
                  alt=""
                  src="/iconamooncheckbold.svg"
                />
                <div className={styles.content2}>
                  <div className={styles.heading2}>
                    <span
                      className={styles.the1stEdition}
                    >{`The 1st edition `}</span>
                    <span
                      className={styles.ofTheMentoring}
                    >{`of the Mentoring Program will be in Polish  `}</span>
                  </div>
                </div>
              </div>
            </Jumpscare>
            <Jumpscare threshold={0.7} delay={mediaQuery1435 ? 0 : 0.5}>
              <div className={styles.column3}>
                <div className={styles.content3}>
                  <img
                    className={styles.iconamooncheckBold1}
                    loading="lazy"
                    alt=""
                    src="/iconamooncheckbold.svg"
                  />
                  <div className={styles.heading3}>
                    <span className={styles.monthsOfGrowth}>
                      3 months of growth
                    </span>
                    <span> during the Mentoring Program</span>
                  </div>
                </div>
              </div>
            </Jumpscare>
            <Jumpscare threshold={0.7} delay={mediaQuery1435 ? 0 : 1}>
              <div className={styles.column4}>
                <img
                  className={styles.iconamooncheckBold2}
                  loading="lazy"
                  alt=""
                  src="/iconamooncheckbold.svg"
                />
                <div className={styles.content4}>
                  <div className={styles.heading4}>
                    <span className={styles.min6Meetings}>Min 6 meetings</span>
                    <span>{` Mentee & Mentor (online or onsite)`}</span>
                  </div>
                </div>
              </div>
            </Jumpscare>
          </div>
          <div className={styles.content5}>
            <div className={styles.container1}>
              <div className={styles.container2}>
                <Jumpscare threshold={0.7} variant="left">
                  <div className={styles.content6}>
                    <img
                      className={styles.maskGroup2Icon}
                      loading="lazy"
                      alt=""
                      src="/mask-group2@2x.png"
                    />
                    <div className={styles.title1}>
                      <div className={styles.heading5}>
                        Mentoring Recruitment
                      </div>
                      <div className={styles.heading6}>
                        Ends on: Aug 30, 2024
                      </div>
                    </div>
                  </div>
                </Jumpscare>
                <Jumpscare threshold={0.7} variant="left">
                  <div className={styles.content7}>
                    <img
                      className={styles.maskGroupIcon}
                      loading="lazy"
                      alt=""
                      src="/mask-group@2x.png"
                    />
                    <div className={styles.title2}>
                      <div className={styles.heading7}>Inauguration</div>
                      <div className={styles.heading8}>
                        Sep 13, 2024 (online)
                      </div>
                    </div>
                  </div>
                </Jumpscare>
              </div>
              <div className={styles.line}>
                <img
                  className={styles.vectorIcon1}
                  loading="lazy"
                  alt=""
                  src="/vector-1.svg"
                />
              </div>
              <div className={styles.container3}>
                <Jumpscare threshold={0.7} variant="right">
                  <div className={styles.content8}>
                    <img
                      className={styles.maskGroup1Icon}
                      loading="lazy"
                      alt=""
                      src="/mask-group1@2x.png"
                    />
                    <div className={styles.title3}>
                      <div className={styles.heading9}>
                        Mentoring Recruitment
                      </div>
                      <div className={styles.heading10}>
                        Start: Jul 10, 2024
                      </div>
                    </div>
                  </div>
                </Jumpscare>
                <Jumpscare threshold={0.7} variant="right">
                  <div className={styles.content9}>
                    <img
                      className={styles.maskGroup3Icon}
                      loading="lazy"
                      alt=""
                      src="/mask-group3@2x.png"
                    />
                    <div className={styles.title4}>
                      <div className={styles.heading11}>Results</div>
                      <div className={styles.heading12}>Sep 6, 2024</div>
                    </div>
                  </div>
                </Jumpscare>
              </div>
              <img
                className={styles.arrowIcon}
                loading="lazy"
                alt=""
                src="/arrow.svg"
              />
            </div>
          </div>
        </section>
        <Services />
        <Layout />
        <About />
        <Services />
      </div>
    {/* isPopUpContactOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpContact}
        >
          <PopUpContact onClose={closePopUpContact} />
        </PortalPopup>
      ) */}
    </>
  );
};

export default Mentoring;
