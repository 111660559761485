import styles from "./StyleLinkSmallFalseDark2.module.css";

const StyleLinkSmallFalseDark2 = ({ button, iconRelume }) => {
  return (
    <button className={styles.stylelinkSmallfalseDark}>
      <div className={styles.button}>{button}</div>
      <img className={styles.iconRelume} alt="" src={iconRelume} />
    </button>
  );
};

export default StyleLinkSmallFalseDark2;
