import { useMemo } from "react";
import styles from "./GlobalReachCard.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";

const GlobalReachCard = ({
  imageDescription,
  featureDescription,
  businessDescription,
  imageDimensions,
  featureIcon,
  technologyOptimizationDes,
  propFlexDirection,
  propFlexWrap,
  propFlex,
  propAlignSelf,
  propOverflow,
  propFlex1,
  propAlignSelf1,
  propOverflow1,
}) => {
  const cardStyle = useMemo(() => {
    return {
      flexDirection: propFlexDirection,
      flexWrap: propFlexWrap,
    };
  }, [propFlexDirection, propFlexWrap]);

  const contentStyle = useMemo(() => {
    return {
      flex: propFlex,
      alignSelf: propAlignSelf,
    };
  }, [propFlex, propAlignSelf]);

  const iconGlobeStyle = useMemo(() => {
    return {
      overflow: propOverflow,
    };
  }, [propOverflow]);

  const content1Style = useMemo(() => {
    return {
      flex: propFlex1,
      alignSelf: propAlignSelf1,
    };
  }, [propFlex1, propAlignSelf1]);

  const iconMedalStyle = useMemo(() => {
    return {
      overflow: propOverflow1,
    };
  }, [propOverflow1]);

  // const mediaQuery527 = useMediaQuery('(max-width: 527px)')
  const mediaQuery527 = useMediaQuery('(max-width: 1007px)')

  return (
    <div className={styles.card} style={cardStyle}>
      <div className={styles.content} style={contentStyle}>
        <Jumpscare>
          <div className={styles.contentTop}>
          <img
            className={styles.iconGlobe}
            alt=""
            src={imageDescription}
            style={iconGlobeStyle}
          />
          <div className={styles.content1}>
            <h5 className={styles.heading}>{featureDescription}</h5>
            <div className={styles.text}>{businessDescription}</div>
          </div>
        </div>
        </Jumpscare>
      </div>
      <div className={styles.content} style={content1Style}>
        <Jumpscare delay={mediaQuery527 ? 0 : 0.5}>
          <div className={styles.contentTop}>
          <img
            className={styles.iconGlobe}
            alt=""
            src={imageDimensions}
            style={iconMedalStyle}
          />
          <div className={styles.content1}>
            <h5 className={styles.heading}>{featureIcon}</h5>
            <div className={styles.text}>{technologyOptimizationDes}</div>
          </div>
        </div>
        </Jumpscare>
      </div>
    </div>
  );
};

export default GlobalReachCard;
