import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import StylePrimarySmallFalseDa from "./StylePrimarySmallFalseDa";
import styles from "./ContactForm.module.css";
import {useCallback, useEffect, useState} from "react";
import useContactUsForm from "../addons/contactUs";
import Jumpscare from "../addons/Jumpscare";

const ContactForm = () => {
  // actually here I will test my "magic hook"
  const { state, handlers } = useContactUsForm()

  return (
    <div className={styles.contactUs} data-scroll-to="contactUsContainer">
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.sectionTitle}>
            <div className={styles.subheading}>Tagline</div>
            <div className={styles.content1}>
              <b className={styles.heading}>
                <Jumpscare variant={'left'}>Contact us</Jumpscare>
              </b>
              <div className={styles.text}>
                <Jumpscare variant={'left'} delay={0.25}>
                  We're here to help. Reach out to us today.
                </Jumpscare>
              </div>
            </div>
          </div>
          <div className={styles.form}>
            <Jumpscare stretch variant={'left'}>
              <div className={styles.sectionTitle}>
                <div className={styles.name}>Name</div>
                <TextField
                  value={state.name}
                  onChange={handlers.name}
                  className={styles.fname}
                  color="primary"
                  label="Enter name"
                  variant="outlined"
                  sx={{"& .MuiInputBase-root": {height: "58px"}}}
                />
              </div>
            </Jumpscare>
            <Jumpscare stretch variant={'left'}>
              <div className={styles.sectionTitle}>
                <div className={styles.name}>Email</div>
                <TextField
                  value={state.email}
                  onChange={handlers.email}
                  className={styles.fname}
                  color="primary"
                  label="Enter email"
                  variant="outlined"
                  sx={{"& .MuiInputBase-root": {height: "58px"}}}
                />
              </div>
            </Jumpscare>
            <Jumpscare stretch variant={'left'}>
              <div className={styles.sectionTitle}>
                <div className={styles.name}>Message</div>
                <TextField
                  value={state.message}
                  onChange={handlers.message}
                  className={styles.fname2}
                  color="primary"
                  rows={6}
                  label="Enter message"
                  variant="outlined"
                  multiline
                />
              </div>
            </Jumpscare>
            <Jumpscare variant={'left'}>
              <div className={styles.checkbox}>
                <div className={styles.container1}>
                  <FormControlLabel
                    className={styles.rectangleFormcontrollabel}
                    label=""
                    control={<Checkbox value={state.accept} onClick={handlers.accept} color="primary"/>}
                  />
                </div>
                <div className={styles.label}>I accept the Terms</div>
              </div>
            </Jumpscare>
            <Jumpscare variant={'left'}><StylePrimarySmallFalseDa
              onClick={handlers.submit}
              button="Send"
              stylePrimarySmallFalseDaAlignSelf="unset"
            /></Jumpscare>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.contactInform}>
            <div className={styles.toRequestA}>
              <Jumpscare variant={'right'}>To request a quote fill our form or contact us directly. Our team
                will have a look at it and will get back to you as soon as
                possible.</Jumpscare>
            </div>
          </div>
          <div className={styles.contactInformations}>
            <Jumpscare variant={'right'}>
              <div className={styles.mail}>
                <img className={styles.emailIcon} alt="" src="/email.svg"/>
                <div className={styles.label}>kontakt@sofixit.pl</div>
              </div>
            </Jumpscare>
            <Jumpscare variant={'right'}>
              <div className={styles.phone}>
                <img className={styles.phoneIcon} alt="" src="/phone.svg"/>
                <div className={styles.label}>+48 71 707 00 98</div>
              </div>
            </Jumpscare>
            <Jumpscare variant={'right'}>
              <div className={styles.phone}>
                <img className={styles.phoneIcon} alt="" src="/frame-43.svg"/>
                <div className={styles.mainOfficeWrocawContainer}>
                  <p className={styles.mainOffice}>Main office</p>
                  <p className={styles.mainOffice}>
                    Wrocław, ul. Powstańców Śląskich 9, 53-332, Poland
                  </p>
                </div>
              </div>
            </Jumpscare>
          </div>
          <Jumpscare variant={'right'}>
            <img className={styles.illustrIcon} alt="" src="/illustr.svg"/>
          </Jumpscare>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
