import { useMemo } from "react";
import styles from "./StyleSecondarySmallTrueD.module.css";

const StyleSecondarySmallTrueD = ({
  button,
  styleSecondarySmallTrueDBackgroundColor,
  buttonDisplay,
  onClick = (() => {})
}) => {
  const styleSecondarySmallTrueDStyle = useMemo(() => {
    return {
      backgroundColor: styleSecondarySmallTrueDBackgroundColor,
    };
  }, [styleSecondarySmallTrueDBackgroundColor]);

  const button4Style = useMemo(() => {
    return {
      display: buttonDisplay,
    };
  }, [buttonDisplay]);

  return (
    <div
      className={styles.stylesecondarySmalltrueD}
      style={styleSecondarySmallTrueDStyle}
      onClick={onClick}
    >
      <div className={styles.button} style={button4Style}>
        {button}
      </div>
    </div>
  );
};

export default StyleSecondarySmallTrueD;
