import { memo, useCallback } from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Jumpscare from '../addons/Jumpscare.js'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Podcast from '../components/Podcast'
import {Tooltip, useMediaQuery} from '@mui/material'

const Root = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #1C1C1C;
`

const HeroContainer = styled.div`
  color: white;
  padding: 80px 64px 80px 64px;
  height: 554px;
  background-image: url(/happy-people.png);
  background-position: center;
  background-size: cover;
  background-color: #2A2F36CC;
  background-blend-mode: multiply;
  display: grid;
  place-items: center;
  @media (max-width: 510px) {
    padding: 0;
  }
`

const HeroContainerInner = styled.div`
  padding: 80px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeroTopText = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  gap: 24px;
  @media (max-width: 850px) {
    font-size: 24px;
    gap: 16px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    gap: 12px;
    line-height: 100%;
    white-space: nowrap;
  }
`

const FatDot = styled.div`
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 100%;
`

const HeroMainText = styled.div`
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 80px;
  font-weight: 700;
  line-height: 96px;
  text-align: center;
  @media (max-width: 850px) {
    font-size: 60px;
  }
  @media (max-width: 700px) {
    line-height: 100%;
    margin-top: 12px;
    font-size: 42px;
  }
  & > img {
    position: absolute;
    width: 400px;
    height: auto;
    top: -15px;
    left: 225px;
    @media (max-width: 850px) {
      width: 300px;
      top: 0;
      left: 170px;
    }
    @media (max-width: 700px) {
      width: 200px;
      top: -10px;
      left: 120px;
    }
  }
`

const HeroButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * + * {
    margin-top: 24px;
  }
`

const HeroButton = styled.button`
  width: 202px;
  height: 48px;
  border: 1px solid white;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  transition: 0.2s all ease;
  &:hover {
    background: rgba(0,0,0,0.8);
    scale: 1.02;
  }
  img {
    width: 24px;
    height: 24px;
  }
`

const PrefaceContainer = styled.div`
  height: 560px;
  padding: 80px 64px 80px 64px;
  background-color: #1C1C1C;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  & > img {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 990px) {
    & > img {
      display: none;
    }
  }
  @media (max-width: 510px) {
    padding: 40px 32px 40px 32px;
  }
  @media (max-width: 440px) {
    padding: 40px 16px 40px 16px;
    height: initial;
  }
`

const PrefaceText = styled.div`
  width: 832px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: white;
  * {
    margin: 0; padding: 0;
  }
`

const PrefaceTextInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const PodcastsContainer = styled.div`
  background-color: #444444;
  padding: 60px 64px 60px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const JoinUsBanner = styled.div`
  height: 268px;
  padding: 80px 64px 80px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(/happy-people.png);
  background-position: center;
  background-size: cover;
  filter: grayscale(100%);
  background-color: rgba(0,0,0,0.7);
  background-blend-mode: multiply;
  @media (max-width: 600px) {
    padding: 40px 32px;
    h1 {
      font-size: 24px;
    }
  }
`

const JoinUsBannerInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: white;
  b {
    cursor: pointer;
  }
  * {
    margin: 0;
    padding: 0;
  }
`

const Prefooter = styled.div`
  // margin-top: -5px;
  padding: 60px 64px 60px 64px;
  background-color: #1C1C1C;
  display: grid;
  place-items: center;
  width: min(1312px, 100vw);
  @media (max-width: 600px) {
    padding: 30px 32px 30px 32px;
  }
`

const PrefooterInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  color: white;
  p {
    margin: 0; padding: 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    @media (max-width: 500px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`

const PodcastInfo = Object.freeze([
  {
    posterSrc: '/preface-poster.svg',
    title: 'Big Data and Artificial Intelligence. How Data Quality Affects AI Power.',
    guest: 'Guest: Magdalena Cebula | Data Scientist | Redge Technologies',
    description: 'Data Scientist at Redge Technologies focused on recommendation systems, and a research and teaching assistant at Warsaw University of Technology. Organizer of ML in PL conference and Polish ML Community meetups. Nominated for Top 100 Women in AI and DS.',
    youtubeLink: 'https://www.youtube.com/watch?v=UVgTZSZhJVA',
    spotifyLink: 'https://open.spotify.com/episode/7mSG31FDcTpOQ4O6DSJMS2',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/7mSG31FDcTpOQ4O6DSJMS2?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-2.svg',
    title: 'How Data Architecture supports the organization?',
    guest: 'Guest: Filip Dzięcioł | Senior Data Architect | Exerizon',
    description: 'Senior Data Architect at Exerizon and an academic instructor of MLOps at the Polish-Japanese Academy of Information Technology. Holds a bachelor\'s and master\'s degree with distinction, focusing on MLOps and Data Mesh, and is pursuing a Ph.D. in Data Governance and GenAI. Previously worked as a systems analyst, Mulesoft developer, and data integration consultant and architect.',
    youtubeLink: 'https://www.youtube.com/watch?v=R_UP5pFuZX0',
    spotifyLink: 'https://open.spotify.com/episode/4aZQAFKMCIk0z8pg1VfVaU',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/4aZQAFKMCIk0z8pg1VfVaU?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-3.svg',
    title: 'Data labeling pipelines for Machine Learning.',
    guest: 'Guest: Marcin Bera | Data Science & AI Lead | BSH Home Appliances Group',
    description: "Graduate of the Warsaw School of Economics with over a decade of experience in data science, mainly in international corporations within home appliance manufacturing, telecommunications, and IT. Currently manages a Data & AI team and works as an AI Architect specializing in natural language processing at Europe's largest home appliance manufacturer.",
    youtubeLink: 'https://www.youtube.com/watch?v=c5HxwzMcjR0',
    spotifyLink: 'https://open.spotify.com/episode/2LyFUi0cylJrFU7ScxnUUM',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/2LyFUi0cylJrFU7ScxnUUM?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-4.svg',
    title: 'Where should we currently position the Data area within organizations?',
    guest: 'Guest: Łukasz Wróblewski | Senior Data Technology Manager |Biedronka (Jeronimo Martins)',
    description: "Data Manager with over 10 years of experience in industries such as retail, digital, HR, and healthcare. In daily work, Łukasz manages the implementation of analytical projects and oversees the Data Technology team. Currently,  focused on building a data-driven organization across all areas of Biedronka's operations. He has a particular affinity for operational controlling and the optimization of store processes.",
    youtubeLink: 'https://www.youtube.com/watch?v=Gz_101MnUfU',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Gdzie-obecnie-powinnimy-pozycjonowa-obszar-Data-w-organizacjach-e2ou98a',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/07dzMDvq5U53e8uSrlXCfk?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-5.svg',
    title: 'Does all data need to be big? And is it possible to have qualitative and ethical projects in data management without a change in mindset?',
    guest: 'Guest: Wiktoria Gromowa - Cieślik | Technology Leader',
    description: 'Technology Leader with over 10 years of experience in IT and finance, progressing from analyst to director. She has led teams in large international banks, supported women in tech, and developed innovative solutions for process optimization, data models, system improvements, and data architectures. Currently focused on cloud-based solutions.',
    youtubeLink: 'https://www.youtube.com/watch?v=CsOJHX4GwkE',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Czy-kada-DATA-musi-by-BIG--I-czy-bez-zmiany-mindsetu-moliwe-s-jakociowe-i-etyczne-projekty-w-obszarze-zarzdzania-danymi-e2p7i2d',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/2bvlOHysPrCApMcecHTWKI?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-6.svg',
    title: 'Building a data-driven organization as the key to improving data quality and the quality of artificial intelligence systems.',
    guest: 'Guest: Paweł Lubiński | Head of Data Science, |  GC Energy',
    description: 'A practitioner in the field of artificial intelligence (AI) development and digital transformation. Specializes in developing and implementing AI models. Has extensive experience in creating AI solutions that address business problems and contribute to the advancement of Industry 4.0 technologies. Co-founder of the Polish ML Community, where he continuously shares knowledge about building AI-based solutions.',
    youtubeLink: 'https://www.youtube.com/watch?v=6e4vOAdDNq8',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Tworzenie-organizacji-opartej-na-danych-jako-klucz-do-zwikszenia-jakoci-danych-oraz-jakoci-systemw-sztucznej-inteligencji-e2ph85p',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/3QqZwU295XuSIKQBSPq7BL?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-7.svg',
    title: 'Challenges and differences in Big Data project implementation: an analytical perspective.',
    guest: 'Guest: Paweł Matławski | Product Design and Analytics | Sofixit',
    description: 'Experienced specialist in business analytics and product design with over six years in the technology industry. Started as a Junior Business Analyst at Credit Suisse and advanced through roles at Unity Group and EFL Group. Currently a Product Owner at Sofixit, managing Big Data projects. Recently completed postgraduate studies in product design, combining technical knowledge with analytical and design expertise to lead innovative business solutions',
    youtubeLink: 'https://www.youtube.com/watch?v=5kChzCVSSlw',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Analiza-wyzwa-i-rnic-w-realizacji-projektw-Big-Data-z-perspektywy-analitycznej-e2pr5km',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/7eIuBJ0Qau7zJi8WzTFZyA?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-8.svg',
    title: 'The challenges of the data anonymization process.',
    guest: 'Guest: Marcin Myrta | BI Unit Manager | SofLab',
    description: 'BI leader and experienced data warehouse designer with skills in business and technical analysis. For nearly 7 years, he has been at Soflab Technology, leading the development of the Soflab G.A.L.L. data anonymization tool. He specializes in creating reporting systems for the telecommunications, financial, and public service sectors.',
    youtubeLink: 'https://www.youtube.com/watch?v=gh2oZcE6OI0',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Wyzwania-procesu-anonimizacji-danych-e2q4dpl',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/2h2m72iymJDpOUpSk5Tv7z?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: '/podcast-poster-9.svg',
    title: 'Increasing the efficiency of architecture. How to optimize work?',
    guest: 'Guest: Michał  Włodkowski  | Data Engineering & Data Science Manager | Eurocash',
    description: 'Experienced Analyst and Data Scientist from Warsaw, Manager of Modeling and Data Engineering at IPH (Eurocash Group). Specializes in transforming data into business recommendations. Skills include Pyspark, Python, R, and SQL. Extensive experience in the retail, consulting, pharmaceutical, and FMCG industries. Passionate about Data Science and involved in education and mentoring, helping organizations better utilize data. In personal time, interested in sports, reading books, and traveling.',
    youtubeLink: 'https://www.youtube.com/watch?v=GZbpsmksFEA',
    spotifyLink: 'https://podcasters.spotify.com/pod/show/sofixit/episodes/Zwikszenie-efektywnoci-architektury--Jak-zoptymalizowa-prac--kiedy-model-raportowania-przestaje-dziaa-sprawnie---studium-przypadku-e2qcgf1',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/0HbQ01kc2rKUCrujesEYSQ?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
  {
    posterSrc: "/podcast-poster-9-5.png",
    title: "DAMA Chapter Poland. A group of data professionals.",
    guest: "Arkadiusz Dąbkowski | Lead Architect | The Stepstone Group",
    description: "Expert in data management and architecture, Arkadiusz Dąbkowski is the Lead Architect at The Stepstone Group and a board member of DAMA Chapter Poland. In this episode, we explore the goals of DAMA Chapter Poland, the history of DAMA International, the role of DAMA-DMBOK as the cornerstone of data management, the benefits of membership in DAMA Chapter Poland, and strategies for tackling the Certified Data Management Professional (CDMP) exam.",
    youtubeLink: "https://www.youtube.com/watch?v=Oy234vm11bU",
    spotifyLink: "https://creators.spotify.com/pod/show/sofixit/episodes/DAMA-Chapter-Poland--Grupa-profesjonalistw-dbajcych-o-wykorzystanie-najlepszych-praktyk-w-pracy-z-danymi-w-Polsce-e2r1anq",
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/4Y5jyXo4G22AnlbU9Dr7Oa?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  },
  {
    posterSrc: '/podcast-poster-10-5.png',
    title: 'Artificial Intelligence through the eyes of a lawyer - how to prepare for legal changes?',
    guest: 'Guest: Przemysław Sotowski | Legal advisor and expert in AI',
    description: 'In this episode, we interview Przemysław Sotowski, a legal advisor and expert in artificial intelligence, about upcoming legal changes related to the AI Act and their impact on businesses and the public sector. We discuss challenges organizations face, key concepts such as risk classification and the roles of system providers, and practical steps to prepare for these changes. We also touch on the second act, the Data Act, and its implications for the data market. Thank you for joining us throughout this series, and don’t forget to explore our additional resources!',
    youtubeLink: 'https://www.youtube.com/watch?v=I1hhgSfcKUU',
    spotifyLink: 'https://creators.spotify.com/pod/show/sofixit/episodes/Sztuczna-inteligencja-oczami-prawnika---jak-si-przygotowa-na-zmiany-prawa-e2rkgut',
    embedFragment: <iframe style={{ borderRadius: 12 }} src="https://open.spotify.com/embed/episode/4o4DWqmJA7kYDUuXCiEt7K?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>,
  },
])

const LatestPodcastInfo = Object.freeze({
  title: 'Artificial Intelligence through the eyes of a lawyer - how to prepare for legal changes?',
  shortDescription: 'In this episode, legal advisor Przemysław Sotowski discusses the AI Act, its impact on businesses, and how to prepare. We also touch on the Data Act and its role in the data market.',
  longDescription: 'In this episode, we interview Przemysław Sotowski, a legal advisor and expert in artificial intelligence, about upcoming legal changes related to the AI Act and their impact on businesses and the public sector. We discuss challenges organizations face, key concepts such as risk classification and the roles of system providers, and practical steps to prepare for these changes. We also touch on the second act, the Data Act, and its implications for the data market. Thank you for joining us throughout this series, and don’t forget to explore our additional resources!',
  poster: '/podcast-poster-10-5.png',
  spotifyLink: 'https://creators.spotify.com/pod/show/sofixit/episodes/Sztuczna-inteligencja-oczami-prawnika---jak-si-przygotowa-na-zmiany-prawa-e2rkgut',
})

const Podcasts = () => {
  const navigate = useNavigate()

  const handleClickLogo = useCallback(() => {
    navigate('/')
  }, [navigate])

  const handleClickLinkedin= useCallback(() => {
    const href = 'https://www.linkedin.com/company/sofixit/'
    window.open(href, '_blank')
  }, [])

  const handleSpotifyMain = useCallback(() => {
    const href = 'https://open.spotify.com/show/6nDDhuQettcx1NhZpSMW9H'
    window.open(href, '_blank')
  }, [])

  const handleYoutubeMain = useCallback(() => {
    const href = 'https://www.youtube.com/@Sofixitcompany'
    window.open(href, '_blank')
  }, [])

  const handleLastPodcast = useCallback(() => {
    const href = LatestPodcastInfo.spotifyLink
    window.open(href, '_blank')
  }, [])

  const handleWriteEmail = useCallback(() => {
    const email = 'marketing@sofixit.pl'
    const subject = 'Sofixit Podcasts'
    const body = 'I want to join your Podcasts program!'
    const href = `mailto:${email}?subject=${subject}&body=${body}`
    window.location.href = href
    // window.open(`mailto:${email}?subject=${subject}&body=${body}`, '_blank')
  }, [])

  const mediaQuery440 = useMediaQuery('(max-width: 440px)')

  return (
    <Root>
      <Navbar
        frame11965='/frame-11965@2x.png'
        onLogoClick={handleClickLogo}
      />

      <HeroContainer>
        <HeroContainerInner>
          <HeroTopText>
            <Jumpscare><p>BIG Data</p></Jumpscare>
            <Jumpscare delay={0.25}><FatDot /></Jumpscare>
            <Jumpscare delay={0.5}><p>Big Challenges</p></Jumpscare>
            <Jumpscare delay={0.75}><FatDot /></Jumpscare>
            <Jumpscare delay={1}><p>BIG Real Success</p></Jumpscare>
          </HeroTopText>
          <Jumpscare delay={1.5}>
            <HeroMainText>
              <img 
                src='/oval.svg'
                alt="Sofixit"
              />
              Sofixit Podcasts
            </HeroMainText>
          </Jumpscare>
          <HeroButtons>
            <Jumpscare delay={2}>
              <HeroButton onClick={handleSpotifyMain}>
                Listen on Spotify
                <img src="/spotify-icon.svg" alt="Spotify icon" />
              </HeroButton>
            </Jumpscare>
            <Jumpscare delay={2.25}>
              <HeroButton onClick={handleYoutubeMain}>
                Listen on YouTube
                <img src="/youtube-icon.svg" alt="YouTube icon" />
              </HeroButton>
            </Jumpscare>
          </HeroButtons>
        </HeroContainerInner>
      </HeroContainer>

      <Jumpscare variant='left'>
        <PrefaceContainer>
          <PrefaceText>
            <h1>Check Out Latest Podcast</h1>
            <PrefaceTextInner>
              <h3>{LatestPodcastInfo.title}</h3>
              {mediaQuery440 ? (
                <p>{LatestPodcastInfo.shortDescription}</p>
              ): (
                <p>{LatestPodcastInfo.longDescription}</p>
              )}
            </PrefaceTextInner>
            <HeroButton onClick={handleLastPodcast}>
              Listen on Spotify
              <img src="/spotify-icon.svg" alt="Spotify icon" />
            </HeroButton>
          </PrefaceText>
          <img
            src={LatestPodcastInfo.poster}
          />
        </PrefaceContainer>
      </Jumpscare>

      <PodcastsContainer>
        {PodcastInfo.map((info, index) => (
          <Jumpscare variant={index % 2 == 0 ? 'left' : 'right'}>
            <Podcast
              key={index}
              {...info}
            />
          </Jumpscare>
        ))}
      </PodcastsContainer>

      <Jumpscare variant='left'>
      <JoinUsBanner>
        <JoinUsBannerInner>
          <h1>Join us for the upcoming 2025 podcast series</h1>
          <p>
            Submit your email to 
            <span style={{ margin: '0 0.25em' }}>
              <Tooltip title={(
                <div style={{ margin: 12 }}>
                  <HeroButton onClick={handleWriteEmail}>
                    Write on the email
                  </HeroButton>
                </div>
              )}>
              <b onClick={handleWriteEmail}>
                marketing@sofixit.pl
              </b> 
              </Tooltip>
            </span>
            to express your interest in the podcast series.
          </p>
        </JoinUsBannerInner>
      </JoinUsBanner>
      </Jumpscare>

      <Jumpscare variant='left'>
        <Prefooter>
          <PrefooterInner>
            <p>In this series, the business asks questions, and industry experts provide answers on current topics in data management, sharing their experiences and insights.</p>
            <p>The podcast series is produced by the boutique firm Sofixit, which offers a range of services and products in the field of data management. Every day, we support our clients in finding and implementing the best solutions for their needs and work towards the growth of the entire industry!</p>
            <p>Some episodes are hosted by Olga Wisławnych, Head of Business Development at Sofixit, while others are led by AI, which has already become an integral part of our professional lives! Special thanks to Dominica Pogudz for the technical preparation and editing of the podcasts.</p>
          </PrefooterInner>
        </Prefooter>
      </Jumpscare>

      <Footer
        frame11965="/frame-119651@2x.png"
        onLogoClick={handleClickLogo}
        onIconLinkedInClick={handleClickLinkedin}
      />
    </Root>
  )
}

export default memo(Podcasts)
