// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StyleSecondarySmallFalse2_stylesecondarySmallfalse__mvgel {
  border-radius: 50px;
  border: 1px solid #1c1c1c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
}
.StyleSecondarySmallFalse2_stylesecondarySmallfalse__mvgel:hover {
  background-color: rgba(229, 229, 229, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/StyleSecondarySmallFalse2.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,eAAe;AACjB;AACA;EACE,0CAA0C;AAC5C","sourcesContent":[".stylesecondarySmallfalse {\n  border-radius: 50px;\n  border: 1px solid #1c1c1c;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 12px;\n  cursor: pointer;\n}\n.stylesecondarySmallfalse:hover {\n  background-color: rgba(229, 229, 229, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stylesecondarySmallfalse": `StyleSecondarySmallFalse2_stylesecondarySmallfalse__mvgel`
};
export default ___CSS_LOADER_EXPORT___;
