// add-on created to handle contact us forms

import { useCallback, useMemo, useReducer } from "react";
import { ALERT_KINDS, useAlert } from "./Alert";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const useContactUsForm = () => {
  const initialState = {
    name: "",
    email: "",
    message: "",
    accept: false,
  };

  const [state, dispatch] = useReducer((state, { type, payload }) => {
    switch (type) {
      case "name":
        return { ...state, name: payload };
      case "email":
        return { ...state, email: payload };
      case "message":
        return { ...state, message: payload };
      case "accept":
        return { ...state, accept: !state.accept };
      case "reset":
        return {
          ...initialState,
          accept: true, // this is a kostyl not a fix
          // TODO: remove later
        };
      default:
        return state;
    }
  }, initialState);

  const completed = useMemo(
    () =>
      [
        emailRegex.test(state.email),
        state.accept && state.name && state.email && state.message,
        state.name.length > 3 && state.message.length > 10,
      ].every((value) => Boolean(value)),
    [state],
  );

  const handleName = useCallback((event) => {
    dispatch({ type: "name", payload: event.target.value });
  }, []);

  const handleEmail = useCallback((event) => {
    dispatch({ type: "email", payload: event.target.value });
  }, []);

  const handleMessage = useCallback((event) => {
    dispatch({ type: "message", payload: event.target.value });
  }, []);

  const handleAccept = useCallback(() => {
    dispatch({ type: "accept" });
  }, []);

  const openAlert = useAlert();

  const handleSubmit = useCallback(() => {
    if (!completed) return;
    const body = JSON.stringify(state);
    // const url = "http://localhost:3031/contact-us-general"
    const url = "/contact-us-general"
    fetch(url, {
      method: "POST",
      body: body,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status.toString());
        }
        openAlert({
          title: "Success!",
          message: "Your email was sent successfully. We will write you back!",
          kind: ALERT_KINDS.SUCCESS,
        });
      })
      .catch((response) => {
        openAlert({
          title: "Failure",
          message:
            "Something went wrong while sending your email. Please, try again or contact us directly",
          kind: ALERT_KINDS.ERROR,
        });
      });
    dispatch({ type: "reset" });
  }, [state, completed]);

  return {
    state: { ...state, completed },
    handlers: {
      name: handleName,
      email: handleEmail,
      message: handleMessage,
      accept: handleAccept,
      submit: handleSubmit,
    },
  };
};

export default useContactUsForm;
