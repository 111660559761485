import styled, {css} from 'styled-components'
import {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {createPortal} from "react-dom";

const AlertContext = createContext()
export const useAlert = () => useContext(AlertContext)

export const ALERT_KINDS = Object.freeze({
  SUCCESS:  'success',
  INFO:     'info',
  WARNING:  'warning',
  ERROR:    'error'
})

export const AlertProvider = ({ children }) => {
  const [alertState, setAlertState] = useState({
    title:    'No title',
    message:  'No message',
    kind:     ALERT_KINDS.INFO,
    shown:    false,
    timeout:  3000
  })

  const closeAlert = useCallback(() => {
    setAlertState(prevState => ({
      ...prevState,
      shown: false
    }))
  }, [])

  useEffect(() => {
    if (!alertState.shown) return
    const handle = setTimeout(closeAlert, [alertState.timeout])
    return () => clearTimeout(handle)
  }, [alertState])

  const setAlert = useCallback(changes => {
    setAlertState(prevState => ({
      ...prevState,
      shown: true,
      ...changes,
    }))
  }, [])

  return (
    <AlertContext.Provider value={setAlert}>
      {createPortal((
        <GlobalAlert
          alertState={alertState}
          closeAlert={closeAlert}
        />
      ), document.body)}
      { children }
    </AlertContext.Provider>
  )
}

const GlobalAlert = ({ alertState, closeAlert }) => {
  return (
    <Root
      $shown={alertState.shown}
      $kind={alertState.kind}
      onClick={closeAlert}
    >
      <Title>{alertState.title}</Title>
      <Message>{alertState.message}</Message>
    </Root>
  )
}

const Message = styled.p`
  padding: 0; margin: 0;
`

const Title = styled.h2`
  padding: 0; margin: 0;
`

const COLOR_SCHEME = Object.freeze({
  [ALERT_KINDS.SUCCESS]: '#81c784',
  [ALERT_KINDS.INFO]:    '#4fc3f7',
  [ALERT_KINDS.WARNING]: '#ffb74d',
  [ALERT_KINDS.ERROR]:   '#e57373',
})

const Root = styled.aside`
  position: fixed;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  
  ${({ $kind }) => css`
    background-color: ${COLOR_SCHEME[$kind]};
    box-shadow: ${COLOR_SCHEME[$kind]} 0px 2px 8px 0px;
  `}
  color: white;
  
  top: -100%;
  ${({ $shown }) => $shown && css`
    top: 10px;
    @media screen and (max-width: 600px) {
      top: 0;
    }
  `}
  transition: 1s top ease-in-out;
  
  padding: 20px 40px;
  width: min(600px, 100vw);
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
  }
`