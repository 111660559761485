import {useMemo, Fragment} from "react";
import StyleSecondarySmallTrueD from "./StyleSecondarySmallTrueD";
import styles from "./FAQContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import FAQ from "../addons/FAQ";
import {useSetPopupState} from "../addons/ContactPopup";

const FAQContainer = ({
                        faqText,
                        propPadding,
                        propMinWidth,
                        faqs
                      }) => {
  const fAQStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const sectionTitleStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const openPopup = useSetPopupState()

  return (
    <div className={styles.faq} style={fAQStyle}>
      <div className={styles.container}>
        <div className={styles.sectionTitle} style={sectionTitleStyle}>
          <b className={styles.faqs}><Jumpscare>FAQs</Jumpscare></b>
          <div className={styles.text}><Jumpscare>{faqText}</Jumpscare></div>
        </div>
        <div className={styles.accordion}>
          {faqs.map((question, index) => (
            <Fragment key={index}>
              <div className={styles.divider}/>
              <FAQ {...question} />
            </Fragment>
          ))}
          <div className={styles.divider}/>
        </div>
        <div className={styles.content}>
          <div className={styles.content1}>
            <b className={styles.stillHaveQuestions}>
              <Jumpscare>Still have questions?</Jumpscare>
            </b>
            <div className={styles.text1}>
              <Jumpscare delay={0.25}>Contact us for more information or assistance.</Jumpscare>
            </div>
          </div>
          <Jumpscare>
            <StyleSecondarySmallTrueD
              button="Contact Us"
              styleSecondarySmallTrueDBackgroundColor="unset"
              buttonDisplay="inline-block"
              onClick={() => openPopup ({
                title: "Still have questions?",
                description: "Contact us for more information or assistance"
              })}
            />
          </Jumpscare>
        </div>
      </div>
    </div>
  );
};

export default FAQContainer;
