// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloudMigrationContainer1_iconamooncheckBold__uPITk {
  width: 40px;
  position: relative;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.CloudMigrationContainer1_heading__yopbU,
.CloudMigrationContainer1_text__VDRZf {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.CloudMigrationContainer1_text__VDRZf {
  font-size: 16px;
  line-height: 150%;
}
.CloudMigrationContainer1_column__FPpz5,
.CloudMigrationContainer1_content__1hwTL {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.CloudMigrationContainer1_content__1hwTL {
  align-self: stretch;
  gap: 24px;
}
.CloudMigrationContainer1_column__FPpz5 {
  flex: 1 1;
  overflow: hidden;
  gap: 32px;
  min-width: 300px;
  max-width: 405px;
  text-align: center;
  font-size: 24px;
  color: #1c1c1c;
  font-family: Roboto;
}
`, "",{"version":3,"sources":["webpack://./src/components/CloudMigrationContainer1.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;AACA;;EAEE,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;;EAEE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,SAAO;EACP,gBAAgB;EAChB,SAAS;EACT,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".iconamooncheckBold {\n  width: 40px;\n  position: relative;\n  height: 40px;\n  overflow: hidden;\n  flex-shrink: 0;\n}\n.heading,\n.text {\n  align-self: stretch;\n  position: relative;\n  line-height: 140%;\n}\n.text {\n  font-size: 16px;\n  line-height: 150%;\n}\n.column,\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n}\n.content {\n  align-self: stretch;\n  gap: 24px;\n}\n.column {\n  flex: 1;\n  overflow: hidden;\n  gap: 32px;\n  min-width: 300px;\n  max-width: 405px;\n  text-align: center;\n  font-size: 24px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconamooncheckBold": `CloudMigrationContainer1_iconamooncheckBold__uPITk`,
	"heading": `CloudMigrationContainer1_heading__yopbU`,
	"text": `CloudMigrationContainer1_text__VDRZf`,
	"column": `CloudMigrationContainer1_column__FPpz5`,
	"content": `CloudMigrationContainer1_content__1hwTL`
};
export default ___CSS_LOADER_EXPORT___;
