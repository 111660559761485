import styled, { keyframes } from 'styled-components'
import {useMemo} from "react";

const Bubble = ({ children }) => {
  const seed = useMemo(() => -Math.random() * 3, [])
  const animationType = useMemo(() => Math.random() > 0.5, [])

  return (
    <BubbleContainer
      $seed={seed}
      $animationType={animationType}
    >
      { children }
    </BubbleContainer>
  )
}

export default Bubble

const verticalAnimation = keyframes`
  0% {
    transform:  translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
`

const horizontalAnimation = keyframes`
  0% {
    transform:  translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(10px);
  }
`


const BubbleContainer = styled.span`
  cursor: pointer;
  & > * {
    animation: ${({ $animationType }) => $animationType ? verticalAnimation : horizontalAnimation} 3s infinite;
    animation-delay: ${({ $seed }) => $seed}s;
  }
`