import { useCallback } from "react";
import {useNavigate} from "react-router-dom";
import Navbar from "../components/Navbar";
import StylePrimarySmallTrueDar from "../components/StylePrimarySmallTrueDar";
import JobDescriptionContainer from "../components/JobDescriptionContainer";
import Footer from "../components/Footer";
import styles from "./CareerSeniorMidDevOpsE.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";

const CareerSeniorMidDevOpsE = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  const openPopup = useSetPopupState()

  return (
    <div className={styles.careerSeniorMidDevopsE}>
      <Navbar
        frame11965="/frame-11965@2x.png"
        onLogoClick={onLogoClick}
      />
      <div className={styles.header}>
        <div className={styles.sectionTitle}>
          <div className={styles.subheading}>Join</div>
          <div className={styles.content}>
            <b className={styles.seniorMid}>
              <Jumpscare>(Senior / Mid) DevOps Engineer</Jumpscare>
            </b>
            <div className={styles.text}>
              <p className={styles.salary20k36kPln}>
                <Jumpscare delay={0.25}>Salary: 15k-28k PLN / month</Jumpscare>
              </p>
              <p className={styles.salary20k36kPln}>
                <Jumpscare delay={0.5}>Fully Remote possible</Jumpscare>
              </p>
            </div>
          </div>
          <Jumpscare delay={1}>
            <StylePrimarySmallTrueDar
              onClick={() => openPopup ({
                title: "Senior DevOps",
                description: "Contact now and apply for this hot position!",
                variant: "application"
              })}
              button="Apply"
              stylePrimarySmallTrueDarPosition="unset"
              stylePrimarySmallTrueDarTop="unset"
              stylePrimarySmallTrueDarRight="unset"
              stylePrimarySmallTrueDarWidth="unset"
              buttonDisplay="inline-block"
            />
          </Jumpscare>
        </div>
      </div>
      <JobDescriptionContainer
        jobDescription="Job Description"
        businessAnalystRequiremen={`We are looking for a Mid/Senior/Lead DevOps Engineers for our Wrocław office to be a key resource of the Big Data team. The primary focus is to running an analytical ecosystem in modern cloud-based big data systems in a fast-paced, agile environment. In this role you will take care of new Kubernetes and application deployments for BigData solutions. Your focus will be around R&D work to automate Devops processes and optimizing client ecosystem in order to improve Application deployment and maintenance process. Responsibilities: Building web based kubernetes cluster installation tool using terraform + ansible + cloud Automating bare metal/cloud kubernetes cluster deployments Automating helm based app deployments on 100+nodes cluster`}
        productDescription="/innovation@2x.png"
        propTextAlign="left"
      />
      <Footer
        frame11965="/frame-119655@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default CareerSeniorMidDevOpsE;
