import CloudMigrationContainer1 from "./CloudMigrationContainer1";
import styles from "./CloudMigrationContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";

const CloudMigrationContainer = () => {
  const mediaQuery1440 = useMediaQuery('(max-width: 1439px)')

  return (
    <div className={styles.powerOfMigration}>
      <div className={styles.sectionTitle}>
        <div className={styles.subheading}>Efficient</div>
        <div className={styles.content}>
          <b className={styles.heading}>
            <Jumpscare>
              Unlock the Power of Cloud Migration
            </Jumpscare>
          </b>
          <div className={styles.text}>
            <Jumpscare delay={0.5}>Discover the benefits of cloud migration and explore the
              possibilities of IaaS and SaaS solutions.</Jumpscare>
          </div>
        </div>
      </div>
      <div className={styles.content1}>
        <div className={styles.row}>
          <Jumpscare><CloudMigrationContainer1
            serviceDescription="Experience seamless migration to the cloud with our expert team"
            cloudMigrationDescription="Efficiently move your business operations to the cloud and unlock its full potential"
          /></Jumpscare>
          <Jumpscare delay={mediaQuery1440 ? 0 : 0.5}>
            <div className={styles.column}>
              <div className={styles.content2}>
                <img
                  className={styles.iconamooncheckBold}
                  alt=""
                  src="/iconamooncheckbold.svg"
                />
                <b className={styles.heading1}>
                  Ensure a smooth transition to the cloud with our comprehensive
                  migration services
                </b>
                <div className={styles.text1}>
                  Minimize downtime and maximize efficiency with our cloud
                  migration solutions
                </div>
              </div>
            </div>
          </Jumpscare>
          <Jumpscare delay={mediaQuery1440 ? 0 : 1}><CloudMigrationContainer1
            serviceDescription="Optimize your business processes with our cloud enablement services"
            cloudMigrationDescription="Leverage the power of the cloud to enhance productivity and scalability"
          /></Jumpscare>
        </div>
      </div>
    </div>
  );
};

export default CloudMigrationContainer;
