import styled, { css } from 'styled-components'
import { useCallback, useEffect, useState } from 'react'

const Showcase = ({
                    data,
                    ItemComponent,
                    RootComponent = DefaultRoot,
                    ControlsComponent = DefaultControls,
                    scaleUp = 15,
                    autoSwitchTime = 3,
                    inactiveOpacity = 0.7
                  }) => {
  const [pointer, setPointer] = useState(0)

  const [hovered, setHovered] = useState(false)
  const handleMouseEnter = useCallback(() => {
    setHovered(true)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setHovered(false)
  }, [])

  useEffect(() => {
    if (hovered) return
    const handle = setInterval(() => {
      setPointer(prev => {
        return prev + 1 >= data.length
          ? 0
          : prev + 1
      })
    }, autoSwitchTime * 1000)
    return () => clearInterval(handle)
  }, [hovered, autoSwitchTime, data.length])

  return (
    <RootComponent
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      { data.map((item, index) => (
        <Item
          key={index}
          item={item}
          active={index === pointer}
          onHover={() => setPointer(index)}
          ItemComponent={ItemComponent}
          scaleUp={scaleUp}
          inactiveOpacity={inactiveOpacity}
        />
      )) }
      <ControlsComponent
        count={data.length}
        pointer={pointer}
        setPointer={setPointer}
      />
    </RootComponent>
  )
}

export default Showcase

const Item = ({
                item,
                active,
                onHover,
                ItemComponent,
                scaleUp,
                inactiveOpacity
              }) => {
  const scale = 1 + scaleUp / 100.0
  const shift = `${-scaleUp / 2}%`

  return (
    <ItemWrapper
      onMouseEnter={onHover}
      $active={active}
      $scale={scale}
      $shift={shift}
      $inactiveOpacity={inactiveOpacity}
    >
      <ItemComponent {...item}/>
    </ItemWrapper>
  )
}

const ItemWrapper = styled.div`
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  opacity: ${({ $inactiveOpacity }) => $inactiveOpacity};
  transform: scale(1.0);
  ${({ $active, $scale, $shift }) => $active && css`
    transform: scale(${$scale}) translateY(${$shift});
    opacity: 1.0;
  `}
`

const DefaultControls = ({
                           count,
                           pointer,
                           setPointer
                         }) => {
  const handleGoBack = useCallback(() => {
    setPointer(prev => {
      return prev - 1 < 0
        ? count - 1
        : prev - 1
    })
  }, [count])
  const handleGoForward = useCallback(() => {
    setPointer(prev => {
      return prev + 1 >= count
        ? 0
        : prev + 1
    })
  }, [count])

  return (
    <>
      <DefaultLeftControlsWrapper>
        <DefaultButton
          onClick={handleGoBack}
        >Prev</DefaultButton>
      </DefaultLeftControlsWrapper>
      <DefaultBottomControlsWrapper>
        { Array(count).fill(null).map((_, index) => (
          <DefaultButton
            onClick={() => setPointer(index)}
            key={index}
            $active={pointer === index}
          >{index + 1}</DefaultButton>
        )) }
      </DefaultBottomControlsWrapper>
      <DefaultRightControlsWrapper>
        <DefaultButton
          onClick={handleGoForward}
        >Next</DefaultButton>
      </DefaultRightControlsWrapper>
    </>
  )
}

const DefaultRightControlsWrapper = styled.div`
  position: absolute;
  right: 20px;
  margin: 0 !important;
`

const DefaultLeftControlsWrapper = styled.div`
  position: absolute;
  left: 20px;
  margin: 0 !important;
`

const DefaultBottomControlsWrapper = styled.div`
  margin: 0 !important;
  position: absolute;
  bottom: -20px;
  & > * + * {
    margin-left: 6px;
  }
`

const DefaultButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 3px 5px;
  ${({ $active }) => $active && css`
    background-color: #ccc;
  `}
  &:hover {
    background-color: #ccc;
  }
`

const DefaultRoot = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > * + * {
    margin-left: 16px;
  }
  padding: 12px 16px;
  padding-top: 24px;
  min-width: 100%;
  position: relative;
`
