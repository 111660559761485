import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MenuTabletMobile.module.css";
import {useSetPopupState} from "../addons/ContactPopup";

const navConfig = {
  services: [
    {
      title: 'Custom Software Development',
      description: 'Innovate Tomorrow, Today',
      icon: '/services-small.svg',
      route: '/product-development'
    },
    {
      title: 'Technology Consulting',
      description: 'Navigate Success with Expert Guidance',
      icon: '/services-small1.svg',
      route: '/consulting'
    },
    {
      title: 'IT Outsourcing',
      description: 'Efficiency Beyond Borders',
      icon: '/services-small2.svg',
      route: '/outsourcing'
    },
  ],
  bigDataSolutions: [
    {
      title: 'Big Data Transformation',
      icon: '/services-data.svg',
      route: '/big-data'
    },
    {
      title: 'Cloud Transformation',
      icon: '/services-cloud.svg',
      route: '/cloud-solutions',
    }
  ],
  ourPrograms: [
    {
      title: 'Mentoring',
      icon: '/services-small2.svg',
      route: '/mentoring',
    },
    {
      title: 'Podcasts',
      icon: '/services-small1.svg',
      route: '/podcasts',
    }
  ],
  rest: [
    {
      title: 'Company',
      navigateTo: '/our-company'
    },
    {
      title: 'Careers',
      navigateTo: '/career'
    }
  ]
}

const MenuTabletMobile = ({ onClose }) => {
  const navigate = useNavigate();
  const openPage = useCallback(page => {
    navigate(page)
  }, [navigate])

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const openPopup = useSetPopupState()

  return (
    <>
      <div className={styles.menuTabletmobile} data-animate-on-scroll>
        <div className={styles.container}>
          <div className={styles.column}>

            <div className={styles.row}>
              <div className={styles.linkFour}>
                <div className={styles.navLinkDropdown}>
                  <div className={styles.link}>Services</div>
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.menu}>
                  <div className={styles.menuList}>
                    { navConfig.services.map((config, index) => (
                      <button
                        key={index}
                        className={styles.menuItem}
                        onClick={() => openPage(config.route)}
                      >
                        <img
                          className={styles.servicesSmallIcon}
                          alt={config.title}
                          src={config.icon}
                        />
                        <div className={styles.content}>
                          <div className={styles.pageOne}>
                            {config.title}
                          </div>
                          <div className={styles.loremIpsumDolor}>
                            {config.description}
                          </div>
                        </div>
                      </button>
                    )) }
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.linkFour}>
                <div className={styles.navLinkDropdown}>
                  <div className={styles.link}>Big Data Solutions</div>
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.menu}>
                  <div className={styles.menuList}>
                    { navConfig.bigDataSolutions.map((config, index) => (
                      <button
                        key={index}
                        className={styles.menuItem}
                        onClick={() => openPage(config.route)}
                      >
                        <img
                          className={styles.servicesSmallIcon}
                          alt={config.title}
                          src={config.icon}
                        />
                        <div className={styles.content}>
                          <div className={styles.pageOne}>
                            {config.title}
                          </div>
                        </div>
                      </button>
                    )) }
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.linkFour}>
                <div className={styles.navLinkDropdown}>
                  <div className={styles.link}>Our programs</div>
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.menu}>
                  <div className={styles.menuList}>
                    { navConfig.ourPrograms.map((config, index) => (
                      <button
                        key={index}
                        className={styles.menuItem}
                        onClick={() => openPage(config.route)}
                      >
                        <img
                          className={styles.servicesSmallIcon}
                          alt={config.title}
                          src={config.icon}
                        />
                        <div className={styles.content}>
                          <div className={styles.pageOne}>
                            {config.title}
                          </div>
                        </div>
                      </button>
                    )) }
                  </div>
                </div>
              </div>
            </div>

            { navConfig.rest.map(({ title, navigateTo }, index) => (
              <button
                key={index}
                className={styles.linkOne}
                onClick={() => openPage(navigateTo)}
              >
                <div className={styles.link1}>{ title }</div>
              </button>
            )) }

          </div>
          <div className={styles.content4}>
            <div className={styles.content5}>
              <div className={styles.lookingForA}>
                Looking for a new career?
              </div>
              <button className={styles.getInTouch} onClick={() => openPopup ({
                title: "Looking for a job?",
                description: "Get in touch with us and show your talent!"
              })}>
                Get in touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuTabletMobile;
