import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MegaMenu.module.css";
import {useSetPopupState} from "../addons/ContactPopup";

const MegaMenu = ({ links }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const openPopup = useSetPopupState()

  return (
    <>
      <div className={styles.megaMenu} data-animate-on-scroll>
        <div className={styles.menu}>
          <div className={styles.menuList}>
            {links.map(({title, description, icon, link}, index) => (
              <button 
                key={index}
                className={styles.menuItem} 
                onClick={() => navigate(link)}
              >
                <img
                  className={styles.servicesSmallIcon2}
                  alt=""
                  src={icon}
                />
                <div className={styles.content}>
                  <div className={styles.pageOne}>{title}</div>
                  <div className={styles.loremIpsumDolor}>
                    {description}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className={styles.content4}>
          <div className={styles.content5}>
            <div className={styles.lookingForA}>Looking for a new career?</div>
            <button className={styles.buttonLink} onClick={() => openPopup ({
              title: "Looking for a job?",
              description: "Get in touch with us and show your talent!"
            })}>
              <div className={styles.buttonLink1}>
                <div className={styles.getInTouch}>Get in touch</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MegaMenu;
