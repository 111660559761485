import Property from "./Property";
import styles from "./ProductManagerContainer.module.css";
import Flipcard from "../addons/Flipcard";
import {useMemo} from "react";

const ProductManagerContainer = ({
  roleDescription,
  technologyAlignment,
  avatar
}) => {
  const front = (
    <div className={styles.specialistBox} /* style={specialistBox1Style} */>
      <div className={styles.contentTop}>
        <Property
          // propertyProperty11={"/component-11.svg"}
          // propertyProperty11='/person-1.svg'
          propertyProperty11={avatar ?? '/person-1.svg'}
          property11IconOverflow="unset"
          property11IconPosition="relative"
          property11IconWidth="181.5px"
          property11IconHeight="181.5px"
        />
        <div className={styles.content}>
          <div className={styles.titleParent}>
            <h5 className={styles.title}>{roleDescription}</h5>
          </div>
        </div>
      </div>
    </div>
  )

  const back = (
    <div className={styles.specialistBox} /* style={specialistBox1Style} */>
      <div className={styles.contentTop}>
        <div className={styles.content}>
          <div className={styles.titleParent}>
            <h5 className={styles.description}>{technologyAlignment}</h5>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Flipcard
      front={front}
      back={back}

      height={350}
      width={290}
    />
  )
};

export default ProductManagerContainer;
