// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WorkProcessContainer_heading__9o2es {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}
.WorkProcessContainer_withOurGlobal__CbeOh {
  margin: 0;
}
.WorkProcessContainer_content3__NabPi,
.WorkProcessContainer_text__HLSBD {
  align-self: stretch;
}
.WorkProcessContainer_text__HLSBD {
  position: relative;
  font-size: 16px;
  line-height: 150%;
}
.WorkProcessContainer_content3__NabPi {
  gap: 24px;
}
.WorkProcessContainer_column__YbzW9,
.WorkProcessContainer_content3__NabPi,
.WorkProcessContainer_sectionTitle__C0ajx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.WorkProcessContainer_sectionTitle__C0ajx {
  align-self: stretch;
}
.WorkProcessContainer_column__YbzW9 {
  flex: 1 1;
  gap: 32px;
  min-width: 300px;
}
.WorkProcessContainer_content__-YEzI,
.WorkProcessContainer_workProcess__eSmXs {
  display: flex;
  justify-content: center;
}
.WorkProcessContainer_content__-YEzI {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 80px;
  max-width: 1312px;
}
.WorkProcessContainer_workProcess__eSmXs {
  align-self: stretch;
  background-color: #fefefe;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 80px 64px;
  text-align: left;
  font-size: 48px;
  color: #1c1c1c;
  font-family: Roboto;
}
`, "",{"version":3,"sources":["webpack://./src/components/WorkProcessContainer.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,SAAS;AACX;AACA;;EAEE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,SAAS;AACX;AACA;;;EAGE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;AAC7B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,SAAO;EACP,SAAS;EACT,gBAAgB;AAClB;AACA;;EAEE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,SAAS;EACT,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".heading {\n  margin: 0;\n  align-self: stretch;\n  position: relative;\n  font-size: inherit;\n  line-height: 120%;\n  font-weight: 700;\n  font-family: inherit;\n}\n.withOurGlobal {\n  margin: 0;\n}\n.content3,\n.text {\n  align-self: stretch;\n}\n.text {\n  position: relative;\n  font-size: 16px;\n  line-height: 150%;\n}\n.content3 {\n  gap: 24px;\n}\n.column,\n.content3,\n.sectionTitle {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n.sectionTitle {\n  align-self: stretch;\n}\n.column {\n  flex: 1;\n  gap: 32px;\n  min-width: 300px;\n}\n.content,\n.workProcess {\n  display: flex;\n  justify-content: center;\n}\n.content {\n  width: 100%;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  gap: 80px;\n  max-width: 1312px;\n}\n.workProcess {\n  align-self: stretch;\n  background-color: #fefefe;\n  overflow: hidden;\n  flex-direction: column;\n  align-items: center;\n  padding: 80px 64px;\n  text-align: left;\n  font-size: 48px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `WorkProcessContainer_heading__9o2es`,
	"withOurGlobal": `WorkProcessContainer_withOurGlobal__CbeOh`,
	"content3": `WorkProcessContainer_content3__NabPi`,
	"text": `WorkProcessContainer_text__HLSBD`,
	"column": `WorkProcessContainer_column__YbzW9`,
	"sectionTitle": `WorkProcessContainer_sectionTitle__C0ajx`,
	"content": `WorkProcessContainer_content__-YEzI`,
	"workProcess": `WorkProcessContainer_workProcess__eSmXs`
};
export default ___CSS_LOADER_EXPORT___;
