import styles from "./AssessCard.module.css";

const AssessCard = ({
  stepNumber,
  imageId,
  taskDescription,
  infrastructureImageUrl,
}) => {
  return (
    <div className={styles.timelineItem}>
      <div className={styles.content}>
        <div className={styles.div}>{stepNumber}</div>
        <div className={styles.content1}>
          <img className={styles.assessIcon} alt="" src={imageId} />
        </div>
      </div>
      <div className={styles.content2}>
        <h5 className={styles.heading}>{taskDescription}</h5>
        <div className={styles.text}>{infrastructureImageUrl}</div>
      </div>
    </div>
  );
};

export default AssessCard;
