import ProductManagerContainer from "./ProductManagerContainer";
import styles from "./ExpertFilterContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useCallback, useMemo, useState} from "react";

const rolesArray = [
  { roleDescription: "Product Managers", technologyAlignment: "Aligns technology with business goals, optimizing resources for successful product development and ensuring customer satisfaction.", propHeight: "511.5px", delay: 0 },
  { roleDescription: "Engineering Managers", technologyAlignment: "Essential for orchestrating technical teams, aligning projects with business goals, and fostering a dynamic and effective engineering culture.", propHeight: "511.5px", delay: 0.25 },
  { roleDescription: "Software Architects", technologyAlignment: "Vital for designing robust and scalable software solutions, providing architectural guidance to development teams, and ensuring alignment with long-term business objectives.", propHeight: "511.5px", delay: 0.5 },
  { roleDescription: "Software Developers", technologyAlignment: "Translating conceptual designs into functional code, implementing and testing software solutions, and continuously collaborating to create innovative and efficient applications that meet user requirements.", propHeight: "unset", delay: 0.75 },
  { roleDescription: "Business Analysts", technologyAlignment: "Bridging the gap between business needs and IT solutions, analyzing data, defining requirements, and providing strategic insights to optimize processes and drive informed decision-making within an organization.", propHeight: "535.5px", delay: 0 },
  { roleDescription: "Scrum masters", technologyAlignment: "Scrum Masters drive agile development, foster team collaboration, and ensure adherence to Scrum principles for successful project outcomes.", propHeight: "535.5px", delay: 0.25 },
  { roleDescription: "QA Engineers", technologyAlignment: "Critical role in ensuring software quality, conducting thorough testing, and identifying and resolving issues to deliver reliable and high-performance applications.", propHeight: "535.5px", delay: 0.5 },
  { roleDescription: "UX Designers", technologyAlignment: "UX designers are needed to create intuitive and enjoyable digital experiences, ensuring that software meets user expectations, maximizes engagement, and ultimately contributes to the success and profitability of a product or service.", propHeight: "535.5px", delay: 0.75 },
  { roleDescription: "UI Designers", technologyAlignment: "UI designers are crucial for crafting visually appealing and aesthetically pleasing interfaces, translating the UX vision into tangible designs that not only attract users but also contribute to the overall usability and brand identity of a product or service.", propHeight: "535.5px", delay: 0 },
  { roleDescription: "Testers", technologyAlignment: "Testers are instrumental in assessing and ensuring the quality of software through systematic testing, detecting and reporting defects, and contributing to the overall reliability and functionality of the applications.", propHeight: "535.5px", delay: 0.25 },
  { roleDescription: "Technical Leaders", technologyAlignment: "Technical leaders guide and inspire their teams, providing expertise and direction, and ensuring the successful execution of technology initiatives in alignment with organizational objectives.", propHeight: "535.5px", delay: 0.5 }
];

const avatars = [
  '/person-1.svg',
  '/person-2.svg',
  '/person-3.svg',
  '/person-4.svg'
]

const ExpertFilterContainer = () => {
  const mediaQuery1290 = useMediaQuery('(max-width: 1289px)')

  // const navigate = useNavigate()
  // const openCompany = useCallback(() => {
  //   navigate('/our-company')
  // }, [])

  const mediaQuery960 = useMediaQuery('(max-width: 960px)')
  const [limit, setLimit] = useState(4)
  const increaseLimit = useCallback(() => {
    setLimit(prev => prev + 3)
  }, [])
  const rolesLimited = useMemo(() => {
    if (!mediaQuery960) return rolesArray
    return rolesArray.slice(0, limit)
  }, [mediaQuery960, limit])

  const mediaQuery500 = useMediaQuery('(max-width: 499px)')

  return (
    <section className={styles.experts}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.sectionTitle}>
            <div className={styles.subheading}>Efficient</div>
            <div className={styles.content1}>
              <b className={styles.heading}>
                {mediaQuery500 ? (
                  <Jumpscare threshold={0.6}>
                    Select the expert whose skills match your project needs for
                    excellence and proficiency.
                  </Jumpscare>
                ) : (
                  <Jumpscare threshold={0.6}>
                    Select the expert whose skills match your project needs for
                    excellence and proficiency. Your success is our priority, and
                    our experts are committed to turning it into reality.
                  </Jumpscare>
                )}
              </b>
              <div className={styles.text}>
                <Jumpscare threshold={0.9}>
                  Your success is not just a goal; it's a shared journey we embark
                  on together, ensuring a collaborative and fruitful outcome.
                </Jumpscare>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.specialistBoxParent}>
              { rolesLimited.map((role, index) => (
                <Jumpscare delay={mediaQuery1290 ? 0 : role.delay}>
                  <ProductManagerContainer
                    key={index}
                    avatar={avatars[index % avatars.length]}
                    {...role}
                  />
                </Jumpscare>
              )) }
            </div>
          </div>
          {(limit < rolesArray.length - 1) && (<
            div className={styles.button} onClick={increaseLimit}>
              <div className={styles.button1}>Show more</div>
            </div>
          ) }
        </div>
      </div>
    </section>
  );
};

export default ExpertFilterContainer;
