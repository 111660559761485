import TypeCloudEMigration from "./TypeCloudEMigration";
import styles from "./DeliveringSolutionsContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";

const DeliveringSolutionsContainer = () => {
  const mediaQuery1370 = useMediaQuery('(max-width: 1367px)')

  return (
    <div className={styles.deliveringSolutions}>
      <div className={styles.container}>
        <div className={styles.sectionTitle}>
          <div className={styles.content}>
            <b className={styles.heading}>
              <Jumpscare>Delivering Perfect Solutions for Every Business</Jumpscare>
            </b>
            <Jumpscare>
              <div
                className={styles.text}
              >{`We offer a range of IT services including IT Product Development, IT Consulting, IT Outsourcing, and Cloud Enablement & Migration. Our team of experts provides extensive technological and domain knowledge to deliver the best solutions for your business needs.`}</div>
            </Jumpscare>
          </div>
        </div>
        <div className={styles.content1}>
          <div className={styles.row}>
            <Jumpscare>
              <div className={styles.column}>
                <TypeCloudEMigration
                  itCons="/services.svg"
                  typeCloudEMigrationWidth="87px"
                  typeCloudEMigrationHeight="unset"
                  itConsIconWidth="unset"
                  itConsIconHeight="80.1px"
                  itConsIconAlignSelf="stretch"
                  itConsIconOverflow="hidden"
                />
                <div className={styles.heading1}>Custom Software Development</div>
                <div className={styles.text}>
                  We specialize in developing innovative IT products that meet
                  your specific requirements.
                </div>
              </div>
            </Jumpscare>
            <Jumpscare delay={mediaQuery1370 ? 0 : 0.25}>
              <div className={styles.column}>
                <TypeCloudEMigration
                  itCons="/icon.svg"
                  typeCloudEMigrationWidth="87px"
                  typeCloudEMigrationHeight="80px"
                  itConsIconWidth="86.8px"
                  itConsIconHeight="75.2px"
                  itConsIconAlignSelf="unset"
                  itConsIconOverflow="unset"
                />
                <div className={styles.heading1}>Technology Consulting</div>
                <div className={styles.text}>
                  Our experienced consultants provide expert guidance to optimize
                  your IT strategies.
                </div>
              </div>
            </Jumpscare>
            <Jumpscare delay={mediaQuery1370 ? 0 : 0.5}>
              <div className={styles.column}>
                <TypeCloudEMigration
                  itCons="/group-11604.svg"
                  typeCloudEMigrationWidth="87px"
                  typeCloudEMigrationHeight="80px"
                  itConsIconWidth="87px"
                  itConsIconHeight="75px"
                  itConsIconAlignSelf="unset"
                  itConsIconOverflow="unset"
                />
                <div className={styles.heading1}>IT Outsourcing</div>
                <div className={styles.text}>
                  Outsource your IT operations to us and focus on your core
                  business activities.
                </div>
              </div>
            </Jumpscare>
            <Jumpscare delay={mediaQuery1370 ? 0 : 0.75}>
              <div className={styles.column}>
                <TypeCloudEMigration
                  itCons="/it-cons.svg"
                  typeCloudEMigrationWidth="unset"
                  typeCloudEMigrationHeight="80px"
                  itConsIconWidth="87px"
                  itConsIconHeight="74.9px"
                  itConsIconAlignSelf="unset"
                  itConsIconOverflow="unset"
                />
                <div className={styles.heading1}>
                  <p className={styles.cloudEnablement}>{`Cloud Enablement `}</p>
                  <p className={styles.cloudEnablement}>{`& Migration`}</p>
                </div>
                <div className={styles.text}>
                  Outsource your IT operations to us and focus on your core
                  business activities.
                </div>
              </div>
            </Jumpscare>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveringSolutionsContainer;
