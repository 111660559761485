// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StyleLinkSmallFalseDark1_iconRelume__F\\+703 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.StyleLinkSmallFalseDark1_stylelinkSmallfalseDark__CUIM0 {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/StyleLinkSmallFalseDark1.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".iconRelume {\n  width: 24px;\n  position: relative;\n  height: 24px;\n  overflow: hidden;\n  flex-shrink: 0;\n}\n.stylelinkSmallfalseDark {\n  border-radius: 4px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconRelume": `StyleLinkSmallFalseDark1_iconRelume__F+703`,
	"stylelinkSmallfalseDark": `StyleLinkSmallFalseDark1_stylelinkSmallfalseDark__CUIM0`
};
export default ___CSS_LOADER_EXPORT___;
