import { useMemo } from "react";
import styles from "./Property.module.css";

const Property = ({
  propertyProperty11,
  property11IconOverflow,
  property11IconPosition,
  property11IconWidth,
  property11IconHeight,
}) => {
  const property11IconStyle = useMemo(() => {
    return {
      overflow: property11IconOverflow,
      position: property11IconPosition,
      width: property11IconWidth,
      height: property11IconHeight,
    };
  }, [
    property11IconOverflow,
    property11IconPosition,
    property11IconWidth,
    property11IconHeight,
  ]);

  return (
    <img
      className={styles.property11Icon}
      alt=""
      src={propertyProperty11}
      style={property11IconStyle}
    />
  );
};

export default Property;
