import { useMemo } from "react";
import styles from "./ButtonCardFormFilter.module.css";

const ButtonCardFormFilter = ({
  productCode,
  propBorder,
  propPadding,
  propBorderRadius,
  propWidth,
  propHeight,
}) => {
  const button5Style = useMemo(() => {
    return {
      border: propBorder,
      padding: propPadding,
      borderRadius: propBorderRadius,
    };
  }, [propBorder, propPadding, propBorderRadius]);

  const solarmapArrowRightBrokenIconStyle = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
    };
  }, [propWidth, propHeight]);

  return (
    <button className={styles.button} style={button5Style}>
      <img
        className={styles.solarmapArrowRightBrokenIcon}
        alt=""
        src={productCode}
        style={solarmapArrowRightBrokenIconStyle}
      />
    </button>
  );
};

export default ButtonCardFormFilter;
