import styles from "./CardContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";
import styled from "styled-components";

const CardContainer = () => {
  const mediaQuery600 = useMediaQuery('(max-width: 599px)')

  return (
    <div className={styles.title}>
      <div className={styles.contentWrapper}>
        <Jumpscare>
          <div className={styles.content}>
            { mediaQuery600 ? (
              <MobileContainer>
                We've teamed up with over 60 people
                to create efficient and flexible cloud
                solutions!
              </MobileContainer>
            ) : (
              <>
                <h2 className={styles.heading2}>We've teamed up with</h2>
                <div className={styles.parent}>
                  <img className={styles.icon} alt="" src="/4.svg" />
                  <h2 className={styles.heading3}>over 60</h2>
                </div>
                <h2 className={styles.heading2}>
                  people
                </h2>
                <h2 className={styles.heading2}>to</h2>
                <h2 className={styles.heading2}>create </h2>
                <h2 className={styles.heading2}>efficient</h2>
                <h2 className={styles.heading2}>and</h2>
                <h2 className={styles.heading2}>flexible </h2>
                <h2 className={styles.heading2}>cloud </h2>
                <h2 className={styles.heading2}>solutions!</h2>
              </>
            )}
          </div>
        </Jumpscare>
      </div>
      <div className={styles.textParent}>
        <Jumpscare>
          <div className={styles.text}>
            Realizing Business Goals Through Technology
          </div>
        </Jumpscare>
        {/*<StyleSecondarySmallFalse*/}
        {/*  button="Browse case studies"*/}
        {/*  styleSecondarySmallFalseBackgroundColor="transparent"*/}
        {/*  buttonDisplay="inline-block"*/}
        {/*  onButton8Click={onButton8Click}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default CardContainer;

const MobileContainer = styled.h2`
  font-size: calc(24px + 3vw);
  line-height: 120%;
`