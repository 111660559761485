// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StyleTertiarySmallTrueDa1_button__j3P42 {
  position: relative;
  line-height: 150%;
}
.StyleTertiarySmallTrueDa1_styletertiarySmalltrueDa__ntD6h {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  text-align: left;
  font-size: 16px;
  color: #1c1c1c;
  font-family: Roboto;
}
.StyleTertiarySmallTrueDa1_styletertiarySmalltrueDa__ntD6h:hover {
  background-color: rgba(229, 229, 229, 0.5);
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .StyleTertiarySmallTrueDa1_styletertiarySmalltrueDa__ntD6h:hover {
    cursor: pointer;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/StyleTertiarySmallTrueDa1.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,0CAA0C;EAC1C,eAAe;AACjB;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".button {\n  position: relative;\n  line-height: 150%;\n}\n.styletertiarySmalltrueDa {\n  border-radius: 4px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 20px;\n  text-align: left;\n  font-size: 16px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n.styletertiarySmalltrueDa:hover {\n  background-color: rgba(229, 229, 229, 0.5);\n  cursor: pointer;\n}\n@media screen and (max-width: 1200px) {\n  .styletertiarySmalltrueDa:hover {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `StyleTertiarySmallTrueDa1_button__j3P42`,
	"styletertiarySmalltrueDa": `StyleTertiarySmallTrueDa1_styletertiarySmalltrueDa__ntD6h`
};
export default ___CSS_LOADER_EXPORT___;
