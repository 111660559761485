// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PortalPopup_portalPopupOverlay__NP6jp {
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;

  animation: PortalPopup_enter__\\+B8cJ 0.5s;
}

/*
@keyframes enter {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}
 */

@keyframes PortalPopup_enter__\\+B8cJ {
  0% {
    transform: scale(20%);
    opacity: 0;
  }

  100% {
    transform: scale(100%);
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PortalPopup.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,QAAQ;;EAER,yCAAqB;AACvB;;AAEA;;;;;;;;;;EAUE;;AAEF;EACE;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,sBAAsB;IACtB,UAAU;EACZ;AACF","sourcesContent":[".portalPopupOverlay {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  inset: 0;\n\n  animation: enter 0.5s;\n}\n\n/*\n@keyframes enter {\n  0% {\n    transform: translateX(-100%);\n  }\n\n  100% {\n    transform: translateX(0%);\n  }\n}\n */\n\n@keyframes enter {\n  0% {\n    transform: scale(20%);\n    opacity: 0;\n  }\n\n  100% {\n    transform: scale(100%);\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portalPopupOverlay": `PortalPopup_portalPopupOverlay__NP6jp`,
	"enter": `PortalPopup_enter__+B8cJ`
};
export default ___CSS_LOADER_EXPORT___;
