import {createContext, Fragment, useCallback, useContext, useState} from 'react'
import PortalPopup from '../components/PortalPopup'
import PopUpContact from '../components/PopUpContact'
import ApplyForm from "../components/ApplyForm"

// global hooks and providers
const PopupContext = createContext()

export const PopupContextProvider = ({ children }) => {
  const popupState = useState(null)
  return (
    <PopupContext.Provider value={popupState}>
      { children }
    </PopupContext.Provider>
  )
}

export const usePopupState = () => {
  return useContext(PopupContext)
}

export const useSetPopupState = () => {
  const [_, setPopupState] = usePopupState()
  return setPopupState
}

// component itself
const ContactPopup = () => {
  const [popupState, setPopupState] = usePopupState()
  const closePopup = useCallback(() => {
    setPopupState(null)
  }, [setPopupState])

  if (!popupState) {
    return <Fragment />
  }

  const Popup = (() => {
    switch (popupState.variant) {
      case 'application':
        return ApplyForm
      default:
        return PopUpContact
    }
  })()

  return (
    <PortalPopup
      overlayColor="rgba(113, 113, 113, 0.3)"
      // overlayColor='transparent'
      placement="Centered"
      onOutsideClick={closePopup}
    >
      <Popup
        {...popupState}
        onClose={closePopup}
      />
    </PortalPopup>
  )
}

export default ContactPopup
