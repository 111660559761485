import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import StyleSecondarySmallTrueD from "./StyleSecondarySmallTrueD";
import SalaryCard from "./SalaryCard";
import styles from "./FormContainer.module.css";
import Jumpscare from "../addons/Jumpscare";

const FormContainer = () => {
  const navigate = useNavigate();

  const onDevopsSeniorClick = useCallback(() => {
    navigate("/career-senior-mid-devops")
  }, [navigate])

  const onButtonContainerClick = useCallback(() => {
    navigate("/career-junior-mid-graphic-designer");
  }, [navigate]);

  const onButtonContainer2Click = useCallback(() => {
    navigate("/career-business-analyst");
  }, [navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.sectionTitle}>
          <div className={styles.subheading}>
            <Jumpscare>Join</Jumpscare>
          </div>
          <div className={styles.content}>
            <b className={styles.currentOpenings}>
              <Jumpscare delay={0.25}>Current Openings</Jumpscare>
            </b>
            <div className={styles.text}>
              <Jumpscare delay={0.75}>Explore our current job openings and find the perfect fit for you.</Jumpscare>
            </div>
          </div>
        </div>
      </div>
      <section className={styles.jobs}>
        <div className={styles.container1}>
          { /* <div className={styles.card}>
            <div className={styles.content1}>
              <div className={styles.content2}>
                <div className={styles.job}>
                  <div className={styles.jobTitle}>
                    <div className={styles.heading}>
                      <p className={styles.devopsEnginner}>
                        <b className={styles.devopsEnginner1}>
                          DevOps Enginner
                        </b>
                      </p>
                      <p className={styles.seniormid}>
                        <span className={styles.seniormid1}>(Senior/Mid)</span>
                        <b className={styles.b}>{` `}</b>
                      </p>
                    </div>
                  </div>
                  <div className={styles.text1}>Salary: 20k-36k PLN /month</div>
                </div>
                <div className={styles.info}>
                  <div className={styles.content3}>
                    <img
                      className={styles.materialSymbolsLightmyLocaIcon}
                      alt=""
                      src="/materialsymbolslightmylocationoutline.svg"
                    />
                    <div className={styles.text2}>Fully Remote possible</div>
                  </div>
                  <div className={styles.content4}>
                    <img
                      className={styles.materialSymbolsLightmyLocaIcon}
                      alt=""
                      src="/bitcoiniconsclockoutline.svg"
                    />
                    <div className={styles.text3}>B2B</div>
                  </div>
                </div>
              </div>
              <StyleSecondarySmallTrueD
                button="More"
                styleSecondarySmallTrueDBackgroundColor="transparent"
                buttonDisplay="inline-block"
              />
            </div>
          </div>
          */ }
          <SalaryCard
            jobTitle="DevOps Engineer"
            employeeLevel="(Senior/Mid)"
            salaryRange="Salary: 15k-28k PL /month"
            workArrangement="Fully Remote possible"
            onButtonContainerClick={onDevopsSeniorClick}
          />
          <SalaryCard
            jobTitle="Business Analyst"
            employeeLevel="(Senior)"
            salaryRange="Salary: 15k-20k PLN /month"
            workArrangement="Partly Remte"
            onButtonContainerClick={onButtonContainer2Click}
          />
        </div>
      </section>
    </div>
  );
};

export default FormContainer;
