import {useExample, useTypingAnimation, useWindowWidth} from "../addons/scalaExamples";
import {memo, useEffect, useMemo, useRef} from "react";
import Carousel from "../addons/Carousel";
import styled from "styled-components";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CODE_WIDTH = 500

const sample = (array, size) => {
  return [...array]
    .sort(Math.random)
    .slice(0, size)
}

const AnimatedExamples = ({ links, carousel = false }) => {
  const windowWidth = useWindowWidth()

  const data = useMemo(() => {
    const amount = Math.ceil(windowWidth / CODE_WIDTH)
    const randomSample = sample(links, amount)
    return randomSample.map(link => ({ link }))
  }, [links, windowWidth])

  return (
    carousel ? (
      <Carousel
        data={data}
        dataRepeat={1}
        itemWidth={CODE_WIDTH}
        ItemComponent={ScalaExample}
      />
    ) : (
      <StaticRow>
        {data.map((props, index) => (
          <ScalaExample
            {...props}
            key={index}
          />
        ))}
      </StaticRow>
    )
  )
}

const StaticRow = styled.div`
  display: flex;
  flex-direction: row;
`

export default AnimatedExamples

const ScalaExample = ({ link }) => {
  const fileContent = useExample(link)
  const currentContent = useTypingAnimation(fileContent, 10, 3)

  const ref = useRef()
  useEffect(() => {
    if (!ref.current) return
    ref.current.scrollTo(0, ref.current.scrollHeight)
  }, [ref.current, currentContent])

  return (
    <CodeContainer ref={ref}>
      <Highlighter content={currentContent}/>
    </CodeContainer>
  )
}

const CodeContainer = styled.div`
  font-size: 16px;
  color: white;
  width: ${CODE_WIDTH}px;
  height: 184px;
  overflow: hidden;
  background-color: #282c34;
  * {
    overflow: hidden !important;
  }
`

const Highlighter = memo(({ content }) => {
  return (
    <SyntaxHighlighter language='scala' style={oneDark}>
      {content}
    </SyntaxHighlighter>
  )
})