// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ITProductDevelopmentContainer_row__Dfq5Q {
  flex: 1 1;
  gap: 32px;
}
.ITProductDevelopmentContainer_container__HW4to,
.ITProductDevelopmentContainer_container1__FhULe,
.ITProductDevelopmentContainer_row__Dfq5Q {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.ITProductDevelopmentContainer_container1__FhULe {
  flex: 1 1;
}
.ITProductDevelopmentContainer_container__HW4to {
  align-self: stretch;
}
`, "",{"version":3,"sources":["webpack://./src/components/ITProductDevelopmentContainer.module.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,SAAS;AACX;AACA;;;EAGE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,uBAAuB;AACzB;AACA;EACE,SAAO;AACT;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".row {\n  flex: 1;\n  gap: 32px;\n}\n.container,\n.container1,\n.row {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  justify-content: center;\n}\n.container1 {\n  flex: 1;\n}\n.container {\n  align-self: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `ITProductDevelopmentContainer_row__Dfq5Q`,
	"container": `ITProductDevelopmentContainer_container__HW4to`,
	"container1": `ITProductDevelopmentContainer_container1__FhULe`
};
export default ___CSS_LOADER_EXPORT___;
