// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StylePrimarySmallTrueDar_button__5gMjX {
  position: relative;
  line-height: 150%;
}
.StylePrimarySmallTrueDar_styleprimarySmalltrueDar__MOiJR {
  border-radius: 4px;
  background-color: #1c1c1c;
  border: 1px solid #1c1c1c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  text-align: left;
  font-size: 16px;
  color: #fefefe;
  font-family: Roboto;
}
.StylePrimarySmallTrueDar_styleprimarySmalltrueDar__MOiJR:hover {
  background: linear-gradient(
      rgba(229, 229, 229, 0.15),
      rgba(229, 229, 229, 0.15)
    ),
    #1b1b1b;
  cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/components/StylePrimarySmallTrueDar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE;;;;WAIS;EACT,eAAe;AACjB","sourcesContent":[".button {\n  position: relative;\n  line-height: 150%;\n}\n.styleprimarySmalltrueDar {\n  border-radius: 4px;\n  background-color: #1c1c1c;\n  border: 1px solid #1c1c1c;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 20px;\n  text-align: left;\n  font-size: 16px;\n  color: #fefefe;\n  font-family: Roboto;\n}\n.styleprimarySmalltrueDar:hover {\n  background: linear-gradient(\n      rgba(229, 229, 229, 0.15),\n      rgba(229, 229, 229, 0.15)\n    ),\n    #1b1b1b;\n  cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `StylePrimarySmallTrueDar_button__5gMjX`,
	"styleprimarySmalltrueDar": `StylePrimarySmallTrueDar_styleprimarySmalltrueDar__MOiJR`
};
export default ___CSS_LOADER_EXPORT___;
