import { useState } from "react";
import PopUpContact from "./PopUpContact";
import PortalPopup from "./PortalPopup";
import CardStart from "./CardStart";
import styles from "./StartProjectContainer1.module.css";
import Jumpscare from "../addons/Jumpscare";

const StartProjectContainer1 = () => {
  return (
    <section className={styles.container}>
      <div className={styles.container1}>
        <div className={styles.row}>
          <Jumpscare variant={'left'}>
            <CardStart
              cardStartBorder="unset"
              cardStartPadding="unset"
              headingDisplay="inline-block"
              textDisplay="inline-block"
            />
          </Jumpscare>
          <div className={styles.getReadyForACloudMakeoverParent}>
            <b className={styles.getReadyFor}>
              <Jumpscare variant={'up'}>Get ready for a cloud makeover!</Jumpscare>
            </b>
            <div className={styles.navigateTheCloud}>
              <Jumpscare variant={'right'}>
                Navigate the cloud with ease! We simplify complexity and create a
                future-ready tech hub that mirrors your unique style. Buckle up
                for a business spotlight like never before!"
              </Jumpscare>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartProjectContainer1;
