import styles from "./CloudMigrationContainer1.module.css";

const CloudMigrationContainer1 = ({
  serviceDescription,
  cloudMigrationDescription,
}) => {
  return (
    <div className={styles.column}>
      <img
        className={styles.iconamooncheckBold}
        alt=""
        src="/iconamooncheckbold.svg"
      />
      <div className={styles.content}>
        <b className={styles.heading}>{serviceDescription}</b>
        <div className={styles.text}>{cloudMigrationDescription}</div>
      </div>
    </div>
  );
};

export default CloudMigrationContainer1;
