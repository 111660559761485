import StyleSecondarySmallTrueD from "./StyleSecondarySmallTrueD";
import styles from "./SalaryCard.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";

const SalaryCard = ({
  jobTitle,
  employeeLevel,
  salaryRange,
  workArrangement,
  onButtonContainerClick,
}) => {
  const mediaQuery1090 = useMediaQuery('(max-width: 1091px)')

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <div className={styles.content1}>
          <div className={styles.job}>
            <div className={styles.jobTitle}>
              <div className={styles.heading}>
                <p className={styles.devopsEnginner}>
                  <b className={styles.devopsEnginner1}>
                    <Jumpscare>{jobTitle}</Jumpscare>
                  </b>
                </p>
                <p className={styles.junior}>
                  <span className={styles.junior1}>
                    <Jumpscare delay={mediaQuery1090 ? 0 : 0.25}>{employeeLevel}</Jumpscare>
                  </span>
                  <b className={styles.b}>{` `}</b>
                </p>
              </div>
            </div>
            <div className={styles.text}>
              <Jumpscare delay={mediaQuery1090 ? 0 : 0.5}>{salaryRange}</Jumpscare>
            </div>
          </div>
          <div className={styles.info}>
            <Jumpscare delay={mediaQuery1090 ? 0 : 0.75}>
              <div className={styles.content2}>
                <img
                  className={styles.materialSymbolsLightmyLocaIcon}
                  alt=""
                  src="/materialsymbolslightmylocationoutline.svg"
                />
                <div className={styles.text1}>{workArrangement}</div>
              </div>
            </Jumpscare>
            <Jumpscare delay={mediaQuery1090 ? 0 : 1}>
              <div className={styles.content3}>
                <img
                  className={styles.materialSymbolsLightmyLocaIcon}
                  alt=""
                  src="/bitcoiniconsclockoutline.svg"
                />
                <div className={styles.text2}>B2B</div>
              </div>
            </Jumpscare>
          </div>
        </div>
        <Jumpscare delay={mediaQuery1090 ? 0 : 1.25}>
          <StyleSecondarySmallTrueD
            button="More"
            styleSecondarySmallTrueDBackgroundColor="unset"
            buttonDisplay="inline-block"
            onClick={onButtonContainerClick}
          />
        </Jumpscare>
      </div>
    </div>
  );
};

export default SalaryCard;
