import styles from "./ConsultationCard.module.css";
import Jumpscare from "../addons/Jumpscare";

const ConsultationCard = () => {
  return (
    <div className={styles.timeline}>
      <div className={styles.timelineItem}>
        <div className={styles.content}>
          <div className={styles.div}>1</div>
          <div className={styles.content1}>
            <img
              className={styles.consultationIcon}
              alt=""
              src="/consultation-icon.svg"
            />
            <div className={styles.divider} />
          </div>
        </div>
        <div className={styles.content2}>
          <Jumpscare variant='right'>
            <h5 className={styles.heading}>
              Free first consultation
            </h5>
          </Jumpscare>
          <div className={styles.text}>
            <Jumpscare variant='right'>
              The initial consultation for your project is always free and
              provides an opportunity for us to discuss your ideas, goals, and
              preferences in detail.
            </Jumpscare>
          </div>
        </div>
      </div>
      <div className={styles.timelineItem}>
        <div className={styles.content}>
          <div className={styles.div}>2</div>
          <div className={styles.content1}>
            <img
              className={styles.consultationIcon}
              alt=""
              src="/identification.svg"
            />
            <div className={styles.divider} />
          </div>
        </div>
        <div className={styles.content2}>
          <Jumpscare variant='right'><h5 className={styles.heading}>Identification</h5></Jumpscare>
          <Jumpscare variant='right'>
            <div className={styles.text}>
              Determine the project's specific objectives, long-term goals, and
              strategic aims.
            </div>
          </Jumpscare>
        </div>
      </div>
      <div className={styles.timelineItem}>
        <div className={styles.content}>
          <div className={styles.div}>3</div>
          <div className={styles.content1}>
            <img
              className={styles.consultationIcon}
              alt=""
              src="/project-initiation.svg"
            />
            <div className={styles.divider} />
          </div>
        </div>
        <div className={styles.content2}>
          <Jumpscare variant='right'><h5 className={styles.heading}>Project initiation</h5></Jumpscare>
          <Jumpscare variant='right'>
            <div className={styles.text}>
              The project is agreed upon and our professionals start working on it
              accordingly.
            </div>
          </Jumpscare>
        </div>
      </div>
      <div className={styles.timelineItem}>
        <div className={styles.content}>
          <div className={styles.div}>4</div>
          <div className={styles.content1}>
            <img
              className={styles.consultationIcon}
              alt=""
              src="/dev-process.svg"
            />
            <div className={styles.divider} />
          </div>
        </div>
        <div className={styles.content2}>
          <Jumpscare variant={'right'}>
            <h5 className={styles.heading}>
              Development process
            </h5>
          </Jumpscare>
          <Jumpscare variant={'right'}>
            <div className={styles.text}>
              It is imperative that we receive your feedback on the current stage
              of the product during its development.
            </div>
          </Jumpscare>
        </div>
      </div>
      <div className={styles.timelineItem}>
        <div className={styles.content}>
          <div className={styles.div}>5</div>
          <div className={styles.content1}>
            <img
              className={styles.consultationIcon}
              alt=""
              src="/product-delivery.svg"
            />
            <div className={styles.divider} />
          </div>
        </div>
        <div className={styles.content2}>
          <Jumpscare variant={'right'}><h5 className={styles.heading}>Product Delivery</h5></Jumpscare>
          <Jumpscare variant={'right'}>
            <div className={styles.text}>
              Upon completion of the product development, we ensure swift and
              seamless delivery.
            </div>
          </Jumpscare>
        </div>
      </div>
      <div className={styles.timelineItem}>
        <div className={styles.content}>
          <div className={styles.div}>6</div>
          <div className={styles.content1}>
            <img
              className={styles.consultationIcon}
              alt=""
              src="/support.svg"
            />
          </div>
        </div>
        <div className={styles.content2}>
          <Jumpscare variant={'right'}><h5 className={styles.heading}>Support</h5></Jumpscare>
          <Jumpscare variant={'right'}>
            <div className={styles.text}>
              Following the delivery, we ensure that you are thoroughly informed
              about the product and promptly address any potential issues.
            </div>
          </Jumpscare>
        </div>
      </div>
    </div>
  );
};

export default ConsultationCard;
