// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardStart_content1__fHQCF {
  width: 149.8px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.CardStart_startAProject__qwwA9 {
  margin: 0;
}
.CardStart_heading__5s4tn {
  position: relative;
  line-height: 150%;
  font-weight: 600;
}
.CardStart_content2__Uify5,
.CardStart_heading__5s4tn,
.CardStart_text__ipcYV {
  align-self: stretch;
}
.CardStart_text__ipcYV {
  position: relative;
  font-size: 16px;
  line-height: 150%;
  font-weight: 300;
}
.CardStart_content2__Uify5 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.CardStart_cardStart__kAOj8,
.CardStart_content__aKXcl,
.CardStart_content2__Uify5,
.CardStart_contentTop__6Vl2o {
  display: flex;
  flex-direction: column;
}
.CardStart_contentTop__6Vl2o {
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.CardStart_cardStart__kAOj8,
.CardStart_content__aKXcl {
  height: 328px;
  align-items: flex-start;
  justify-content: center;
}
.CardStart_content__aKXcl {
  width: 312px;
  padding: 32px;
  box-sizing: border-box;
}
.CardStart_cardStart__kAOj8 {
  width: 304px;
  border-radius: 8px;
  background-color: #1c1c1c;
  min-width: 267px;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  color: #fefefe;
  font-family: Roboto;
}
.CardStart_cardStart__kAOj8:hover {
  background-color: rgba(28, 28, 28, 0.8);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/CardStart.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;;;EAGE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,SAAS;AACX;AACA;;;;EAIE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;AACX;AACA;;EAEE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,uCAAuC;EACvC,eAAe;AACjB","sourcesContent":[".content1 {\n  width: 149.8px;\n  height: 80px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n}\n.startAProject {\n  margin: 0;\n}\n.heading {\n  position: relative;\n  line-height: 150%;\n  font-weight: 600;\n}\n.content2,\n.heading,\n.text {\n  align-self: stretch;\n}\n.text {\n  position: relative;\n  font-size: 16px;\n  line-height: 150%;\n  font-weight: 300;\n}\n.content2 {\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 16px;\n}\n.cardStart,\n.content,\n.content2,\n.contentTop {\n  display: flex;\n  flex-direction: column;\n}\n.contentTop {\n  align-self: stretch;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 24px;\n}\n.cardStart,\n.content {\n  height: 328px;\n  align-items: flex-start;\n  justify-content: center;\n}\n.content {\n  width: 312px;\n  padding: 32px;\n  box-sizing: border-box;\n}\n.cardStart {\n  width: 304px;\n  border-radius: 8px;\n  background-color: #1c1c1c;\n  min-width: 267px;\n  cursor: pointer;\n  text-align: center;\n  font-size: 24px;\n  color: #fefefe;\n  font-family: Roboto;\n}\n.cardStart:hover {\n  background-color: rgba(28, 28, 28, 0.8);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content1": `CardStart_content1__fHQCF`,
	"startAProject": `CardStart_startAProject__qwwA9`,
	"heading": `CardStart_heading__5s4tn`,
	"content2": `CardStart_content2__Uify5`,
	"text": `CardStart_text__ipcYV`,
	"cardStart": `CardStart_cardStart__kAOj8`,
	"content": `CardStart_content__aKXcl`,
	"contentTop": `CardStart_contentTop__6Vl2o`
};
export default ___CSS_LOADER_EXPORT___;
