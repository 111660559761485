import styled from 'styled-components'
import Carousel from '../addons/Carousel'

const data = [
  {
    src: 'technologies-badges.svg',
    alt: 'Technologies'
  }
]

const TechnologiesCarousel = () => {
  return (
    <Root>
      <Carousel
        data={data}
        dataRepeat={3}
        itemWidth={2349}
        ItemComponent={Image}
      />
    </Root>
  )
}

export default TechnologiesCarousel

const Image = styled.img`
  width: 2349px;
  height: 100px;
`

const Root = styled.div`
  margin-left: -64px;
`
