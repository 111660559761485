// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloudMigrationProcessContainer_heading__eBwRn {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}
.CloudMigrationProcessContainer_content__qw4Ms,
.CloudMigrationProcessContainer_content3__weq6d,
.CloudMigrationProcessContainer_timeline__rCLHU {
  display: flex;
  align-items: flex-start;
}
.CloudMigrationProcessContainer_content__qw4Ms {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.CloudMigrationProcessContainer_content3__weq6d,
.CloudMigrationProcessContainer_timeline__rCLHU {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.CloudMigrationProcessContainer_timeline__rCLHU {
  flex: 1 1;
  min-width: 300px;
}
.CloudMigrationProcessContainer_content3__weq6d {
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  max-width: 1312px;
  text-align: left;
  font-size: 16px;
}
.CloudMigrationProcessContainer_contentParent__XOKhK,
.CloudMigrationProcessContainer_workProcess__99T1u {
  align-self: stretch;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
}
.CloudMigrationProcessContainer_contentParent__XOKhK {
  align-items: center;
  justify-content: flex-start;
  gap: 62px;
}
.CloudMigrationProcessContainer_workProcess__99T1u {
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  padding: 80px 64px 0;
  text-align: center;
  font-size: 48px;
  color: #1c1c1c;
  font-family: Roboto;
}

@media screen and (max-width: 499px) {
  .CloudMigrationProcessContainer_heading__eBwRn {
    font-size: 36px;
    line-height: 43.2px;
    margin: -40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CloudMigrationProcessContainer.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;;;EAGE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;;EAEE,mBAAmB;EACnB,eAAe;EACf,uBAAuB;AACzB;AACA;EACE,SAAO;EACP,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;AACA;;EAEE,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;AACX;AACA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,uBAAuB;EACvB,oBAAoB;EACpB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;IACnB,aAAa;EACf;AACF","sourcesContent":[".heading {\n  margin: 0;\n  align-self: stretch;\n  position: relative;\n  font-size: inherit;\n  line-height: 120%;\n  font-weight: 700;\n  font-family: inherit;\n}\n.content,\n.content3,\n.timeline {\n  display: flex;\n  align-items: flex-start;\n}\n.content {\n  align-self: stretch;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.content3,\n.timeline {\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n.timeline {\n  flex: 1;\n  min-width: 300px;\n}\n.content3 {\n  width: 100%;\n  padding: 32px;\n  box-sizing: border-box;\n  max-width: 1312px;\n  text-align: left;\n  font-size: 16px;\n}\n.contentParent,\n.workProcess {\n  align-self: stretch;\n  background-color: #f6f6f6;\n  display: flex;\n  flex-direction: column;\n}\n.contentParent {\n  align-items: center;\n  justify-content: flex-start;\n  gap: 62px;\n}\n.workProcess {\n  overflow: hidden;\n  align-items: flex-start;\n  justify-content: center;\n  padding: 80px 64px 0;\n  text-align: center;\n  font-size: 48px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n\n@media screen and (max-width: 499px) {\n  .heading {\n    font-size: 36px;\n    line-height: 43.2px;\n    margin: -40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `CloudMigrationProcessContainer_heading__eBwRn`,
	"content": `CloudMigrationProcessContainer_content__qw4Ms`,
	"content3": `CloudMigrationProcessContainer_content3__weq6d`,
	"timeline": `CloudMigrationProcessContainer_timeline__rCLHU`,
	"contentParent": `CloudMigrationProcessContainer_contentParent__XOKhK`,
	"workProcess": `CloudMigrationProcessContainer_workProcess__99T1u`
};
export default ___CSS_LOADER_EXPORT___;
