import {useCallback, useState} from 'react'

const useSlides = data => {
  const [pointer, setPointer] = useState(0)
  const handleGoBack = useCallback(() => {
    setPointer(prev => Math.max(0, prev - 1))
  }, [])
  const handleGoForward = useCallback(() => {
    setPointer(prev => Math.min(data.length - 1, prev + 1))
  }, [data.length])
  return {
    current: data[pointer],
    pointer,
    handleGoBack,
    handleGoForward
  }
}

export default useSlides