// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/cta@3x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContainerCTA_heading__WyeIH {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}
.ContainerCTA_content__hUKfc,
.ContainerCTA_heading__WyeIH,
.ContainerCTA_text__NOyCW {
  align-self: stretch;
}
.ContainerCTA_text__NOyCW {
  position: relative;
  font-size: 24px;
  line-height: 150%;
  font-weight: 300;
}
.ContainerCTA_content__hUKfc {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.ContainerCTA_actions__5zRK\\+,
.ContainerCTA_column__joJfv,
.ContainerCTA_content__hUKfc,
.ContainerCTA_cta__xjj-I {
  display: flex;
  justify-content: flex-start;
}
.ContainerCTA_actions__5zRK\\+ {
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 0 0;
}
.ContainerCTA_column__joJfv,
.ContainerCTA_cta__xjj-I {
  flex-direction: column;
}
.ContainerCTA_column__joJfv {
  width: 100%;
  align-items: flex-start;
  gap: 24px;
  max-width: 1312px;
}
.ContainerCTA_cta__xjj-I {
  align-self: stretch;
  overflow: hidden;
  align-items: center;
  padding: 80px 64px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: 48px;
  color: #fefefe;
  font-family: Roboto;
}

@media screen and (max-width: 535px) {
  .ContainerCTA_heading__WyeIH {
    font-size: calc(20px + 4vw);
  }
  .ContainerCTA_cta__xjj-I {
    padding: 40px 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ContainerCTA.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;;;EAGE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;AACX;AACA;;;;EAIE,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;AACA;;EAEE,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,SAAS;EACT,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,yDAAyC;EACzC,sBAAsB;EACtB,4BAA4B;EAC5B,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".heading {\n  margin: 0;\n  position: relative;\n  font-size: inherit;\n  line-height: 120%;\n  font-weight: 700;\n  font-family: inherit;\n}\n.content,\n.heading,\n.text {\n  align-self: stretch;\n}\n.text {\n  position: relative;\n  font-size: 24px;\n  line-height: 150%;\n  font-weight: 300;\n}\n.content {\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 24px;\n}\n.actions,\n.column,\n.content,\n.cta {\n  display: flex;\n  justify-content: flex-start;\n}\n.actions {\n  flex-direction: row;\n  align-items: flex-start;\n  padding: 16px 0 0;\n}\n.column,\n.cta {\n  flex-direction: column;\n}\n.column {\n  width: 100%;\n  align-items: flex-start;\n  gap: 24px;\n  max-width: 1312px;\n}\n.cta {\n  align-self: stretch;\n  overflow: hidden;\n  align-items: center;\n  padding: 80px 64px;\n  background-image: url(/public/cta@3x.png);\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: top;\n  text-align: left;\n  font-size: 48px;\n  color: #fefefe;\n  font-family: Roboto;\n}\n\n@media screen and (max-width: 535px) {\n  .heading {\n    font-size: calc(20px + 4vw);\n  }\n  .cta {\n    padding: 40px 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `ContainerCTA_heading__WyeIH`,
	"content": `ContainerCTA_content__hUKfc`,
	"text": `ContainerCTA_text__NOyCW`,
	"actions": `ContainerCTA_actions__5zRK+`,
	"column": `ContainerCTA_column__joJfv`,
	"cta": `ContainerCTA_cta__xjj-I`
};
export default ___CSS_LOADER_EXPORT___;
