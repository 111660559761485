import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import ProductDevelopmentContainer from "../components/ProductDevelopmentContainer";
import DeliveringItSolutionsContainer from "../components/DeliveringItSolutionsContainer";
import WorkProcessContainer1 from "../components/WorkProcessContainer1";
import TransformYourBusinessContainer from "../components/TransformYourBusinessContainer";
import FAQContainer from "../components/FAQContainer";
import Footer from "../components/Footer";
import styles from "./ServicesItSoftwareDevelopme.module.css";

const faqs = [
  {
    question: 'What is Software Development?',
    answer: 'Software development is the process of creating, designing, testing, and maintaining computer programs and applications. It involves a series of steps that start with defining the requirements and specifications of the software and continue through coding, testing, debugging, deployment, and ongoing maintenance.'
  },
  {
    question: 'Why choose our services?',
    answer: 'Our services stand out for several reasons. Firstly, we prioritize customer satisfaction by offering top-notch quality in every aspect of our work. Our team is composed of skilled professionals who bring a wealth of experience and expertise to the table. We are committed to delivering timely and reliable solutions tailored to meet the unique needs of our clients.'
  },
  {
    question: 'How long does it take to develop software?',
    answer: 'Developing software timelines can vary based on the complexity of the project, requirements, and various other factors. Our team prides itself on efficient and agile development processes, which often results in quicker turnaround times compared to industry averages.'
  },
  {
    question: 'What technologies do you use?',
    answer: 'We utilize a diverse tech stack, including Kotlin, JavaScript, Docker, Azure, Scala, Spring, Java 8/11, Angular, React, AWS, and Google technologies, to ensure the delivery of robust and cutting-edge solutions.'
  },
  {
    question: 'Can you customize software?',
    answer: 'Customization is at the core of our software development philosophy. We pride ourselves on tailoring solutions to meet the unique needs of our clients, ensuring that our software aligns seamlessly with their specific requirements, preferences, and business goals. Our experienced team is dedicated to working closely with you to understand your vision and deliver a fully customized software solution that not only meets but exceeds your expectations.'
  }
]

const ServicesItSoftwareDevelopme = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  return (
    <div className={styles.servicesitSoftwareDevelopme}>
      <Navbar
        frame11965="/frame-119659@2x.png"
        onLogoClick={onLogoClick}
      />
      <ProductDevelopmentContainer />
      <DeliveringItSolutionsContainer />
      <WorkProcessContainer1 />
      <TransformYourBusinessContainer />
      <FAQContainer
        faqText="Find quick answers to common queries about our IT Software Development services."
        faqs={faqs}
      />
      <Footer
        frame11965="/frame-1196510@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default ServicesItSoftwareDevelopme;
