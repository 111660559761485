import StylePrimarySmallTrueDar from "./StylePrimarySmallTrueDar";
import styles from "./TransformYourBusinessContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";
import {useMediaQuery} from "@mui/material";

const TransformYourBusinessContainer = () => {
  const openPopup = useSetPopupState()

  const mediaQuery835 = useMediaQuery('(max-width: 835px)')

  return (
    <section className={styles.transformYourBusiness}>
      <div className={styles.content}>
        {mediaQuery835 && (
          <Jumpscare variant={'right'}>
            <img className={styles.contentChild} alt="" src="/group-11613.svg"/>
          </Jumpscare>
        )}
        <div className={styles.content1}>
          <h2 className={styles.heading}>
            <Jumpscare threshold={0.9}>Transform Your Business with great software</Jumpscare>
          </h2>
          <div className={styles.text}>
            <Jumpscare threshold={0.9}>
              Exploring automation for your organization, optimizing solutions for
              performance, or reaching the end of your application lifecycle?
              Let's discuss your needs!
            </Jumpscare>
          </div>
          <div className={styles.button}>
            <Jumpscare>
              <StylePrimarySmallTrueDar
                onClick={() => openPopup ({
                  title: 'Transform Your Business!',
                  description: 'Explore opportunities that we offer'
                })}
                button="Get a free quote"
                stylePrimarySmallTrueDarPosition="unset"
                stylePrimarySmallTrueDarTop="unset"
                stylePrimarySmallTrueDarRight="unset"
                stylePrimarySmallTrueDarWidth="unset"
                buttonDisplay="inline-block"
              />
            </Jumpscare>
          </div>
        </div>
        {/*<Jumpscare variant={'right'}>*/}
        {/*  <img className={styles.contentChild} alt="" src="/group-11613.svg"/>*/}
        {/*</Jumpscare>*/}
      </div>
    </section>
  );
};

export default TransformYourBusinessContainer;
