import { useMemo } from "react";
import styles from "./StylePrimarySmallTrueDar.module.css";

const StylePrimarySmallTrueDar = ({
  button,
  stylePrimarySmallTrueDarPosition,
  stylePrimarySmallTrueDarTop,
  stylePrimarySmallTrueDarRight,
  stylePrimarySmallTrueDarWidth,
  buttonDisplay,
  onClick = (() => {})
}) => {
  const stylePrimarySmallTrueDarStyle = useMemo(() => {
    return {
      position: stylePrimarySmallTrueDarPosition,
      top: stylePrimarySmallTrueDarTop,
      right: stylePrimarySmallTrueDarRight,
      width: stylePrimarySmallTrueDarWidth,
    };
  }, [
    stylePrimarySmallTrueDarPosition,
    stylePrimarySmallTrueDarTop,
    stylePrimarySmallTrueDarRight,
    stylePrimarySmallTrueDarWidth,
  ]);

  const button2Style = useMemo(() => {
    return {
      display: buttonDisplay,
    };
  }, [buttonDisplay]);

  return (
    <div
      onClick={onClick}
      className={styles.styleprimarySmalltrueDar}
      style={stylePrimarySmallTrueDarStyle}
    >
      <div className={styles.button} style={button2Style}>
        {button}
      </div>
    </div>
  );
};

export default StylePrimarySmallTrueDar;
