import PropTypes from "prop-types";
import styles from "./About.module.css";
import Jumpscare from "../addons/Jumpscare";

const About = ({ className = "" }) => {
  return (
    <section className={[styles.about, className].join(" ")}>
      <div className={styles.content}>
        <div className={styles.content1}>
          <div className={styles.headingParent}>
            <Jumpscare>
              <h1 className={styles.heading}>
                Sofixit thinks we drive positive change!
              </h1>
            </Jumpscare>
            <Jumpscare>
              <h2
                className={styles.heading1}
              >{`Therefore, we decided to create this space for our experts and for industry colleagues! `}</h2>
            </Jumpscare>
          </div>
          <div className={styles.description}>
            <p className={styles.mentoringIsANaturalToolFo}>
              <span>
                <Jumpscare variant="left">
                  <span>
                    Mentoring is a natural tool for knowledge transfer between
                    generations, contributing to the optimization of processes
                    in multi-generational teams and the entire organization.
                  </span>
                </Jumpscare>
              </span>
            </p>
            <p className={styles.blankLine}>
              <span>
                <span>&nbsp;</span>
              </span>
            </p>
            <p className={styles.theMentoringProgramBigDat}>
              <Jumpscare variant="right">
                <span>
                  <span>The mentoring program</span>
                  <span className={styles.span}>{` `}</span>
                </span>
                <span className={styles.bigData}>
                  “BIG Data – BIG Challenges – BIG Mentoring
                </span>
                <b className={styles.b}>{` `}</b>
                <span className={styles.programForYourBigRealSucc}>
                  {/*<span
                        className={styles.programForYour}
                      >{`Program for your BIG REAL Success“ will be a `}</span>*/}
                  <span
                    className={styles.programForYour}
                  ><b>Program for your BIG REAL Success“</b> will be a </span>
                  <span className={styles.uniqueSpace}>unique space</span>
                </span>
                <span>
                  <span className={styles.span1}>{` `}</span>
                  <span>for people with similar professional interests.</span>
                </span>
              </Jumpscare>
            </p>
            <p className={styles.blankLine1}>
              <span>
                <span>&nbsp;</span>
              </span>
            </p>
            <p className={styles.itWillAllowIndividualsToS}>
              <span>
                <Jumpscare variant="left">
                  <span>
                    It will allow individuals to share knowledge and experience,
                    thereby shaping best practices in the industry and enriching
                    themselves with the experiences of others.
                  </span>
                </Jumpscare>
              </span>
            </p>
          </div>
        </div>
        <Jumpscare variant="right">
          <img
            className={styles.contentChild}
            loading="lazy"
            alt=""
            src="/group-11650-1@2x.png"
          />
        </Jumpscare>
      </div>
    </section>
  );
};

About.propTypes = {
  className: PropTypes.string,
};

export default About;
