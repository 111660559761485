import PropTypes from "prop-types";
import styles from "./Services.module.css";
import Jumpscare from "../addons/Jumpscare";
import {openMenteeForm, openMentorForm} from "../addons/mentoring";

const Services = ({ className = "" }) => {
  return (
    <>
      <Jumpscare variant="bottom" stretch>
        <section className={[styles.cta, className].join(" ")}>
          <div className={styles.content}>
            <div className={styles.contentTop}>
              <div className={styles.content1}>
                <div className={styles.title}>
                  <h1 className={styles.heading}>
                    <span>{`MENTOR `}</span>
                    <span className={styles.or}>OR</span>
                    <span> MENTEE?</span>
                  </h1>
                </div>
                <div className={styles.title1}>
                  <h2 className={styles.heading1}>
                    Just fill in the form and wait for hearing from us
                  </h2>
                </div>
              </div>
              <img
                className={styles.joinNowIconPink}
                loading="lazy"
                alt=""
                src="/join-now-icon-pink.svg"
              />
              <div className={styles.container}>
                <button
                  className={styles.button}
                  onClick={openMenteeForm}
                >
                  <div className={styles.button1}>
                    <span className={styles.signUpAs}>{`Sign Up as a `}</span>
                    <span className={styles.mentee}>Mentee</span>
                  </div>
                </button>
                <div
                  className={styles.button2}
                  onClick={openMentorForm}
                >
                  <div className={styles.button3}>
                    <span>{`I Want to be a `}</span>
                    <span className={styles.mentor}><b>Mentor</b></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Jumpscare>
      {/* isPopUpContactOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpContact}
        >
          <PopUpContact onClose={closePopUpContact} />
        </PortalPopup>
      ) */}
    </>
  );
};

Services.propTypes = {
  className: PropTypes.string,
};

export default Services;
