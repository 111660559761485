import PropTypes from "prop-types";
import styles from "./Layout.module.css";
import Jumpscare from "../addons/Jumpscare";

const Layout = ({ className = "" }) => {
  return (
    <section className={[styles.layout246, className].join(" ")}>
      <div className={styles.content}>
        <Jumpscare threshold={0.7} variant="left">
          <div className={styles.frameParent}>
            <img
              className={styles.frameChild}
              loading="lazy"
              alt=""
              src="/group-11650@2x.png"
            />
            <div className={styles.sectionTitle}>
              <div className={styles.column}>
                <h1 className={styles.heading}>WHY A MENTORING PROGRAM?</h1>
              </div>
            </div>
          </div>
        </Jumpscare>
        <div className={styles.row}>
          <div className={styles.listItem}>
            <Jumpscare variant="left">
              <img
                className={styles.iconRelume}
                alt=""
                src="/icon--relume.svg"
              />
              <div className={styles.heading1}>
                <p className={styles.mentoringProgramsContribute}>
                  <span
                    className={styles.mentoringProgramsContribute1}
                  >{`Mentoring programs contribute to the `}</span>
                  <span className={styles.growthOfSelfAwareness}>
                    growth of self-awareness
                  </span>
                  <span>{` for both individuals and entire organizations. `}</span>
                </p>
                <p className={styles.blankLine}>&nbsp;</p>
                <p className={styles.employeesWithAMentorArePr}>
                  <span>{`Employees `}</span>
                  <span className={styles.withAMentor}>with a mentor</span>
                  <span className={styles.are}>{` are `}</span>
                  <span className={styles.promoted5Times}>
                    promoted 5 times faste
                  </span>
                  <span className={styles.rThanOthers}>r than others.</span>
                </p>
              </div>
              <div className={styles.text}>
                Learn from industry experts and gain valuable insights to
                enhance your skills.
              </div>
              <div className={styles.action}>
                <div className={styles.stylelinkSmallfalseDark}>
                  <div className={styles.button}>Learn More</div>
                  <img
                    className={styles.iconChevronRight}
                    alt=""
                    src="/icon--chevronright.svg"
                  />
                </div>
              </div>
              <div className={styles.listItem1}>
                <img
                  className={styles.iconRelume1}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.heading2}>
                  All companies in the top <b>50 of the Fortune 500</b> list have
                  developed mentoring programs, and <b>96% of the top 100 companies</b>{' '} have them.
                </div>
                <div className={styles.text1}>
                  Receive guidance and support to advance your career in the
                  DATA field.
                </div>
                <div className={styles.action1} />
              </div>
            </Jumpscare>
          </div>
          <div className={styles.listItem2}>
            <Jumpscare variant="right">
              <img
                className={styles.iconRelume2}
                alt=""
                src="/icon--relume.svg"
              />
              <div className={styles.heading3}>
                <p className={styles.ofEmployeesWithAMentorRep}>
                  <span
                    className={styles.ofEmployeesWith}
                  >{`91% of employees with a mentor report `}</span>
                  <span className={styles.greaterJobSatisfaction}>
                    greater job satisfaction
                  </span>
                  <span>.</span>
                </p>
                <p className={styles.blankLine1}>&nbsp;</p>
                <p className={styles.workingWithAMentorToBoost}>
                  <span>Working with a mentor to boost potential</span>
                  <span className={styles.increasesJobSatisfaction}>
                    {" "}
                    increases job satisfaction by 35%
                  </span>
                  <span className={styles.span}>.</span>
                </p>
              </div>
              <div className={styles.text2}>
                Receive guidance and support to advance your career in the DATA
                field.
              </div>
              <div className={styles.action2}>
                <div className={styles.stylelinkSmallfalseDark1}>
                  <div className={styles.button1}>Learn More</div>
                  <img
                    className={styles.iconChevronRight1}
                    alt=""
                    src="/icon--chevronright.svg"
                  />
                </div>
              </div>
              <div className={styles.listItem3}>
                <img
                  className={styles.iconRelume3}
                  alt=""
                  src="/icon--relume.svg"
                />
                <div className={styles.heading4}>
                  <span>{`87% of mentees feel `}</span>
                  <span
                    className={styles.moreEmpoweredAnd}
                  >{`more empowered and confident `}</span>
                  <span>thanks to their mentoring relationship.</span>
                </div>
                <div className={styles.text3}>
                  Stay up-to-date with the latest trends and adopt
                  industry-leading strategies.
                </div>
                <div className={styles.action3} />
              </div>
            </Jumpscare>
          </div>
          <div className={styles.listItem4}>
            <img
              className={styles.iconRelume4}
              alt=""
              src="/icon--relume.svg"
            />
            <b className={styles.heading5}>
              Thanks to mentoring programs, minority representation among
              employees in surveyed organizations increases by an average of
              24%.
            </b>
            <div className={styles.text4}>
              Stay up-to-date with the latest trends and adopt industry-leading
              strategies.
            </div>
            <div className={styles.action4}>
              <div className={styles.stylelinkSmallfalseDark2}>
                <div className={styles.button2}>Learn More</div>
                <img
                  className={styles.iconChevronRight2}
                  alt=""
                  src="/icon--chevronright.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
};

export default Layout;
