import { useMemo } from "react";
import styles from "./TypeCloudEMigration.module.css";

const TypeCloudEMigration = ({
  itCons,
  typeCloudEMigrationWidth,
  typeCloudEMigrationHeight,
  itConsIconWidth,
  itConsIconHeight,
  itConsIconAlignSelf,
  itConsIconOverflow,
}) => {
  const typeCloudEMigrationStyle = useMemo(() => {
    return {
      width: typeCloudEMigrationWidth,
      height: typeCloudEMigrationHeight,
    };
  }, [typeCloudEMigrationWidth, typeCloudEMigrationHeight]);

  const itConsIconStyle = useMemo(() => {
    return {
      width: itConsIconWidth,
      height: itConsIconHeight,
      alignSelf: itConsIconAlignSelf,
      overflow: itConsIconOverflow,
    };
  }, [
    itConsIconWidth,
    itConsIconHeight,
    itConsIconAlignSelf,
    itConsIconOverflow,
  ]);

  return (
    <div
      className={styles.typecloudEmigration}
      style={typeCloudEMigrationStyle}
    >
      <img
        className={styles.itConsIcon}
        alt=""
        src={itCons}
        style={itConsIconStyle}
      />
    </div>
  );
};

export default TypeCloudEMigration;
