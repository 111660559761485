import styles from "./DeliveringItSolutionsContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import Bubble from "../addons/Bubble";

const DeliveringItSolutionsContainer = () => {
  return (
    <section className={styles.deliveringItSolutions}>
      <div className={styles.content}>
        <div className={styles.content1}>
          <h2 className={styles.heading}>
            <Jumpscare>Delivering Perfect IT Solutions for Every Business</Jumpscare>
          </h2>
          <div className={styles.text}>
            <Jumpscare variant={'left'}>
              Our software development services provide enhanced efficiency and
              tailored support for businesses. We specialize in creating optimized
              solutions that meet the unique needs of each client. With our
              expertise in Agile (Scrum) principles and the SDLC framework, we
              ensure that our software development process follows industry
              standards and delivers top-quality results.
            </Jumpscare>
          </div>
        </div>
        <Jumpscare variant={'right'} threshold={0.25}>
          <div className={styles.contentInner}>
          <div className={styles.sdlcParent}>
            <div className={styles.sdlc}>
              <img
                className={styles.subtractIcon}
                alt=""
                src="/subtract@2x.png"
              />
              <Bubble>
                <div className={styles.analysis}>Analysis</div>
              </Bubble>
              <div className={styles.sdlcGroup}>
                <div className={styles.sdlc1}>
                  <Jumpscare variant={'right'} transitionDistance={100}>
                    SDLC
                  </Jumpscare>
                </div>
                <div className={styles.softwareDecelopmentLifeContainer}>
                  <p className={styles.softwareDecelopment}>
                    <Jumpscare variant={'right'} transitionDistance={100} delay={0.25}>
                      Software Development
                    </Jumpscare>
                  </p>
                  <p className={styles.lifeCycle}>
                    <Jumpscare variant={'right'} transitionDistance={100} delay={0.5}>
                      Life Cycle
                    </Jumpscare>
                  </p>
                </div>
              </div>
              <Bubble>
                <div className={styles.design}>Design</div>
              </Bubble>
              <Bubble>
                <div className={styles.testing}>Testing</div>
              </Bubble>
              <Bubble>
                <div className={styles.evolution}>Evolution</div>
              </Bubble>
              <Bubble>
                <div className={styles.implementation}>Implementation</div>
              </Bubble>
              <Bubble>
                <div className={styles.sdlcChild}/>
              </Bubble>
              <div className={styles.sdlcItem} />
              <img
                className={styles.sdlcInner}
                alt=""
                src="/group-11353@2x.png"
              />
            </div>
            <img className={styles.groupChild} alt="" src="/group-11611.svg" />
            <img className={styles.groupItem} alt="" src="/group-11610.svg" />
          </div>
        </div>
        </Jumpscare>
      </div>
    </section>
  );
};

export default DeliveringItSolutionsContainer;
