import styles from "./PopUpContact.module.css";
import StyleTertiarySmallTrueDa2 from "./StyleTertiarySmallTrueDa2";
import {Button, Checkbox, FormControlLabel, TextField, Tooltip} from "@mui/material";
import StylePrimarySmallFalseDa from "./StylePrimarySmallFalseDa";
import {useCallback, useMemo, useRef, useState} from "react";
import {ALERT_KINDS, useAlert} from "../addons/Alert";

const minNameLength = 4
const maxNameLength = 30
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
const allowedResumeExtensions = ['pdf', 'doc', 'docx']
const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/
const maxResumeSize = 2

const ApplyForm = ({
                     title = 'Apply For This Position',
                     description = 'Sofixit member',
                     onClose
                   }) => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [resume, setResume] = useState(null)
  const [resumeError, setResumeError] = useState('')
  const [linkedIn, setLinkedIn] = useState('')
  const [linkedInError, setLinkedInError] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [acceptedTermsError, setAcceptedTermsError] = useState('')

  const { resumeExtension, resumeSize } = useMemo(() => {
    return resume ? ({
      resumeExtension: resume.name.split('.').pop().toLowerCase(),
      resumeSize:      resume.size / (1024 * 1024)
    }) : ({
      resumeExtension: '',
      resumeSize:      0
    })
  }, [resume])

  const setErrors = useCallback(() => {
    setNameError(name.length < minNameLength || name.length > maxNameLength
      ? `Name should contain from ${minNameLength} to ${maxNameLength} characters`
      : ''
    )
    setEmailError(!emailRegex.test(email)
      ? 'Not an email'
      : ''
    )
    const resumeErrorParts = []
    if (!allowedResumeExtensions.includes(resumeExtension)) {
      resumeErrorParts.push(`Wrong file extension ${resumeExtension} (expected ${allowedResumeExtensions.join(', ')})`)
    }
    if (resumeSize > maxResumeSize) {
      resumeErrorParts.push(`Wrong file size ${~~(resumeSize * 100) / 100} MB (expected up to 2 MB)`)
    }
    setResumeError(resumeErrorParts.join('; '))
    setLinkedInError(linkedIn && !linkedInRegex.test(linkedIn)
      ? 'This is not a LinkedIn profile link'
      : ''
    )
    setAcceptedTermsError(!acceptedTerms
      ? 'Terms should be accepted'
      : ''
    )
  }, [
    name, setNameError,
    email, setEmailError,
    resume, resumeExtension, resumeSize, setResumeError,
    linkedIn, setLinkedInError,
    acceptedTerms, setAcceptedTermsError
  ])

  const isFormCompleted = useMemo(() => {
    if (![name, email, resume, acceptedTerms].every(Boolean)) {
      return false
    }
    if (linkedIn && !linkedInRegex.test(linkedIn)) {
      return false
    }
    if (name.length < minNameLength || name.length > maxNameLength || !emailRegex.test(email)) {
      return false
    }
    if (!allowedResumeExtensions.includes(resumeExtension) || resumeSize > maxResumeSize) {
      return false
    }
    return true
  }, [
    name, email, resume, resumeExtension,
    resumeSize, acceptedTerms, linkedIn
  ])

  const inputRef = useRef()
  const handleAttachResume = useCallback(() => {
    if (!inputRef.current) return
    inputRef.current.click()
  }, [inputRef.current])

  const handleFileInputChange = useCallback(event => {
    const file = event.target.files[0]
    setResume(file)
  }, [])

  const openAlert = useAlert()

  const handleSubmit = useCallback(() => {
    if (!isFormCompleted) {
      setErrors()
      return
    }
    const data = new FormData()
    data.append('name', name)
    data.append('email', email)
    const blob = new Blob([resume], { type: resume.type })
    data.append('resume', blob)
    if (linkedIn) {
      data.append('linkedIn', linkedIn)
    }
    // const url = 'http://localhost:3031/job-application'
    const url = '/job-application'
    fetch(url, {
      method: 'POST',
      body: data,
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.status.toString())
      }
      openAlert({
        title: 'Success!',
        message: 'Your application to our job was successfully sent. We will write you back!',
        kind: ALERT_KINDS.SUCCESS
      })
    }).catch(() => {
      openAlert({
        title: 'Failure',
        message: 'Something went wrong while sending your application. Try again or contact us directly',
        kind: ALERT_KINDS.ERROR
      })
    })
    onClose()
  }, [
    isFormCompleted,
    name, email, resume, linkedIn,
    onClose, setErrors
  ])

  return (
    <div className={styles.popUpContact}>
      <div className={styles.exit}>
        <StyleTertiarySmallTrueDa2
          iconRelume="/mingcutecloseline.svg"
          onClose={onClose}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.sectionTitleParent}>
            <div className={styles.sectionTitle}>
              <div className={styles.content1}>
                <b className={styles.heading}>
                  {title}
                </b>
                <div className={styles.text}>
                  {description}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.form}>
            <div className={styles.input}>
              <div className={styles.name}>Full name</div>
              <Tooltip
                title='Should contain at list 4 characters'
                enterTouchDelay={0}
              ><TextField
                value={name}
                onChange={event => setName(event.target.value)}
                className={styles.fname}
                color="primary"
                name="Name"
                label="Enter full name"
                required={true}
                variant="outlined"
                sx={{"& .MuiInputBase-root": {height: "58px"}}}
                error={Boolean(nameError)}
                helperText={nameError}
              /></Tooltip>
            </div>
            <div className={styles.input}>
              <div className={styles.name}>Email</div>
              <Tooltip
                title='Should be a valid email'
                enterTouchDelay={0}
              ><TextField
                value={email}
                onChange={event => setEmail(event.target.value)}
                type={'email'}
                className={styles.fname}
                color="primary"
                label="Enter email"
                required={true}
                variant="outlined"
                sx={{"& .MuiInputBase-root": {height: "58px"}}}
                error={Boolean(emailError)}
                helperText={emailError}
              /></Tooltip>
            </div>
            <div className={styles.input}>
              <div className={styles.name}>Resume</div>
              <input ref={inputRef}
                     onChange={handleFileInputChange}
                     type='file'
                     hidden
              />
              <div className={styles.fileInputContainer}>
                <Button
                  size='medium'
                  variant='outlined'
                  onClick={handleAttachResume}
                >Upload</Button>
                {resumeError ? (
                  <div className={styles.fileInputLabelError}>
                    { resumeError }
                  </div>
                ) : (
                  <div className={styles.fileInputLabel}>
                    {resume
                      ? `${resume.name}, ${~~(resume.size / 1024 ** 2 * 100) / 100} MB`
                      : 'No selected file'
                    }
                  </div>
                )}
              </div>
              <div className={styles.fileFormLabel}>
                PDF, DOC, DOCX (2 MB)
              </div>
            </div>
            <div className={styles.input}>
              <div className={styles.optionalContainer}>
                <div className={styles.name}>LinkedIn Profile URL</div>
                <div className={styles.optionalLabel}>Optional</div>
              </div>
              <Tooltip
                title='Should be a valid LinkedIn link (optional field)'
                enterTouchDelay={0}
              ><TextField
                value={linkedIn}
                onChange={event => setLinkedIn(event.target.value)}
                type='url'
                className={styles.fname}
                color="primary"
                label="Enter LinkedIn Profile URL"
                variant="outlined"
                sx={{"& .MuiInputBase-root": {height: "58px"}}}
                error={Boolean(linkedInError)}
                helperText={linkedInError}
              /></Tooltip>
            </div>
            <div className={styles.checkbox} style={{alignItems: 'flex-start'}}>
              <div className={styles.container1}>
                <FormControlLabel
                  className={styles.rectangleFormcontrollabel}
                  label=""
                  control={<Checkbox
                    color="primary"
                    value={acceptedTerms}
                    onClick={() => setAcceptedTerms(prev => !prev)}
                  />}
                />
              </div>
              <div className={styles.container2}>
                <div className={styles.label}>
                  By sending the job application you agree
                  to processing of your personal data included
                  in the job application by the Employer/Data
                  Administrator for the purpose of recruitment
                  on the position indicated in the advertisement.
                  <br/>
                  <span style={{ color: '#d32f2f' }}>
                    {acceptedTermsError}
                  </span>
                </div>
              </div>
            </div>
            <StylePrimarySmallFalseDa
              onClick={handleSubmit}
              // disabled={!isFormCompleted}
              ready={isFormCompleted}
              button="Send"
              stylePrimarySmallFalseDaAlignSelf="unset"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyForm
