import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import StylePrimarySmallTrueDar from "../components/StylePrimarySmallTrueDar";
import JobDescriptionContainer from "../components/JobDescriptionContainer";
import Footer from "../components/Footer";
import styles from "./CareerGraphicDesignerWeb.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";

const CareerGraphicDesignerWeb = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  const openPopup = useSetPopupState()

  return (
    <div className={styles.CareerGraphicDesignerWeb}>
      <Navbar
        frame11965="/frame-11965@2x.png"
        onLogoClick={onLogoClick}
      />
      <div className={styles.header}>
        <div className={styles.sectionTitle}>
          <div className={styles.subheading}>
            <Jumpscare>Join</Jumpscare>
          </div>
          <div className={styles.content}>
            <b className={styles.businessAnalyst}>
              <Jumpscare delay={0.25}>Graphic Designer</Jumpscare>
            </b>
            <div className={styles.text}>
              <p className={styles.salary15k20kPln}>
                <Jumpscare delay={0.5}>Salary: 40 PLN / hour</Jumpscare>
              </p>
              <p className={styles.salary15k20kPln}>
                <Jumpscare delay={0.75}>Fully Remote</Jumpscare>
              </p>
            </div>
          </div>
          <Jumpscare delay={1}><StylePrimarySmallTrueDar
            onClick={() => openPopup ({
              title: "Graphic Designer",
              description: "Contact us and apply for this hot position right now!",
              variant: "application"
            })}
            button="Apply"
            stylePrimarySmallTrueDarPosition="unset"
            stylePrimarySmallTrueDarTop="unset"
            stylePrimarySmallTrueDarRight="unset"
            stylePrimarySmallTrueDarWidth="unset"
            buttonDisplay="inline-block"
          /></Jumpscare>
        </div>
      </div>
      <JobDescriptionContainer
        jobDescription="Job Description"
        businessAnalystRequiremen="We are hiring a remote graphic designer for 10 hours per week specializing in creating innovative tech solutions for global clients work includes developing sales materials social media graphics collaborating with marketing and developers ensuring brand consistency staying updated with design trends potential UX/UI support proficiency in Photoshop and Illustrator required creativity and openness to learning understanding of design and branding principles good organization and teamwork skills required portfolio and English proficiency needed knowledge of UI/UX design and Figma a plus we offer a dynamic supportive team professional development flexible hours and attractive salary send your CV and portfolio and join us to create exceptional projects together."
        productDescription="/innovation@2x.png"
        propTextAlign="left"
      />
      <Footer
        frame11965="/frame-119655@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default CareerGraphicDesignerWeb;
