import TechnologyContainer from "./TechnologyContainer";
import ContainerTestimonial from "./ContainerTestimonial";
import styles from "./TechnologyExperienceContainer.module.css";
import ContainerCompanies from "./ContainerCompanies";

const TechnologyExperienceContainer = () => {
  return (
    <section className={styles.experience}>
      <TechnologyContainer
        propBackgroundColor="#fefefe"
        propAlignSelf="stretch"
      />
      <ContainerCompanies />
      <ContainerTestimonial />
    </section>
  );
};

export default TechnologyExperienceContainer;
