// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Property_property11Icon__4qmRu {
  width: 181.5px;
  max-width: 100%;
  overflow: hidden;
  height: 181.5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Property.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".property11Icon {\n  width: 181.5px;\n  max-width: 100%;\n  overflow: hidden;\n  height: 181.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"property11Icon": `Property_property11Icon__4qmRu`
};
export default ___CSS_LOADER_EXPORT___;
