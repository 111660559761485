// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TechnologyExperienceContainer_experience__CIAES {
  align-self: stretch;
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 48px;
  color: #1c1c1c;
  font-family: Roboto;
}
`, "",{"version":3,"sources":["webpack://./src/components/TechnologyExperienceContainer.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".experience {\n  align-self: stretch;\n  background-color: #fefefe;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  text-align: center;\n  font-size: 48px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experience": `TechnologyExperienceContainer_experience__CIAES`
};
export default ___CSS_LOADER_EXPORT___;
