import { useState, useCallback } from "react";
import PopUpContact from "../components/PopUpContact";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import FormContainer from "../components/FormContainer";
import JobDescriptionContainer from "../components/JobDescriptionContainer";
import Footer from "../components/Footer";
import styles from "./CareerWeb.module.css";

const CareerWeb = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  return (
    <div className={styles.careerWeb}>
      <Navbar
        frame11965="/frame-119653@2x.png"
        onLogoClick={onLogoClick}
      />
      <FormContainer />
      <JobDescriptionContainer
        jobDescription="Why We Think You Should Join Us"
        businessAnalystRequiremen="We know that starting in a new place can be challenging. Let us give you a few reasons why you should consider being part of our team."
        productDescription="/innovation1@2x.png"
        propTextAlign="center"
      />
      <Footer
        frame11965="/frame-119652@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default CareerWeb;
