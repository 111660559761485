import {useMemo} from "react";
import styles from "./JobDescriptionContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";

const JobDescriptionContainer = ({
                                   jobDescription,
                                   businessAnalystRequiremen,
                                   productDescription,
                                   propTextAlign,
                                 }) => {
  const text3Style = useMemo(() => {
    return {
      textAlign: propTextAlign,
    };
  }, [propTextAlign]);

  const mediaQuery620 = useMediaQuery('(max-width: 618px)')

  return (
    <div className={styles.description}>
      <div className={styles.sectionTitle}>
        <div className={styles.subheading}>Efficient</div>
        <div className={styles.content}>
          <b className={styles.heading}>
            <Jumpscare>{jobDescription}</Jumpscare>
          </b>
          <div className={styles.text} style={text3Style}>
            <Jumpscare delay={0.5} threshold={0.1}>
              {businessAnalystRequiremen}
            </Jumpscare>
          </div>
        </div>
      </div>
      <div className={styles.content1}>
        <div className={styles.row}>
          <Jumpscare>
            <div className={styles.quality}>
              <img className={styles.qualityIcon} alt="" src="/quality.svg"/>
              <div className={styles.development}>Quality</div>
            </div>
          </Jumpscare>

          <Jumpscare delay={mediaQuery620 ? 0 : 0.25}>
            <div className={styles.quality}>
              <img
                className={styles.innovationIcon}
                alt=""
                src={productDescription}
              />
              <div className={styles.development}>Innovation</div>
            </div>
          </Jumpscare>

          <Jumpscare delay={mediaQuery620 ? 0 : 0.5}>
            <div className={styles.quality}>
              <img className={styles.qualityIcon} alt="" src="/quality.svg"/>
              <div className={styles.development}>Trust</div>
            </div>
          </Jumpscare>

          <Jumpscare delay={mediaQuery620 ? 0 : 0.75}>
            <div className={styles.quality}>
              <img className={styles.qualityIcon} alt="" src="/fairness.svg"/>
              <div className={styles.development}>Fairness</div>
            </div>
          </Jumpscare>

          <Jumpscare delay={mediaQuery620 ? 0 : 1}>
            <div className={styles.quality}>
              <img className={styles.qualityIcon} alt="" src="/dev.svg"/>
              <div className={styles.development}>Development</div>
            </div>
          </Jumpscare>
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionContainer;
