// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StyleTertiarySmallTrueDa2_iconRelume__hXgG2 {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.StyleTertiarySmallTrueDa2_styletertiarySmalltrueDa__biiJ1 {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.StyleTertiarySmallTrueDa2_styletertiarySmalltrueDa__biiJ1:hover {
  background-color: rgba(229, 229, 229, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/StyleTertiarySmallTrueDa2.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,0CAA0C;AAC5C","sourcesContent":[".iconRelume {\n  width: 24px;\n  position: relative;\n  height: 24px;\n  overflow: hidden;\n  flex-shrink: 0;\n}\n.styletertiarySmalltrueDa {\n  border-radius: 4px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 8px;\n}\n.styletertiarySmalltrueDa:hover {\n  background-color: rgba(229, 229, 229, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconRelume": `StyleTertiarySmallTrueDa2_iconRelume__hXgG2`,
	"styletertiarySmalltrueDa": `StyleTertiarySmallTrueDa2_styletertiarySmalltrueDa__biiJ1`
};
export default ___CSS_LOADER_EXPORT___;
