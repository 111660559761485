import styled from 'styled-components'
import {useState, useCallback, useRef, useEffect} from 'react'
import styles from "../components/CloudEnablementContainer.module.css";
import Jumpscare from "./Jumpscare";

const FAQ = ({ question, answer }) => {
  const [open, setOpen] = useState(false)
  const openAnswer = useCallback(() => {
    setOpen(true)
  }, [])
  const closeAnswer = useCallback(() => {
    setOpen(false)
  }, [])

  const [enteredFlag, setEnteredFlag] = useState(false)
  const handleMouseEnter = useCallback(() => {
    if (enteredFlag) return
    openAnswer()
    setEnteredFlag(true)
  }, [enteredFlag])

  return (
    <Root onMouseEnter={handleMouseEnter}>
      <Jumpscare threshold={0.9} variant={'left'}>
        <Title>
          <Question>{question}</Question>
          <Indicator $open={open} onClick={open ? closeAnswer : openAnswer}>
            <img
              className={styles.icon}
              alt="Arrow"
              src="/icon1.svg"
            />
          </Indicator>
        </Title>
        <Answer $open={open}>
          <p>{answer}</p>
        </Answer>
      </Jumpscare>
    </Root>
  )
}

export default FAQ

const Answer = styled.div`
  box-sizing: border-box;
  p {
    padding: 0; margin: 0;
    margin-bottom: 40px;
    
    border-left: 7px solid #ccc;
    padding: 8px 12px;
    margin-left: 12px;
    max-width: 720px;
  }
  max-height: 0;
  overflow: hidden;
  transition: 0.5s max-height cubic-bezier(0, 1, 0, 1);
  ${({ $open }) => $open && `
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  `}
`

const Indicator = styled.span`
  width: 32px; height: 32px;
  transform: rotate(90deg);
  transition: 0.2s transform ease-in-out;
  ${({ $open }) => $open && `
    transform: rotate(0);
  `}
`

const Question = styled.b`
  flex: 1;
  line-height: 150%;
  background: linear-gradient(#1c1c1c, #1c1c1c), #000;
  -webkit-background-clip: text;
  margin: 0; padding: 0;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`

const Root = styled.div`
  width: 100%;
  cursor: pointer;
`