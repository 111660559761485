import TypeCloudEMigration from "./TypeCloudEMigration";
import StylePrimarySmallTrueDar from "./StylePrimarySmallTrueDar";
import styles from "./ContainerHeaderForm.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";

const ContainerHeaderForm = () => {
  const openPopup = useSetPopupState()

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content1}>
            <div className={styles.content2}>
              <b className={styles.heading}>
                <Jumpscare transitionTime={1}>
                  <span>{`Information `}</span>
                  <span
                    className={styles.technologyConsulting}
                  >{`Technology Consulting `}</span>
                  <span>Services: Maximizing Your Business Potential</span>
                </Jumpscare>
              </b>
              <div className={styles.leverageOurExpertise}>
                <Jumpscare delay={0.25}>
                  Leverage our expertise for strategic insights and optimized IT
                  solutions.
                </Jumpscare>
              </div>
            </div>
            <div className={styles.servicesWrapper}>
              <Jumpscare delay={0.5}>
                <TypeCloudEMigration
                  itCons="/group-11604.svg"
                  typeCloudEMigrationWidth="87px"
                  typeCloudEMigrationHeight="80.1px"
                  itConsIconWidth="87px"
                  itConsIconHeight="75px"
                  itConsIconAlignSelf="unset"
                  itConsIconOverflow="unset"
                />
              </Jumpscare>
            </div>
            <div className={styles.button}>
              <Jumpscare delay={0.75}>
                <StylePrimarySmallTrueDar
                  onClick={() => openPopup ({
                    title: "Get a free quote",
                    description: "Contact us right now!"
                  })}
                  button="Get a free quote"
                  stylePrimarySmallTrueDarPosition="absolute"
                  stylePrimarySmallTrueDarTop="-1px"
                  stylePrimarySmallTrueDarRight="0px"
                  stylePrimarySmallTrueDarWidth="unset"
                  buttonDisplay="inline-block"
                />
              </Jumpscare>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerHeaderForm;
