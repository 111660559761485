import styled, {css} from 'styled-components'

const Flipcard = ({
  front, back,
  width, height,
  perspective = 1000,
  transitionTime = 0.8
}) => {
  return (
    <Root
      $perspective={perspective}
      $width={width}
      $height={height}
    >
      <Inner $transitionTime={transitionTime}>
        <Side>{front}</Side>
        <Side $inverted={true}>{back }</Side>
      </Inner>
    </Root>
  )
}

export default Flipcard

const Side = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  ${({ $inverted }) => $inverted && css`
    transform: rotateY(180deg);
  `};
`

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform ${({ $transitionTime }) => $transitionTime}s;
  transform-style: preserve-3d;
`

const Root = styled.div`
  perspective: ${({ $perspective }) => $perspective}px;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  &:hover > * {
    transform: rotateY(180deg);
  }
  cursor: pointer;
`