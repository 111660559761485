import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import StateDefault from "./StateDefault";
import styles from "./ITProductDevelopmentContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";
import StartProjectContainer from "./StartProjectContainer";

const cards = [
  {
    icon: "/DevOpsIcon.svg",
    heading: "DevOps, Kubernetes Services",
    text: "Accelerate innovation with our DevOps and Kubernetes expertise, enhancing software delivery and scalability.",
    fullDescription: "Boost your development workflow by streamlining processes, optimizing resource utilization, and fostering a collaborative environment that empowers your team to iterate rapidly and deliver high-quality software solutions.",
    delay: 0
  },
  {
    icon: "/BigDataIcon.svg",
    heading: "Big Data and AI/ML Services",
    text: "Transform data into insights and intelligence with our advanced Big Data and AI/ML solutions.",
    fullDescription: "Leverage cutting-edge technologies and analytics to unlock the full potential of your data, gaining actionable insights and strategic intelligence that drive informed decision-making. Our robust Big Data and AI/ML solutions are tailored to meet your unique business needs, ensuring a seamless integration that propels you towards data-driven success.",
    delay: 0.5
  },
  {
    icon: "/DataStrategyIcon.svg",
    heading: "Data Strategy & Data Management",
    text: "Craft and execute a powerful data strategy, ensuring your data's integrity, security, and value.",
    fullDescription: "Forge a robust data strategy that prioritizes integrity, security, and value. Our proven practices in governance, encryption, and monitoring ensure your data assets remain safeguarded while delivering optimal business insights.",
    delay: 1.0
  },
  {
    icon: "/CloudNativeIcon.svg",
    heading: "Cloud-Native Transformation",
    text: "Embrace cloud-native agility to modernize applications and optimize your IT ecosystem efficiently.",
    fullDescription: "Seamlessly migrate to cloud-native architecture, unlocking unparalleled agility that empowers the modernization of applications and optimizes your IT ecosystem for enhanced efficiency and scalability.",
    delay: 1.5
  },
  {
    heading: "Custom Software Development",
    text: "We develop cutting-edge software solutions.",
    fullDescription: "Our dedicated team harnesses the latest technologies to engineer cutting-edge software solutions that not only meet but exceed industry standards, ensuring innovation, reliability, and seamless user experiences.",
    delay: 0
  },
];

const ITProductDevelopmentContainer = () => {
  const navigate = useNavigate();
  const openPage = useCallback(page => {
    navigate(page)
  }, [navigate])

  const lessThan1439px = useMediaQuery('(max-width: 1439px)')

  return (
    <div className={styles.container}>
      <div className={styles.container1}>
        <div className={styles.row}>
          { cards.map((cardInfo, index) => (
            <Jumpscare
              key={index}
              threshold={0.3}
              delay={lessThan1439px ? 0 : cardInfo.delay}
            >
              <StateDefault
                {...cardInfo}
                onCardClick={() => openPage(cardInfo.navigationRoute)}
              />
            </Jumpscare>
          )) }
          <StartProjectContainer />
        </div>
      </div>
    </div>
  );
};

export default ITProductDevelopmentContainer;
