import {
  TextField,
  Checkbox,
  FormControlLabel, Tooltip,
} from "@mui/material";
import {useCallback, useMemo, useState} from "react";
import StyleTertiarySmallTrueDa2 from "./StyleTertiarySmallTrueDa2";
import StylePrimarySmallFalseDa from "./StylePrimarySmallFalseDa";
import styles from "./PopUpContact.module.css";
import {ALERT_KINDS, useAlert} from "../addons/Alert";

const minNameLength = 4
const maxNameLength = 30
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
const minMessageLength = 10
const maxMessageLength = 2000

const PopUpContact = ({
  title = 'Contact us',
  description = 'We\'re here for you! Reach out today for assistance and support. Let\'s make things better together!',
  onClose
}) => {
  const [name, setName]           = useState('')
  const [nameError, setNameError] = useState('')

  const [email, setEmail]           = useState('')
  const [emailError, setEmailError] = useState('')

  const [message, setMessage]           = useState('')
  const [messageError, setMessageError] = useState('')

  const [accept, setAccept]           = useState(false)
  const [acceptError, setAcceptError] = useState('')

  const setErrors = useCallback(() => {
    setNameError(name.length < minNameLength || name.length > maxNameLength
      ? `Name should contain from ${minNameLength} to ${maxNameLength} characters`
      : ''
    )
    setEmailError(!emailRegex.test(email)
      ? 'Not an email'
      : ''
    )
    setMessageError(message.length < minMessageLength || message.length > maxMessageLength
      ? `Message should contain from ${minMessageLength} to ${maxMessageLength} characters, ${message.length} were found`
      : ''
    )
    setAcceptError(!accept
      ? 'Terms should be accepted'
      : ''
    )
  }, [
    name,    setNameError,
    email,   setEmailError,
    message, setMessageError,
    accept,  setAcceptError
  ])

  const isFormCompleted = useMemo(() => {
    if (!accept) return false
    if (name.length < minNameLength || name.length > maxNameLength) return false
    if (message.length > maxMessageLength || message.length < minMessageLength) return false
    return emailRegex.test(email)
  }, [name, email, message, accept])

  const openAlert = useAlert()

  const submitHandler = useCallback(() => {
    if (!isFormCompleted) {
      setErrors()
      return
    }
    const body = JSON.stringify({ name, email, message })
    // const url = "http://localhost:3031/contact-us-general"
    const url = "/contact-us-general"
    fetch(url, {
      method: 'POST',
      body,
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.status.toString())
      }
      openAlert({
        title: 'Success!',
        message: 'Email was successfully sent! We will write you back as soon as possible!',
        kind: ALERT_KINDS.SUCCESS
      })
    }).catch(() => {
      openAlert({
        title: 'Failure',
        message: 'Something went wrong while sending your email. Try again or contact us directly',
        kind: ALERT_KINDS.ERROR
      })
    })
    onClose()
  }, [setErrors, isFormCompleted, onClose])

  return (
    <div className={styles.popUpContact}>
      <div className={styles.exit}>
        <StyleTertiarySmallTrueDa2
          iconRelume="/mingcutecloseline.svg"
          onClose={onClose}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.sectionTitleParent}>
            <div className={styles.sectionTitle}>
              <div className={styles.subheading}>Tagline</div>
              <div className={styles.content1}>
                <b className={styles.heading}>{title}</b>
                <div className={styles.text}>{description}</div>
              </div>
            </div>
            <img className={styles.mesageIcon} alt="" src="/mesage.svg" />
          </div>
          <div className={styles.form}>
            <div className={styles.input}>
              <div className={styles.name}>Name</div>
              <Tooltip
                title='Should contain at list 4 characters'
                enterTouchDelay={0}
              ><TextField
                value={name}
                onChange={event => setName(event.target.value)}
                className={styles.fname}
                color="primary"
                name="Name"
                label="Enter name"
                required={true}
                variant="outlined"
                sx={{"& .MuiInputBase-root": {height: "58px"}}}
                error={Boolean(nameError)}
                helperText={nameError}
              /></Tooltip>
            </div>
            <div className={styles.input}>
              <div className={styles.name}>Email</div>
              <Tooltip
                title='Should be a valid email'
                enterTouchDelay={0}
              ><TextField
                value={email}
                onChange={event => setEmail(event.target.value)}
                type={'email'}
                className={styles.fname}
                color="primary"
                label="Enter email"
                required={true}
                variant="outlined"
                sx={{"& .MuiInputBase-root": {height: "58px"}}}
                error={Boolean(emailError)}
                helperText={emailError}
              /></Tooltip>
            </div>
            <div className={styles.input}>
              <div className={styles.name}>Message</div>
              <Tooltip
                title='Should contain at least 11 characters'
                enterTouchDelay={0}
              ><TextField
                value={message}
                onChange={event => setMessage(event.target.value)}
                className={styles.fname2}
                color="primary"
                rows={6}
                label="Enter message"
                required={true}
                variant="outlined"
                multiline
                error={Boolean(messageError)}
                helperText={messageError}
              /></Tooltip>
            </div>
            <div className={styles.checkbox}>
              <div className={styles.container1}>
                <FormControlLabel
                  className={styles.rectangleFormcontrollabel}
                  label=""
                  control={<Checkbox color="primary" value={accept} onClick={() => setAccept(prev => !prev)}/>}
                />
              </div>
              <div className={styles.container2}>
                <div className={styles.label}>
                  I accept the Terms
                  <br/>
                  <span style={{ color: '#d32f2f' }}>
                    {acceptError}
                  </span>
                </div>
              </div>
            </div>
            <StylePrimarySmallFalseDa
              onClick={submitHandler}
              // disabled={!completed}
              button="Send"
              stylePrimarySmallFalseDaAlignSelf="unset"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpContact;
