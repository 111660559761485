// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EngagementContainer_heading__roeZ4,
.EngagementContainer_text__Sm2t2 {
  align-self: stretch;
  position: relative;
  line-height: 140%;
}
.EngagementContainer_text__Sm2t2 {
  font-size: 16px;
  line-height: 150%;
}
.EngagementContainer_column__XFkdJ,
.EngagementContainer_content__ysCyt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.EngagementContainer_content__ysCyt {
  align-self: stretch;
  align-items: center;
  gap: 24px;
}
.EngagementContainer_column__XFkdJ {
  flex: 1 1;
  overflow: hidden;
  align-items: flex-start;
  min-width: 300px;
  max-width: 405px;
  text-align: center;
  font-size: 24px;
  color: #1c1c1c;
  font-family: Roboto;
}
`, "",{"version":3,"sources":["webpack://./src/components/EngagementContainer.module.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;;EAEE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,SAAO;EACP,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".heading,\n.text {\n  align-self: stretch;\n  position: relative;\n  line-height: 140%;\n}\n.text {\n  font-size: 16px;\n  line-height: 150%;\n}\n.column,\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.content {\n  align-self: stretch;\n  align-items: center;\n  gap: 24px;\n}\n.column {\n  flex: 1;\n  overflow: hidden;\n  align-items: flex-start;\n  min-width: 300px;\n  max-width: 405px;\n  text-align: center;\n  font-size: 24px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `EngagementContainer_heading__roeZ4`,
	"text": `EngagementContainer_text__Sm2t2`,
	"column": `EngagementContainer_column__XFkdJ`,
	"content": `EngagementContainer_content__ysCyt`
};
export default ___CSS_LOADER_EXPORT___;
