import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import StylePrimarySmallTrueDar from "../components/StylePrimarySmallTrueDar";
import JobDescriptionContainer from "../components/JobDescriptionContainer";
import Footer from "../components/Footer";
import styles from "./CareerBusinessAnalystWeb.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";

const CareerBusinessAnalystWeb = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  const openPopup = useSetPopupState()

  return (
    <div className={styles.careerBusinessAnalystWeb}>
      <Navbar
        frame11965="/frame-11965@2x.png"
        onLogoClick={onLogoClick}
      />
      <div className={styles.header}>
        <div className={styles.sectionTitle}>
          <div className={styles.subheading}>
            <Jumpscare>Join</Jumpscare>
          </div>
          <div className={styles.content}>
            <b className={styles.businessAnalyst}>
              <Jumpscare delay={0.25}>Business Analyst</Jumpscare>
            </b>
            <div className={styles.text}>
              <p className={styles.salary15k20kPln}>
                <Jumpscare delay={0.5}>Salary: 15k-20k PLN / month</Jumpscare>
              </p>
              <p className={styles.salary15k20kPln}>
                <Jumpscare delay={0.75}>Partly Remote</Jumpscare>
              </p>
            </div>
          </div>
          <Jumpscare delay={1}><StylePrimarySmallTrueDar
            onClick={() => openPopup ({
              title: "Business Analyst",
              description: "Contact us and apply for this hot position right now!",
              variant: "application"
            })}
            button="Apply"
            stylePrimarySmallTrueDarPosition="unset"
            stylePrimarySmallTrueDarTop="unset"
            stylePrimarySmallTrueDarRight="unset"
            stylePrimarySmallTrueDarWidth="unset"
            buttonDisplay="inline-block"
          /></Jumpscare>
        </div>
      </div>
      <JobDescriptionContainer
        jobDescription="Job Description"
        businessAnalystRequiremen="Sofixit is looking for an experienced Business Analyst who will join the product team and help drive requirements for the world of Big Data. We are looking for a technical background and knowledge of BI solutions to drive requirements towards our Big Data set of tools and web applications. In this role, you will be responsible for: Writing Use Cases for Web-based platform. Analyzing Advanced SQL queries to define edge cases for Big Data solutions Running brainstorming sessions with the Product team Working on mock-ups for non-standard functionalities Explaining use cases and providing help to the Test team Maintain product documentation"
        productDescription="/innovation@2x.png"
        propTextAlign="left"
      />
      <Footer
        frame11965="/frame-119655@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default CareerBusinessAnalystWeb;
