import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import OutsourceForm from "../components/OutsourceForm";
import EngagementContainer from "../components/EngagementContainer";
import ExpertFilterContainer from "../components/ExpertFilterContainer";
import StyleSecondarySmallFalse1 from "../components/StyleSecondarySmallFalse1";
import ContainerTestimonial from "../components/ContainerTestimonial";
import FAQContainer from "../components/FAQContainer";
import Footer from "../components/Footer";
import styles from "./ServicesIOutsourcingWeb.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";
import {useSetPopupState} from "../addons/ContactPopup";

const faqs = [
  {
    question: 'What is IT Outsourcing?',
    answer: 'IT Outsourcing involves hiring external companies or individuals to handle specific IT functions or tasks that are typically performed in-house. This can include services such as software development, technical support, or infrastructure management. Outsourcing allows businesses to focus on their core activities while benefiting from the expertise and cost-effectiveness of external IT professionals.'
  },
  {
    question: 'Why should I consider IT Outsourcing?',
    answer: 'IT Outsourcing offers cost savings by leveraging external expertise, allowing your business to access specialized skills without the overhead of maintaining an in-house team. It enables focus on core business activities, provides scalability, taps into a global talent pool, and transfers certain risks to external providers. Ultimately, it\'s a strategic approach for efficiency, flexibility, and staying competitive in the dynamic IT landscape.'
  },
  {
    question: 'How does IT Outsourcing work?',
    answer: 'IT Outsourcing involves hiring external experts or companies to handle specific IT tasks. After identifying needs, you select a vendor, negotiate terms, and transition tasks. Ongoing communication, performance monitoring, and flexibility are key for a successful outsourcing relationship.'
  },
  {
    question: 'How do I get started with IT Outsourcing?',
    answer: 'To get started with IT Outsourcing, consider Sofixit as your solution. Begin by exploring the services we offer. Contact us to discuss your specific needs and goals. Our team will guide you through the onboarding process, ensuring a tailored outsourcing solution that aligns with your business objectives.'
  }
]

const ServicesIOutsourcingWeb = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  const openPopup = useSetPopupState()

  const mediaQuery1440 = useMediaQuery('(max-width: 1438px)')

  return (
    <>
      <div className={styles.servicesiOutsourcingWeb}>
        <Navbar
          frame11965="/frame-11965@2x.png"
          onLogoClick={onLogoClick}
        />
        <OutsourceForm />
        <div className={styles.streamlined}>
          <div className={styles.sectionTitle}>
            <div className={styles.subheading}>Efficient</div>
            <div className={styles.content}>
              <b className={styles.heading}>
                <Jumpscare>
                  Streamlined IT Outsourcing Process for Optimal Results
                </Jumpscare>
              </b>
              <div className={styles.text}>
                <Jumpscare delay={0.5}>
                  Our IT Outsourcing process is designed to ensure seamless client
                  engagement, efficient project setup, and effective ongoing
                  management. We follow Agile practices to deliver optimal
                  results.
                </Jumpscare>
              </div>
            </div>
          </div>
          <div className={styles.content1}>
            <div className={styles.row}>
              <Jumpscare><EngagementContainer
                serviceDescription="Client Engagement"
                clientEngagementDescripti="We collaborate closely with our clients to understand their specific IT outsourcing needs and goals."
              /></Jumpscare>
              <Jumpscare delay={mediaQuery1440 ? 0 : 0.5}><EngagementContainer
                serviceDescription="Project Setup"
                clientEngagementDescripti="We ensure smooth project setup, including resource allocation and timeline planning."
              /></Jumpscare>
              <Jumpscare delay={mediaQuery1440 ? 0 : 1}><EngagementContainer
                serviceDescription="Ongoing Management"
                clientEngagementDescripti="We provide continuous support and management throughout the IT outsourcing process."
              /></Jumpscare>
            </div>
          </div>
        </div>
        <ExpertFilterContainer />
        <Jumpscare stretch transitionTime={1} variant={'left'}>
          <section className={styles.cta}>
            <div className={styles.column}>
              <div className={styles.content2}>
                <h2 className={styles.heading1}>
                  <Jumpscare>Custom IT Outsourcing Solutions</Jumpscare>
                </h2>
                <div className={styles.text1}>
                  <Jumpscare delay={0.25}>Contact us today to discuss your IT Outsourcing needs and
                    receive custom solutions.</Jumpscare>
                </div>
              </div>
              <div className={styles.actions} onClick={() => openPopup ({
                title: "Thinking of outsourcing?",
                description: "Find answers to common questions about IT Outsourcing and how to get started"
              })}>
                <Jumpscare delay={0.5}><StyleSecondarySmallFalse1
                  button="Contact Us"
                  styleSecondarySmallFalseBackgroundColor="unset"
                  buttonDisplay="inline-block"
                /></Jumpscare>
              </div>
            </div>
          </section>
        </Jumpscare>
        <ContainerTestimonial
          imageDimensions="/avatar-image1@2x.png"
          propMinWidth="300px"
        />
        <FAQContainer
          faqText="Find answers to common questions about IT Outsourcing and how to get started."
          faqs={faqs}
        />
        <Footer
          frame11965="/frame-119657@2x.png"
          onLogoClick={onLogo1Click}
          onIconLinkedInClick={onIconLinkedInClick}
        />
      </div>
    </>
  );
};

export default ServicesIOutsourcingWeb;
