import styles from "./AgileBestPracticesContainer.module.css";

const AgileBestPracticesContainer = ({
  titleText,
  collaborationDescription,
}) => {
  return (
    <div className={styles.column}>
      <div className={styles.content}>
        <b className={styles.heading}>{titleText}</b>
        <div className={styles.text}>{collaborationDescription}</div>
      </div>
    </div>
  );
};

export default AgileBestPracticesContainer;
