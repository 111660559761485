import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import SeamlessCloudSolutionsContaine from "../components/SeamlessCloudSolutionsContaine";
import StartProjectContainer1 from "../components/StartProjectContainer1";
import CloudMigrationContainer from "../components/CloudMigrationContainer";
import CloudMigrationProcessContainer from "../components/CloudMigrationProcessContainer";
import CloudEnablementContainer from "../components/CloudEnablementContainer";
import Footer from "../components/Footer";
import styles from "./ServicesCloudMigrationEnable.module.css";

const ServicesCloudMigrationEnable = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  return (
    <div className={styles.servicescloudMigrationEnable}>
      <Navbar
        frame11965="/frame-119653@2x.png"
        onLogoClick={onLogoClick}
      />
      <SeamlessCloudSolutionsContaine />
      <StartProjectContainer1 />
      <CloudMigrationContainer />
      <CloudMigrationProcessContainer />
      <CloudEnablementContainer />
      <Footer
        frame11965="/frame-119656@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default ServicesCloudMigrationEnable;
