import { useMemo } from "react";
import styles from "./StyleSecondarySmallFalse1.module.css";

const StyleSecondarySmallFalse1 = ({
  button,
  styleSecondarySmallFalseBackgroundColor,
  buttonDisplay,
  onClick = (() => {})
}) => {
  const styleSecondarySmallFalse1Style = useMemo(() => {
    return {
      backgroundColor: styleSecondarySmallFalseBackgroundColor,
    };
  }, [styleSecondarySmallFalseBackgroundColor]);

  const button1Style = useMemo(() => {
    return {
      display: buttonDisplay,
    };
  }, [buttonDisplay]);

  return (
    <div
      className={styles.stylesecondarySmallfalse}
      style={styleSecondarySmallFalse1Style}
      onClick={onClick}
    >
      <div className={styles.button} style={button1Style}>
        {button}
      </div>
    </div>
  );
};

export default StyleSecondarySmallFalse1;
