import styled from 'styled-components'
import {useMemo, useState} from "react";
import StyleSecondarySmallFalse2 from "./StyleSecondarySmallFalse2";
import styles from "./ContainerTestimonial.module.css";
import useSlides from "../addons/slides";
import Slideshow from "../addons/Slideshow";
import {useMediaQuery} from "@mui/material";
import Jumpscare from "../addons/Jumpscare";

const partners = [
  {
    name: 'Piotr Ostrowski',
    avatar: '/avatar-image@2x.png',
    position: 'Client Solution Director',
    additionalInfo: 'GFT',
    quote: 'They truly are professionals in their field and the work they deliver is top-notch'
  },
  {
    name: 'Mateusz Żelazny',
    avatar: '/harmony-icon.png',
    position: 'CEO',
    additionalInfo: 'Harmony',
    quote: 'We are very satisfied with the collaboration with Sofixit. Their team delivered high quality and good value product within agreed time frame'
  },
  {
    name: 'Kamil Raróg',
    avatar: '/testimonial-cardinality.jpg',
    position: 'Head of Engineering',
    additionalInfo: 'Elisa Polystar',
    quote: 'Our collaboration with Sofixit spans several years, during which they have consistently delivered unparalleled quality without a single failure'
  }
]

const ContainerTestimonial = () => {
  const mediaQuery965 = useMediaQuery('(max-width: 964px)')

  return mediaQuery965 ? <ContainerTestimonialMobile /> : (
    <div className={styles.testimonial}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Slideshow
            GoBackComponent={ButtonBack}
            GoForwardComponent={ButtonForward}
            ControlsComponent={Controls}
            slideWidth={840}
            slideHeight={320}
            bounds={false}
            autoSlideTime={9999}
          >
          {partners.map((current, index) => (
            <div className={styles.container1} key={index}>
              {/*<b className={styles.text}>“</b>*/}
              <div className={styles.avatar}>
                <img
                  className={current.avatarClassName ?? styles.avatarImageIcon}
                  alt=""
                  src={current.avatar}
                />
                <div className={styles.avatarContent}>
                  <div className={styles.text1}>{current.name}</div>
                  <div className={styles.gft}>{current.position}</div>
                </div>
                <div className={styles.divider} />
                <b className={styles.gft}>{current.additionalInfo}</b>
              </div>
              <div className={styles.quoteWrapper}>
                <h5 className={styles.quote}>
                  {current.quote}
                </h5>
              </div>
            </div>
          ))}
          </Slideshow>
        </div>
      </div>
    </div>
  );
}

export default ContainerTestimonial;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 60px));
  ${({ $orientation }) => $orientation === 'left'
    ? 'left: 0'
    : 'right: 0'
  };
  z-index: 2;
`

const ControlsWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`

const Controls = ({ onClick, childrenCount, pointer }) => {
  return (
    <ControlsWrapper>
      <div className={styles.sliderDots}>
        { Array(childrenCount).fill(null).map((_, index) => (
          <button
            key={index}
            className={styles.dotActive}
            onClick={() => onClick(index)}
          >
            <div className={index === pointer ? styles.dot : styles.dot1} />
          </button>
        )) }
      </div>
    </ControlsWrapper>
  )
}

const ButtonBack = ({ onClick }) => {
  return (
    <ButtonWrapper $orientation='left'>
      <StyleSecondarySmallFalse2
        onClick={onClick}
        iconRelume="/octiconarrowleft16.svg"
        // iconRelume='/ArrowLeft.svg'
      />
    </ButtonWrapper>
  )
}

const ButtonForward = ({ onClick }) => {
  return (
    <ButtonWrapper $orientation='right'>
      <StyleSecondarySmallFalse2
        onClick={onClick}
        iconRelume="/octiconarrowright161.svg"
        // iconRelume='/ArrowRight.svg'
      />
    </ButtonWrapper>
  )
}

const ContainerTestimonialMobile = () => {
  return (
    <MobileRoot>
      { partners.map((partner, index) => (
        <PartnerMobile
          key={index}
          {...partner}
        />
      )) }
    </MobileRoot>
  )
}

const MobileRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  & > * + * {
    margin-top: 64px;
  }
`

const PartnerMobile = ({ name, avatar, position, additionalInfo, quote, avatarClassName }) => {
  return (
    <PartnerMobileRoot>
      <LeftPart>
        <Jumpscare variant={'left'}><img
          className={avatarClassName}
          src={avatar}
          alt={name}
        /></Jumpscare>
        <h1><Jumpscare variant={'left'}>{name}</Jumpscare></h1>
        <small><Jumpscare variant={'left'}>{position} at <b>{additionalInfo}</b></Jumpscare></small>
      </LeftPart>
      <RightPart>
        <UpperQuote><Jumpscare variant={'up'}>“</Jumpscare></UpperQuote>
        <Jumpscare variant={'right'} transitionDistance={60}>{quote}</Jumpscare>
        <LowerQuote><Jumpscare variant={'bottom'}>”</Jumpscare></LowerQuote>
      </RightPart>
    </PartnerMobileRoot>
  )
}

const LowerQuote = styled.span`
  font-size: 60px;
  line-height: 100%;
  position: absolute;
  right: 20px;
  bottom: -40px;
`

const UpperQuote = styled.span`
  font-size: 60px;
  line-height: 100%;
  position: absolute;
  left: -10px;
  top: -10px
`

const PartnerMobileRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  
  @media screen and (max-width: 679px) {
    flex-direction: column;
  }
`

const LeftPart = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  
  h1 {
    margin: 0; padding: 0;
    font-size: 24px;
  }
  
  small {
    margin: 0; padding: 0;
    font-size: 14px;
  }
  
  img {
    border-radius: 100%;
    height: 160px;
    width: 160px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 679px) {
    img {
      margin-bottom: 0;
    }
  }
`

const RightPart = styled.div`
  flex: 1;
  font-size: 18px;
  padding: 30px 40px 30px 0;
  position: relative;

  @media screen and (max-width: 679px) {
    transform: translateX(15px);
  }
`
