import { useMemo } from "react";
import styles from "./TechnologyContainer.module.css";
import Carousel from "../addons/Carousel";
import styled from "styled-components";
import {useMediaQuery} from "@mui/material";
import Jumpscare from "../addons/Jumpscare";

const technologies = [
  { name: 'Kotlin', icon: '/icon-kotlin.svg' },
  { name: 'JavaScript', icon: '/icon-java-s.svg' },
  { name: 'Docker', icon: '/icon-docker.svg' },
  { name: 'Azure', icon: '/icon-azure.svg' },
  { name: 'Scala', icon: '/icon-scala.svg' },
  { name: 'Spring', icon: '/icon-spring.svg' },
  { name: 'Java', icon: '/icon-java-8--11.svg' },
  { name: 'Angular', icon: '/icon-angular.svg' },
  { name: 'React', icon: '/icon-react.svg' },
  { name: 'AWS', icon: '/icon-aws.svg' },
  { name: 'Google', icon: '/icon-google.svg' },
]

const TechnologyContainer = ({ propBackgroundColor, propAlignSelf }) => {
  const technologyStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const text2Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const mediaQuery460px = useMediaQuery('(max-width: 459px)')

  return (
    <section className={styles.technology} style={technologyStyle}>
      <div className={styles.sectionTitle}>
        <div className={styles.text} style={text2Style}>
          Technology Experience
        </div>
      </div>
      { mediaQuery460px ? (
        <MobileTechnologyContainer>
          { technologies.map((technology, index) => (
            <TechnologyItemMobile
              {...technology}
              key={index}
            />
          )) }
        </MobileTechnologyContainer>
      ) : (
        <div className={styles.content}>
          <CarouselWrapper $bgColor={propBackgroundColor}>
            <Carousel
              data={technologies}
              dataRepeat={4}
              speed={0}
              itemWidth={144}
              ItemComponent={TechnologyItem}
            />
          </CarouselWrapper>
        </div>
      ) }
    </section>
  );
};

export default TechnologyContainer;

const MobileTechnologyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  padding: 0 12px;
`

const TechnologyItemMobileContainer = styled.div`
  img {
    height: 32px;
    width: auto;
  }
  
  p {
    font-size: 16px;
    padding: 0;
    margin: 0;
    margin-left: 6px;
  }
  
  margin: 6px;
  width: fit-content !important;
  padding: 3px 8px !important;
  padding-right: 14px !important;
`

const TechnologyItemMobile = ({ name, icon }) => {
  return (
    <Jumpscare>
      <TechnologyItemMobileContainer className={styles.badge}>
        <img
          alt={name}
          src={icon}
        />
        <p>{name}</p>
      </TechnologyItemMobileContainer>
    </Jumpscare>
  )
}

const TechnologyItem = ({ name, icon }) => {
  return (
    <div className={styles.badge}>
      <img
        className={styles.iconKotlin}
        alt={name}
        src={icon}
      />
      <div className={styles.javascript}>
        {name}
      </div>
    </div>
  )
}

const CarouselWrapper = styled.div`
  position: relative;
  width: 100vw !important;

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    z-index: 100;
    top: 0; bottom: 0;
    width: 60px;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, ${({ $bgColor }) => $bgColor} 0%, transparent 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, ${({ $bgColor }) => $bgColor} 0%, transparent 100%);
  }
`
