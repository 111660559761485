import AssessCard from "./AssessCard";
import styles from "./CloudMigrationProcessContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";

const CloudMigrationProcessContainer = () => {
  const mediaQuery1440 = useMediaQuery('(max-width: 1439px)')

  return (
    <section className={styles.workProcess}>
      <div className={styles.contentParent}>
        <div className={styles.content}>
          <div className={styles.content}>
            <div className={styles.content}>
              <div className={styles.content}>
                <h2 className={styles.heading}>
                  <Jumpscare>Streamline Your Cloud Migration Process with Us</Jumpscare>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content3}>
          <div className={styles.timeline}>
            <Jumpscare><AssessCard
              stepNumber="1"
              imageId="/identification.svg"
              taskDescription="Assess"
              infrastructureImageUrl="Evaluate existing infrastructure, pinpoint weaknesses, and set concise cloud migration goals in alignment with organizational objectives."
            /></Jumpscare>
            <Jumpscare delay={mediaQuery1440 ? 0 : 0.25}><AssessCard
              stepNumber="2"
              imageId="/project-initiation.svg"
              taskDescription="Plan"
              infrastructureImageUrl="Create a custom cloud migration strategy to meet your organization's unique requirements and ensure a smooth transition."
            /></Jumpscare>
            <Jumpscare delay={mediaQuery1440 ? 0 : 0.5}><AssessCard
              stepNumber="3"
              imageId="/execute.svg"
              taskDescription="Execute"
              infrastructureImageUrl="Execute the carefully planned migration with minimal disruption to your day-to-day business operations, maintaining optimal productivity throughout the process."
            /></Jumpscare>
            <Jumpscare delay={mediaQuery1440 ? 0 : 0.75}><AssessCard
              stepNumber="4"
              imageId="/optimaze.svg"
              taskDescription="Optimize"
              infrastructureImageUrl="To get the best results, optimize and monitor your cloud environment to maximize performance and cost-efficiency."
            /></Jumpscare>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CloudMigrationProcessContainer;
