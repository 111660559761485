import StyleLinkSmallFalseDark1 from "./StyleLinkSmallFalseDark1";
import styles from "./StyleSecondarySmallFalse2.module.css";

const StyleSecondarySmallFalse2 = ({ iconRelume, onClick = (() => {}) }) => {
  return (
    <div className={styles.stylesecondarySmallfalse} onClick={onClick}>
      <StyleLinkSmallFalseDark1 iconRelume={iconRelume} />
    </div>
  );
};

export default StyleSecondarySmallFalse2;
