import {useEffect, useState} from "react";
import env from '../.env.js'

const API_ROUTE = 'https://api.github.com/repositories/17165658/contents/examples/src/main/scala/org/apache/spark/examples'

const removeComments = code => {
  code = code.replace(/\/\*[\s\S]*?\*\//g, '');
  code = code.replace(/\/\/.*/g, '');
  code = code.replace(/^\s*[\r\n]/gm, '');
  return code;
}


export const useExamplesLinks = () => {
  const [links, setLinks] = useState([])
  useEffect(() => {
    fetch(API_ROUTE, {
      headers: {
        'Accept': 'application/vnd.github+json',
        'Authorization': `Bearer ${env.GITHUB_TOKEN}`
      }
    }).then(async response => {
      try {
        const body = await response.json()
        const fileLinks = body
          .filter(({type}) => type === 'file')
          .map(({url}) => url)
        setLinks(fileLinks)
      } catch (ignored) {}
    })
  }, [])
  return links
}

export const useExample = link => {
  const [content, setContent] = useState('')
  useEffect(() => {
    fetch(link, {
      headers: {
        'Accept': 'application/vnd.github+json',
        'Authorization': `Bearer ${env.GITHUB_TOKEN}`
      }
    }).then(async response => {
      try {
        const body = await response.json()
        const fileContent = atob(body.content)
        const fileContentWithoutComments = removeComments(fileContent)
        setContent(fileContentWithoutComments)
      } catch (ignored) {}
    })
  }, [])
  return content
}

export const useTypingAnimation = (originalContent, delay, simplify = 1) => {
  const [pointer, setPointer] = useState(0)
  useEffect(() => {
    const handle = setInterval(() => {
      setPointer(prev => {
        return prev + simplify >= originalContent.length
          ? 0
          : prev + simplify
      })
    }, delay * simplify)
    return () => clearInterval(handle)
  }, [originalContent, delay, simplify])
  return originalContent.slice(0, pointer)
}

export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handler = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])
  return width
}