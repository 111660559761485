// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StyleLinkSmallFalseDark2_button__\\+KW-F {
  position: relative;
  font-size: 16px;
  line-height: 150%;
  font-family: Roboto;
  color: #fefefe;
  text-align: left;
}
.StyleLinkSmallFalseDark2_iconRelume__JZq0o {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.StyleLinkSmallFalseDark2_stylelinkSmallfalseDark__h4D9G {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.StyleLinkSmallFalseDark2_stylelinkSmallfalseDark__h4D9G:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/StyleLinkSmallFalseDark2.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;EACf,SAAS;EACT,UAAU;EACV,6BAA6B;EAC7B,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;AACV;AACA;EACE,eAAe;AACjB","sourcesContent":[".button {\n  position: relative;\n  font-size: 16px;\n  line-height: 150%;\n  font-family: Roboto;\n  color: #fefefe;\n  text-align: left;\n}\n.iconRelume {\n  width: 24px;\n  position: relative;\n  height: 24px;\n  overflow: hidden;\n  flex-shrink: 0;\n}\n.stylelinkSmallfalseDark {\n  cursor: pointer;\n  border: 0;\n  padding: 0;\n  background-color: transparent;\n  border-radius: 4px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n}\n.stylelinkSmallfalseDark:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `StyleLinkSmallFalseDark2_button__+KW-F`,
	"iconRelume": `StyleLinkSmallFalseDark2_iconRelume__JZq0o`,
	"stylelinkSmallfalseDark": `StyleLinkSmallFalseDark2_stylelinkSmallfalseDark__h4D9G`
};
export default ___CSS_LOADER_EXPORT___;
