// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TypeCloudEMigration_itConsIcon__-mU5s {
  width: 87px;
  position: relative;
  height: 74.9px;
}
.TypeCloudEMigration_typecloudEmigration__gYYKo {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/components/TypeCloudEMigration.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,2BAA2B;AAC7B","sourcesContent":[".itConsIcon {\n  width: 87px;\n  position: relative;\n  height: 74.9px;\n}\n.typecloudEmigration {\n  height: 80px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itConsIcon": `TypeCloudEMigration_itConsIcon__-mU5s`,
	"typecloudEmigration": `TypeCloudEMigration_typecloudEmigration__gYYKo`
};
export default ___CSS_LOADER_EXPORT___;
