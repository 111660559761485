// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StyleSecondarySmallTrueD_button__DlzO7 {
  position: relative;
  line-height: 150%;
}
.StyleSecondarySmallTrueD_stylesecondarySmalltrueD__ETeFS {
  border-radius: 4px;
  border: 1px solid #1c1c1c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  text-align: left;
  font-size: 16px;
  color: #1c1c1c;
  font-family: Roboto;
}
.StyleSecondarySmallTrueD_stylesecondarySmalltrueD__ETeFS:hover {
  background-color: rgba(229, 229, 229, 0.5);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/StyleSecondarySmallTrueD.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,0CAA0C;EAC1C,eAAe;AACjB","sourcesContent":[".button {\n  position: relative;\n  line-height: 150%;\n}\n.stylesecondarySmalltrueD {\n  border-radius: 4px;\n  border: 1px solid #1c1c1c;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 20px;\n  text-align: left;\n  font-size: 16px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n.stylesecondarySmalltrueD:hover {\n  background-color: rgba(229, 229, 229, 0.5);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `StyleSecondarySmallTrueD_button__DlzO7`,
	"stylesecondarySmalltrueD": `StyleSecondarySmallTrueD_stylesecondarySmalltrueD__ETeFS`
};
export default ___CSS_LOADER_EXPORT___;
