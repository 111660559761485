import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import ContainerHeaderForm from "../components/ContainerHeaderForm";
import TechnologyContainer from "../components/TechnologyContainer";
import StreamlineForm from "../components/StreamlineForm";
import StreamlineContainer from "../components/StreamlineContainer";
import StyleSecondarySmallFalse1 from "../components/StyleSecondarySmallFalse1";
import Footer from "../components/Footer";
import styles from "./ServicesItConsultingWeb.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";

const ServicesItConsultingWeb = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  const openPopup = useSetPopupState()

  return (
    <div className={styles.servicesitConsultingWeb}>
      <Navbar
        frame11965="/frame-119658@2x.png"
        onLogoClick={onLogoClick}
      />
      <ContainerHeaderForm />
      <TechnologyContainer
        propBackgroundColor="#f6f6f6"
        propAlignSelf="unset"
      />
      <StreamlineForm />
      <StreamlineContainer />
      <section className={styles.cta}>
        <div className={styles.column}>
          <div className={styles.content}>
            <h2 className={styles.heading}>
              <Jumpscare>Transform your business with us</Jumpscare>
            </h2>
            <div className={styles.text}>
              <Jumpscare delay={0.25}>Our consulting team provides personalized solutions to optimize
                your technology and drive growth.</Jumpscare>
            </div>
          </div>
          <div className={styles.actions}>
            <Jumpscare delay={0.5}><StyleSecondarySmallFalse1
              onClick={() => openPopup ({
                title: "Transform your business",
                description: "Our consulting team provides personalized solutions to optimize your technology and drive growth."
              })}
              button="Contact Us"
              styleSecondarySmallFalseBackgroundColor="unset"
              buttonDisplay="inline-block"
            /></Jumpscare>
          </div>
        </div>
      </section>
      <Footer
        frame11965="/frame-119656@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default ServicesItConsultingWeb;
