import { useMemo } from "react";
import ButtonCardFormFilter from "./ButtonCardFormFilter";
import styles from "./CardStart.module.css";
import {useSetPopupState} from "../addons/ContactPopup";

const CardStart = ({
  cardStartBorder,
  cardStartPadding,
  headingDisplay,
  textDisplay,
}) => {
  const cardStartStyle = useMemo(() => {
    return {
      border: cardStartBorder,
      padding: cardStartPadding,
    };
  }, [cardStartBorder, cardStartPadding]);

  const headingStyle = useMemo(() => {
    return {
      display: headingDisplay,
    };
  }, [headingDisplay]);

  const textStyle = useMemo(() => {
    return {
      display: textDisplay,
    };
  }, [textDisplay]);

  const openPopup = useSetPopupState()

  return (
    <>
      <div
        className={styles.cardStart}
        onClick={() => openPopup ({
          title: "Wanna talk?",
          description: "We're here for you! Reach out today for assistance and support. Let's make things better together"
        })}
        style={cardStartStyle}
      >
        <div className={styles.content}>
          <div className={styles.contentTop}>
            <div className={styles.content1}>
              <ButtonCardFormFilter
                productCode="/solarmaparrowrightbroken.svg"
                propBorder="1px solid #fefefe"
                propPadding="12px"
                propBorderRadius="50px"
                propWidth="24px"
                propHeight="24px"
              />
            </div>
            <div className={styles.content2}>
              <div className={styles.heading} style={headingStyle}>
                <p className={styles.startAProject}>Start a project</p>
                <p className={styles.startAProject}>with us</p>
              </div>
              <div className={styles.text} style={textStyle}>
                Let’s talk. Tell us what you need and we will support your
                business and deliver the best solutions.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardStart;
