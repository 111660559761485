// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssessCard_div__61DkA {
  width: 8.9px;
  position: relative;
  line-height: 150%;
  font-weight: 600;
  display: inline-block;
  height: 20.2px;
  flex-shrink: 0;
}
.AssessCard_assessIcon__WbKGc {
  width: 50px;
  position: relative;
  height: 50px;
}
.AssessCard_content__6I\\+7C,
.AssessCard_content1__QPejc {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.AssessCard_content__6I\\+7C {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
}
.AssessCard_heading__2kENg,
.AssessCard_text__xyo1J {
  align-self: stretch;
  position: relative;
}
.AssessCard_heading__2kENg {
  margin: 0;
  font-size: inherit;
  line-height: 140%;
  font-weight: 700;
  font-family: inherit;
}
.AssessCard_text__xyo1J {
  font-size: 16px;
  line-height: 150%;
}
.AssessCard_content2__qRamj,
.AssessCard_timelineItem__y3K49 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.AssessCard_content2__qRamj {
  flex: 1 1;
  flex-direction: column;
  padding: 8px 0;
  gap: 8px;
  font-size: 24px;
}
.AssessCard_timelineItem__y3K49 {
  width: 312px;
  flex-direction: row;
  gap: 40px;
  text-align: left;
  font-size: 16px;
  color: #1c1c1c;
  font-family: Roboto;
}
`, "",{"version":3,"sources":["webpack://./src/components/AssessCard.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,cAAc;EACd,cAAc;AAChB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;AACd;AACA;;EAEE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;EACvB,SAAS;AACX;AACA;;EAEE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;;EAEE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;AAC7B;AACA;EACE,SAAO;EACP,sBAAsB;EACtB,cAAc;EACd,QAAQ;EACR,eAAe;AACjB;AACA;EACE,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".div {\n  width: 8.9px;\n  position: relative;\n  line-height: 150%;\n  font-weight: 600;\n  display: inline-block;\n  height: 20.2px;\n  flex-shrink: 0;\n}\n.assessIcon {\n  width: 50px;\n  position: relative;\n  height: 50px;\n}\n.content,\n.content1 {\n  align-self: stretch;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n}\n.content {\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: center;\n  gap: 16px;\n}\n.heading,\n.text {\n  align-self: stretch;\n  position: relative;\n}\n.heading {\n  margin: 0;\n  font-size: inherit;\n  line-height: 140%;\n  font-weight: 700;\n  font-family: inherit;\n}\n.text {\n  font-size: 16px;\n  line-height: 150%;\n}\n.content2,\n.timelineItem {\n  display: flex;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n.content2 {\n  flex: 1;\n  flex-direction: column;\n  padding: 8px 0;\n  gap: 8px;\n  font-size: 24px;\n}\n.timelineItem {\n  width: 312px;\n  flex-direction: row;\n  gap: 40px;\n  text-align: left;\n  font-size: 16px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"div": `AssessCard_div__61DkA`,
	"assessIcon": `AssessCard_assessIcon__WbKGc`,
	"content": `AssessCard_content__6I+7C`,
	"content1": `AssessCard_content1__QPejc`,
	"heading": `AssessCard_heading__2kENg`,
	"text": `AssessCard_text__xyo1J`,
	"content2": `AssessCard_content2__qRamj`,
	"timelineItem": `AssessCard_timelineItem__y3K49`
};
export default ___CSS_LOADER_EXPORT___;
