import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import DeliveringSolutionsContainer from "../components/DeliveringSolutionsContainer";
import ContactForm from "../components/ContactForm";
import ContainerCTA from "../components/ContainerCTA";
import Footer from "../components/Footer";
import styles from "./CompanyWeb.module.css";
import Jumpscare from "../addons/Jumpscare";
import FAQContainer from "../components/FAQContainer";
import {useMediaQuery} from "@mui/material";

const faqs = [
  {
    question: 'What services do you offer?',
    answer: 'We specialize in IT Product Development, providing tailored solutions. Our IT Consulting services offer strategic insights. IT Outsourcing ensures efficient operations, and Cloud Enablement & Migration support seamless transitions to the cloud.',
  },
  {
    question: 'How can I contact you?',
    answer: 'For communication, our primary method is email. Feel free to reach out to us at kontakt@sofixit.pl for inquiries, assistance, or any information you may need.'
  },
  {
    question: 'Do you provide IT consulting?',
    answer: 'Yes, we provide top-notch IT consulting services. Our team of experienced professionals offers strategic insights, innovative solutions, and a client-focused approach, making us an excellent choice for your IT consulting needs.'
  },
  {
    question: 'What is your expertise?',
    answer: 'Our expertise extends across various technologies, with a primary focus on Big Data, data transformations, and processing. We excel in utilizing Kotlin, JavaScript, Docker, Azure, Scala, Spring, Java, Angular, React, AWS, and Google Cloud to deliver comprehensive solutions in the realm of IT services.'
  },
  {
    question: 'Do you offer cloud migration?',
    answer: 'Yes, we offer cloud migration services. Additionally, our company has developed and actively works on products that streamline and simplify the data migration process, ensuring a smooth transition to the cloud.'
  }
]

const CompanyWeb = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  const mediaQuery500 = useMediaQuery('(max-width: 499px)')

  return (
    <div className={styles.companyWeb}>
      <Navbar
        frame11965="/frame-11965@2x.png"
        onLogoClick={onLogoClick}
      />
      <Jumpscare variant={'left'} transitionTime={1} transitionDistance={20} stretch>
        <div className={styles.header}>
          <div className={styles.header1}>
            <div className={styles.content}>
              <div className={styles.container}>
                <h1 className={styles.mediumLengthHero}>
                  <Jumpscare delay={0.75}>
                    Delivering IT Excellence
                  </Jumpscare>
                </h1>
              </div>
              <div className={styles.column}>
                <div className={styles.loremIpsumDolor}>
                  {mediaQuery500 ? (
                    <Jumpscare delay={1.25}>
                      With extensive technological and domain knowledge,
                      we implement plans that follow tried and true practices
                      from top consulting companies.
                    </Jumpscare>
                  ) : (
                    <Jumpscare delay={1.25}>
                      We are a Wroclaw based IT service company focused on technology
                      optimization and consulting. With extensive technological and
                      domain knowledge, we implement plans that follow tried and true
                      practices from top consulting companies.
                    </Jumpscare>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Jumpscare>
      <section className={styles.about}>
        <div className={styles.content1}>
          <div className={styles.content2}>
            <h2 className={styles.heading}>
              <Jumpscare>
                Experience the Global Reach and Expertise in Technology
                Optimization
              </Jumpscare>
            </h2>
            <div className={styles.text}>
              <p className={styles.ourCompanyOffers}>
                <Jumpscare /* threshold={0.9} */ variant={'left'}>
                  Our company offers a consultative approach to deliver the
                  perfect solutions for every business, leveraging extensive
                  technological and domain knowledge.
                </Jumpscare>
              </p>
              <p className={styles.ourCompanyOffers}>&nbsp;</p>
              <p className={styles.ourCompanyOffers}>
                <Jumpscare /* threshold={0.9} */ variant={'left'}>
                  We are IT service company focused on technology optimization and
                  consulting. We work globally, aiming to deliver the perfect
                  solutions for every business. Our primary focus is on Software
                  Development.
                </Jumpscare>
              </p>
              <p className={styles.ourCompanyOffers}>
                <Jumpscare /* threshold={0.9} */ variant={'left'}>We take best practices from Agile to produce our Software.</Jumpscare>
              </p>
              <p className={styles.ourCompanyOffers}>&nbsp;</p>
              <p className={styles.ourCompanyOffers}>
                <Jumpscare /* threshold={0.9} */ variant={'left'}>
                  You can expect extensive technological and domain knowledge from
                  our trusted team, as well as the implementation of plans that
                  follow all tried and true practices from top consulting
                  companies. For web application development we follow Agile
                  (Scrum) principles and the SDLC framework, both of which are
                  above industry standards. We can also work directly inside your
                  organization to build IT services from scratch.
                </Jumpscare>
              </p>
            </div>
          </div>
          <Jumpscare /* threshold={0.7} */ variant={'right'}>
            <img
              className={styles.containerIcon}
              alt=""
              src="/container@2x.png"
            />
          </Jumpscare>
        </div>
      </section>
      <DeliveringSolutionsContainer />
      <ContactForm />
      <FAQContainer
        faqs={faqs}
        faqText='Find answers to common questions about our processes, services, and client collaboration.'
      />
      <ContainerCTA
        descriptionText="Looking for a fast and easy solution? Take advantage of our subscription plan."
        businessSolutionsText="Discover how our expert team can optimize your technology and provide tailored IT solutions to meet your business needs. "
        propBackgroundImage="url('/cta4@3x.png')"
      />
      <Footer
        frame11965="/frame-119655@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default CompanyWeb;
