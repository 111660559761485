import ConsultationCard from "./ConsultationCard";
import styles from "./WorkProcessContainer1.module.css";
import Jumpscare from "../addons/Jumpscare";

const WorkProcessContainer1 = () => {
  return (
    <section className={styles.workProcess}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.sectionTitle}>
            <div className={styles.sectionTitle}>
              <div className={styles.sectionTitle}>
                <div className={styles.content3}>
                  <h2 className={styles.heading}>
                    <Jumpscare threshold={0.9}>Make things happen</Jumpscare>
                  </h2>
                  <b className={styles.text}>
                    <Jumpscare threshold={0.9}>
                      Are you considering to automating process in your
                      organization?
                    </Jumpscare>
                  </b>
                  <b className={styles.text}>
                    <Jumpscare threshold={0.9}>
                      Is there a need to optimize existing solution, due to
                      performance or capacity reason?
                    </Jumpscare>
                  </b>
                  <b className={styles.text}>
                    <Jumpscare threshold={0.9}>Is your application lifecycle coming to an end?</Jumpscare>
                  </b>
                  <div className={styles.text3}>
                    <p className={styles.ifTheAnswer}>
                      <Jumpscare threshold={0.9}>
                        If the answer to any of the above is “yes”, please do not
                        hesitate to contacts us. Use the contact form and we will
                        contact you as soon as possible. You can also write us an
                        email or just give us a call. Our customer service agents
                        are here to help.
                      </Jumpscare>
                    </p>
                    <p className={styles.ifTheAnswer}></p>
                    <p className={styles.ifTheAnswer} /* style={{ marginTop: '20px' }} */>
                      <Jumpscare threshold={0.9}>
                        We can build everything for you, from small applications
                        to enterprise-level software with a high scalability.
                      </Jumpscare>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConsultationCard />
      </div>
    </section>
  );
};

export default WorkProcessContainer1;
