import { useState, useCallback } from "react";
import MegaMenu from "./MegaMenu";
import PortalDrawer from "./PortalDrawer";
import StyleTertiarySmallTrueDa1 from "./StyleTertiarySmallTrueDa1";
import MenuTabletMobile from "./MenuTabletMobile";
import StylePrimarySmallTrueDar from "./StylePrimarySmallTrueDar";
import styles from "./Navbar.module.css";
import { useMediaQuery } from "@mui/material";
import { useSetPopupState } from "../addons/ContactPopup";
import { useNavigate } from "react-router-dom";

const servicesLinks = [
  {
    title: "Custom Software Development",
    description: "Innovate Tomorrow, Today",
    icon: "/services-small.svg",
    link: "/product-development",
  },
  {
    title: "Technology Consulting",
    description: "Navigate Success with Expert Guidance",
    icon: "/services-small1.svg",
    link: "/consulting",
  },
  {
    title: "IT Outsourcing",
    description: "Efficiency Beyond Borders",
    icon: "/services-small2.svg",
    link: "/outsourcing",
  },
];

const programsLink = [
  {
    title: "Mentoring",
    description:
      "Joining a mentoring program as a mentee offers invaluable opportunities for personal and professional growth",
    icon: "/services-small2.svg",
    link: "/mentoring",
  },
  {
    title: "Podcasts",
    description: "Podcast series focused on BigData across various industries!",
    icon: "/services-small1.svg",
    link: "/podcasts",
  },
];

const navigationLinks = Object.freeze([
  {
    title: "Services",
    links: servicesLinks,
  },
  {
    title: "Big Data Transformations",
    navigateTo: "/big-data",
  },
  {
    title: "Cloud Transformation",
    navigateTo: "/cloud-solutions",
  },
  {
    title: "Our Programs",
    links: programsLink,
  },
  {
    title: "Company",
    navigateTo: "/our-company",
  },
  {
    title: "Careers",
    navigateTo: "/career",
  },
]);

const Navbar = ({ frame11965, onLogoClick }) => {
  const navigate = useNavigate();
  const navigateToPage = useCallback(
    (route) => {
      navigate(route);
    },
    [navigate],
  );

  const [isRowOpen, setRowOpen] = useState(false);

  const [megaMenuLinks, setMegaMenuLinks] = useState(null);

  const openRow = useCallback(() => {
    setRowOpen(true);
  }, []);

  const closeRow = useCallback(() => {
    setRowOpen(false);
  }, []);

  const showFreeQuote = useMediaQuery("(min-width:1151px)");

  const openPopup = useSetPopupState();

  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.column}>
              <div className={styles.logo} onClick={onLogoClick}>
                <img className={styles.logoChild} alt="" src={frame11965} />
                <b className={styles.sofixit}>Sofixit</b>
              </div>
            </div>
          </div>
          <div className={styles.column1}>
            {navigationLinks.map(({ title, links, navigateTo }, index) => (
              <>
                {navigateTo && (
                  <StyleTertiarySmallTrueDa1
                    key={index}
                    button={title}
                    styleTertiarySmallTrueDaBorder="none"
                    styleTertiarySmallTrueDaBackgroundColor="transparent"
                    buttonDisplay="inline-block"
                    onButton1Click={() => navigateToPage(navigateTo)}
                  />
                )}
                {links && (
                  <button
                    className={styles.button}
                    onClick={() => setMegaMenuLinks(links)}
                  >
                    <div className={styles.services}>{title}</div>
                    <img
                      className={styles.iconChevronDown}
                      alt=""
                      src="/icon--chevron-down.svg"
                    />
                  </button>
                )}
              </>
            ))}
          </div>
          <div className={styles.actions}>
            <button className={styles.mingcutemenuFill} onClick={openRow}>
              <img className={styles.groupIcon} alt="" src="/group.svg" />
            </button>
            {showFreeQuote && (
              <StylePrimarySmallTrueDar
                onClick={() =>
                  openPopup({
                    title: "Get a free quote",
                    description: "Contact us right now!",
                  })
                }
                button="Get a free quote"
                stylePrimarySmallTrueDarPosition="unset"
                stylePrimarySmallTrueDarTop="unset"
                stylePrimarySmallTrueDarRight="unset"
                stylePrimarySmallTrueDarWidth="160px"
                buttonDisplay="inline-block"
              />
            )}
          </div>
        </div>
      </nav>
      {megaMenuLinks && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={() => setMegaMenuLinks(null)}
        >
          <MegaMenu links={megaMenuLinks} />
        </PortalDrawer>
      )}
      {isRowOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeRow}
        >
          <MenuTabletMobile onClose={closeRow} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Navbar;
