import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import StyleLinkSmallFalseDark2 from "../components/StyleLinkSmallFalseDark2";
import Navbar from "../components/Navbar";
import SoftwareDesignContainer from "../components/SoftwareDesignContainer";
import ITProductDevelopmentContainer from "../components/ITProductDevelopmentContainer";
import DeliveringIndustryContainer from "../components/DeliveringIndustryContainer";
import TechnologyExperienceContainer from "../components/TechnologyExperienceContainer";
import WorkProcessContainer from "../components/WorkProcessContainer";
import ContainerCTA from "../components/ContainerCTA";
import CardContainer from "../components/CardContainer";
import StyleSecondarySmallFalse2 from "../components/StyleSecondarySmallFalse2";
import Footer from "../components/Footer";
import styles from "./MainPage.module.css";
import Jumpscare from "../addons/Jumpscare";
import Showcase from "../addons/Showcase";
import styled from "styled-components";
import Flipcard from "../addons/Flipcard";
import {useMediaQuery} from "@mui/material";
import TechnologiesCarousel from "../components/TechnologiesCarousel";

const MainPage = () => {
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLogo1Click = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onIconLinkedInClick = useCallback(() => {
    window.open(
      "https://www.linkedin.com/company/sofixit/?originalSubdomain=pl"
    );
  }, []);

  return (
    <div className={styles.mainPage}>
      <section className={styles.head}>
        {/* <DesignTeamAds /> */}
        <Navbar
          frame11965="/frame-11965@2x.png"
          onLogoClick={onLogoClick}
        />
        <div className={styles.megaMenuWrapper}>
          <div className={styles.megaMenu}>
            <div className={styles.menu}>
              <div className={styles.menuList}>
                <div className={styles.menuItem}>
                  <img
                    className={styles.servicesSmallIcon}
                    src="/services-small.svg"
                  />
                  <div className={styles.content}>
                    <div className={styles.pageOne}>Custom Software Development</div>
                    <div className={styles.loremIpsumDolor}>
                      Unleash the power of cutting-edge IT solutions through our dynamic product development expertise.
                    </div>
                  </div>
                </div>
                <div className={styles.menuItem}>
                  <img
                    className={styles.servicesSmallIcon1}
                    alt=""
                    src="/services-small1.svg"
                  />
                  <div className={styles.content}>
                    <div className={styles.pageTwo}>It Consluting</div>
                    <div className={styles.loremIpsumDolor}>
                      Lorem ipsum dolor sit amet consectetur elit
                    </div>
                  </div>
                </div>
                <div className={styles.menuItem}>
                  <img
                    className={styles.servicesSmallIcon2}
                    alt=""
                    src="/services-small2.svg"
                  />
                  <div className={styles.content}>
                    <div className={styles.pageOne}>It Outsourcing</div>
                    <div className={styles.loremIpsumDolor}>
                      Lorem ipsum dolor sit amet consectetur elit
                    </div>
                  </div>
                </div>
                <div className={styles.menuItem}>
                  <img
                    className={styles.servicesSmallIcon3}
                    alt=""
                    src="/services-small3.svg"
                  />
                  {/*<div className={styles.content}>
                    <div
                      className={styles.pageOne}
                    >{`Cloud Enablement & Migration`}</div>
                    <div className={styles.loremIpsumDolor}>
                      Lorem ipsum dolor sit amet consectetur elit
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
            <div className={styles.content4}>
              <div className={styles.content5}>
                <div className={styles.lookingForA}>
                  Looking for a new career?
                </div>
                <div className={styles.getInTouch}>Get in touch</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.header}>
          <SoftwareDesignContainer />
          { /* <ContainerWrapper /> */ }
          <TechnologiesCarousel />
        </div>
      </section>
      <section className={styles.services}>
        <div className={styles.container}>
          <ITProductDevelopmentContainer />
          { /* <StartProjectContainer /> */ }
        </div>
      </section>
      <DeliveringIndustryContainer />
      <TechnologyExperienceContainer />
      <WorkProcessContainer />

      <ContainerCTA
        descriptionText="Transforming businesses through innovative technology"
        businessSolutionsText="We are here to help you optimize your IT infrastructure and find the perfect solutions for your business."
        propBackgroundImage="url('/cta@3x.png')"
      />
      { /* <ContainerCompanies /> */ }
      <section className={styles.blog}>
        <div className={styles.container1}>
          <CardContainer />
          <CaseStudies />
        </div>
      </section>
      <Footer
        frame11965="/frame-119651@2x.png"
        onLogoClick={onLogo1Click}
        onIconLinkedInClick={onIconLinkedInClick}
      />
    </div>
  );
};

export default MainPage;

const cases = [
  {
    title: 'Security by Design: Banking Industry',
    description: 'Establish development team for "Payment transaction 2.0" for former polish Bank BZ WBK.',
    image: '/payment4334491-1280-1@2x.png'
  },
  {
    title: 'Internal Portal: Insurance Company',
    description: 'Learn how we built an internal portal for a major insurance company.',
    image: '/calculator1680905-1280-1@2x.png'
  },
  {
    title: 'Application Support and Maintenance',
    description: 'In past two years we’ve been capable to serve and manage full end to end Application Maintenance and Support functions.',
    image: '/startup849794-1280-1@2x.png'
  },

]

const CaseStudies = () => {
  const mediaQuery1150 = useMediaQuery('(max-width: 1150px)')

  if (mediaQuery1150) {
    return <MobileCaseStudies />
  }

  return (
    <Showcase
      data={cases}
      ItemComponent={CardElement}
      ControlsComponent={DefaultControls}
      inactiveOpacity={0.5}
    />
  )
}

//==========

const MobileCaseStudies = () => {
  return (
    <MobileRoot>
      { cases.map((props, index) => (
        <MobileCaseStudy
          key={index}
          {...props}
        />
      )) }
    </MobileRoot>
  )
}

const MobileCaseStudy = ({
  title,
  description,
  image
}) => {
  return (
    <Jumpscare>
      <MobileCaseRoot>
        <img
          alt={title}
          src={image}
        />
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </MobileCaseRoot>
    </Jumpscare>
  )
}

const MobileRoot = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 24px;
  }
  
  @media screen and (max-width: 429px) {
    width: 100vw;
    margin: 0 -64px;
    padding: 0 20px;
  }
`

const MobileCaseRoot = styled.div`
  display: flex;
  
  img {
    height: 160px;
    width: auto;
  }

  div {
    margin-left: 32px;
    p {
      max-width: 560px;
    }
  }
  
  @media screen and (max-width: 699px) {
    flex-direction: column;
    div {
      margin-left: 0;
      h1 {
        margin: 0;
        margin-top: 12px;
      }
      p {
        margin: 0;
      }
    }
    
    img {
      width: 320px;
      height: auto;
    }
  }
`

//==========

const DefaultControls = ({
                           count,
                           pointer,
                           setPointer
                         }) => {
  const handleGoBack = useCallback(() => {
    setPointer(prev => {
      return prev - 1 < 0
        ? count - 1
        : prev - 1
    })
  }, [count])
  const handleGoForward = useCallback(() => {
    setPointer(prev => {
      return prev + 1 >= count
        ? 0
        : prev + 1
    })
  }, [count])

  return (
    <>
      <DefaultLeftControlsWrapper>
          <StyleSecondarySmallFalse2
            iconRelume="/octiconarrowleft16.svg"
            onClick={handleGoBack}
          />
      </DefaultLeftControlsWrapper>
      <DefaultBottomControlsWrapper>
        <div className={styles.sliderDots}>
          {Array(count).fill(null).map((_, index) => (
            <button
              key={index}
              className={styles.dotActive}
              onClick={() => setPointer(index)}
            >
              <div className={index === pointer ? styles.dot : styles.dot1}/>
            </button>
          ))}
        </div>
      </DefaultBottomControlsWrapper>
      <DefaultRightControlsWrapper>
        <StyleSecondarySmallFalse2
          iconRelume="/octiconarrowright161.svg"
          onClick={handleGoForward}
        />
      </DefaultRightControlsWrapper>
    </>
  )
}

const DefaultRightControlsWrapper = styled.div`
  position: absolute;
  right: 20px;
  margin: 0 !important;
`

const DefaultLeftControlsWrapper = styled.div`
  position: absolute;
  left: 20px;
  margin: 0 !important;
`

const DefaultBottomControlsWrapper = styled.div`
  margin: 0 !important;
  position: absolute;
  bottom: -20px;
  & > * + * {
    margin-left: 6px;
  }
`

const CardElement = ({ title, description, image }) => {
  const navigate = useNavigate()
  // const handleClick = useCallback(() => {
  //   navigate('/case-studies')
  // }, [navigate])

  const front = (
    <FrontWrapper>
      <CardImage src={image} alt={title}/>
      <CardTitle>{title}</CardTitle>
    </FrontWrapper>
  )

  const back = (
    <BackWrapper>
      { description }
    </BackWrapper>
  )

  return (
    <CardWrapper /* onClick={handleClick} */>
      <Jumpscare>
        <Flipcard
          front={front}
          back={back}
          width={300}
          height={300}
        />
      </Jumpscare>
    </CardWrapper>
  )
}

const CardImage = styled.img`
  width: 300px;
  height: auto;
`

const BackWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 16px 24px;
  transform: scale(1.05);
  border-radius: 16px;
  border: 3px dashed #ccc;
`

const FrontWrapper = styled.div``

const CardTitle = styled.h1`
  margin: 0; padding: 0;
  font-size: 24px;
  line-height: 120%;
`

const CardWrapper = styled.div`
  box-sizing: border-box !important;
  & * {
    box-sizing: border-box !important;
  }
  width: 300px;
  height: 300px;
  margin: 24px;
  background-color: #f6f6f6;
`

// let it be here

const DesignTeamAds = () => {
  const lessThan380 = useMediaQuery('(max-width: 380px)')

  return (
    <div className={styles.primary}>
      <div className={styles.newDesignTeamInSubscriptiParent}>
        <div className={styles.newDesignTeam}>
          {lessThan380
            ? '30% Subscription Discount!'
            : 'Design Team in Subscription! 30% discount for first 2 months!'}
        </div>
        <StyleLinkSmallFalseDark2
          button="More"
          iconRelume="/octiconarrowright16.svg"
        />
      </div>
    </div>
  )
}