import styles from "./Footer.module.css";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

const navigationLinks = [
  {
    title: 'Big Data Transformations',
    navigateTo: '/big-data'
  },
  {
    title: 'Cloud Transformation',
    navigateTo: '/cloud-solutions'
  },
  {
    title: 'Company',
    navigateTo: '/our-company'
  },
  {
    title: 'Careers',
    navigateTo: '/career'
  }
]

const Footer = ({
  frame11965,
  onLogoClick,
  onIconLinkedInClick,
}) => {
  const handlePrivacyPolicy = useCallback(() => {
    window.open('http://localhost:3031/privacy-policy', '_blank').focus()
  }, [])

  const handleTermsAndConditions = useCallback(() => {
    window.open('http://localhost:3031/terms-and-conditions', '_blank').focus()
  })

  const handleCookiePolicy = useCallback(() => {
    window.open('http://localhost:3031/cookie-policy', '_blank').focus()
  })

  const navigate = useNavigate()
  const openPage = useCallback(page => {
    navigate(page)
  }, [navigate])

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.logo} onClick={onLogoClick}>
            <img className={styles.logoChild} alt="" src={frame11965} />
            <b className={styles.sofixit}>Sofixit</b>
          </div>
          <div className={styles.links}>
            { /*
            <div className={styles.button}>
              <StyleLinkSmallFalseDark
                button="Services"
                iconRelume="/icon--chevron-down.svg"
              />
            </div>
            */ }
            { navigationLinks.map(({ title, navigateTo }, index) => (
              <button
                key={index}
                className={styles.button1}
                onClick={() => openPage(navigateTo)}
              >
                <div className={styles.button2}>{ title }</div>
              </button>
            )) }
          </div>
          <div className={styles.socialLinks}>
            {/* <a
              className={styles.iconFacebook}
              href="https://www.facebook.com/profile.php?id=100079092518051"
              onClick={onIconFacebookClick}
            >
              <img className={styles.vectorIcon} alt="" src="/vector1.svg" />
            </a> */}
            <a className={styles.iconFacebook} onClick={onIconLinkedInClick}>
              <img className={styles.vectorIcon1} alt="" src="/vector2.svg" />
            </a>
          </div>
        </div>
        <div className={styles.credits}>
          <div className={styles.divider} />
          <div className={styles.row}>
            <div className={styles.sofixitAllRights}>
              © 2024 Sofixit. All rights reserved.
            </div>
            <div className={styles.footerLinks} style={{ display: 'none' }}>
              <a className={styles.privacyPolicy} onClick={handlePrivacyPolicy}>Privacy Policy</a>
              <a className={styles.privacyPolicy} onClick={handleTermsAndConditions}>Terms and Conditions</a>
              <a className={styles.privacyPolicy} onClick={handleCookiePolicy}>Cookie Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
