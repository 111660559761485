import {Fragment, useCallback } from "react";
import StyleSecondarySmallTrueD from "./StyleSecondarySmallTrueD";
import styles from "./CloudEnablementContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import FAQ from "../addons/FAQ";
import {useSetPopupState} from "../addons/ContactPopup";
import {useNavigate} from "react-router-dom";

const faqs = [
  {
    question: 'What is IT Outsourcing?',
    answer: '<empty>'
  },
  {
    question: 'Why should I consider IT Outsourcing?',
    answer: '<empty>'
  },
  {
    question: 'How does IT Outsourcing work?',
    answer: '<empty>'
  },
  {
    question: 'What are the risks of IT Outsourcing?',
    answer: '<empty>'
  },
  {
    question: 'How do I get started with IT Outsourcing?',
    answer: '<empty>'
  }
]

const CloudEnablementContainer = () => {
  const openPopup = useSetPopupState()

  const navigate = useNavigate()
  const openOutsourcing = useCallback(() => {
    navigate('/outsourcing')
  }, [])

  return (
    <section className={styles.faq}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.sectionTitle}>
            <div className={styles.subheading}>Efficient</div>
            <div className={styles.content1}>
              <b className={styles.heading}>
                <Jumpscare>
                  Unlock the Power of the Cloud with Our Expert Cloud Enablement
                  Services
                </Jumpscare>
              </b>
              <div className={styles.text}>
                <Jumpscare delay={0.5}>Our team is ready to apply our know-how and best practices to
                  help you in this journey.</Jumpscare>
              </div>
            </div>
          </div>
          <div className={styles.faq2}>
            {/*<div className={styles.sectionTitle1}>
              <b className={styles.heading}><Jumpscare>FAQs</Jumpscare></b>
              <div className={styles.text1}>
                <Jumpscare delay={0.5}>Find answers to common questions about IT Outsourcing and how to
                  get started.</Jumpscare>
              </div>
            </div>
            <div className={styles.accordion}>
              { faqs.map((question, index) => (
                <Fragment key={index}>
                  <div className={styles.divider} />
                  <FAQ {...question} />
                </Fragment>
              )) }
              <div className={styles.divider} />
            </div>*/}
            <div className={styles.content2}>
              <div className={styles.content3}>
                <b className={styles.stillHaveQuestions}>
                  <Jumpscare>Still have questions?</Jumpscare>
                </b>
                <div className={styles.text2}>
                  <Jumpscare delay={0.5}>Contact us for more information or assistance.</Jumpscare>
                </div>
              </div>
              <Jumpscare delay={0.75}><StyleSecondarySmallTrueD
                onClick={() => openPopup ({
                  title: "Still have questions?",
                  description: "Contact us for more information or assistance."
                })}
                button="Contact Us"
                styleSecondarySmallTrueDBackgroundColor="unset"
                buttonDisplay="inline-block"
              /></Jumpscare>
            </div>
          </div>
          <div className={styles.button} onClick={openOutsourcing}>
            <div className={styles.button1}>Show more</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CloudEnablementContainer;
