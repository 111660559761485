import GlobalReachCard from "./GlobalReachCard";
import styles from "./StreamlineContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";

const StreamlineContainer = () => {
  const mediaQuery650 = useMediaQuery('(max-width: 640px)')

  return (
    <section className={styles.streamline}>
      <div className={styles.content}>
        <div className={styles.column}>
          <GlobalReachCard
            imageDescription="/streamlinegraphbarincreasesolid.svg"
            featureDescription="Increase Efficiency"
            businessDescription="Identify bottlenecks, streamline processes, and implement cutting-edge technologies to boost productivity."
            imageDimensions="/streamlineaitechnologysparksolid.svg"
            featureIcon="Drive Innovation"
            technologyOptimizationDes="Stay ahead of the competition by embracing emerging technologies and implementing innovative solutions."
            propFlexDirection="column"
            propFlexWrap="unset"
            propFlex="unset"
            propAlignSelf="stretch"
            propOverflow="hidden"
            propFlex1="unset"
            propAlignSelf1="stretch"
            propOverflow1="hidden"
          />
        </div>
        <div className={styles.content1}>
          <h2 className={styles.heading}>
            <Jumpscare>Streamline your business with expert IT consulting</Jumpscare>
          </h2>
          <div className={styles.text}>
            <p className={styles.ifYouNeed}>
              <Jumpscare threshold={mediaQuery650 ? 0.25 : 0.6} variant={'right'}>If you need someone who can provide you expertise in any IT domain
                and/or you are looking for highly skilled specialists, you've
                found right place. Our target is not only to supply companies and
                projects with human resources , but also help drive IT processes,
                advice proper technologies and ensure that all processes and
                people behind them are concentrated on the same goal.</Jumpscare>
            </p>
            <p className={styles.ifYouNeed}>
              <Jumpscare threshold={mediaQuery650 ? 0.25 : 0.6} variant={'right'}>We treat all our clients equally, it does not matter if you are
                enterprise level company or a start-up, you will get optimal plan
                and solution tailored for your case.</Jumpscare>
            </p>
            <p className={styles.ifYouNeed}>
              <Jumpscare threshold={mediaQuery650 ? 0.25 : 0.6} variant={'right'}>Complexity of IT environment is pushing many companies into
                chaotic planning and solution development. We've also been there,
                so we can help you build processes that bring simplicity in your
                projects and IT management. Instead of bringing more and more
                tools and technologies, we are concentrating on optimization and
                standardization of what you have today instead of expensive build
                from scratch approach.</Jumpscare>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StreamlineContainer;
