// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalReachCard_iconGlobe__NZYW3 {
  width: 80px;
  position: relative;
  height: 80px;
}
.GlobalReachCard_heading__1-Doz,
.GlobalReachCard_text__GzoSi {
  align-self: stretch;
  position: relative;
}
.GlobalReachCard_heading__1-Doz {
  margin: 0;
  font-size: inherit;
  line-height: 140%;
  font-weight: 700;
  font-family: inherit;
}
.GlobalReachCard_text__GzoSi {
  font-size: 16px;
  line-height: 150%;
}
.GlobalReachCard_content1__Fz9ci,
.GlobalReachCard_contentTop__LfPe- {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.GlobalReachCard_contentTop__LfPe- {
  align-items: center;
  gap: 24px;
}
.GlobalReachCard_card__TdFdo,
.GlobalReachCard_content__DFg1r {
  display: flex;
  justify-content: center;
}
.GlobalReachCard_content__DFg1r {
  flex: 1 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  box-sizing: border-box;
  min-width: 200px;
}
.GlobalReachCard_card__TdFdo {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fefefe;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 0;
  text-align: center;
  font-size: 24px;
  color: #1c1c1c;
  font-family: Roboto;
}
`, "",{"version":3,"sources":["webpack://./src/components/GlobalReachCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;AACd;AACA;;EAEE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;;EAEE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,SAAS;AACX;AACA;EACE,mBAAmB;EACnB,SAAS;AACX;AACA;;EAEE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,SAAO;EACP,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".iconGlobe {\n  width: 80px;\n  position: relative;\n  height: 80px;\n}\n.heading,\n.text {\n  align-self: stretch;\n  position: relative;\n}\n.heading {\n  margin: 0;\n  font-size: inherit;\n  line-height: 140%;\n  font-weight: 700;\n  font-family: inherit;\n}\n.text {\n  font-size: 16px;\n  line-height: 150%;\n}\n.content1,\n.contentTop {\n  align-self: stretch;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 16px;\n}\n.contentTop {\n  align-items: center;\n  gap: 24px;\n}\n.card,\n.content {\n  display: flex;\n  justify-content: center;\n}\n.content {\n  flex: 1;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 32px;\n  box-sizing: border-box;\n  min-width: 200px;\n}\n.card {\n  align-self: stretch;\n  border-radius: 8px;\n  background-color: #fefefe;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: center;\n  padding: 8px 0;\n  text-align: center;\n  font-size: 24px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconGlobe": `GlobalReachCard_iconGlobe__NZYW3`,
	"heading": `GlobalReachCard_heading__1-Doz`,
	"text": `GlobalReachCard_text__GzoSi`,
	"content1": `GlobalReachCard_content1__Fz9ci`,
	"contentTop": `GlobalReachCard_contentTop__LfPe-`,
	"card": `GlobalReachCard_card__TdFdo`,
	"content": `GlobalReachCard_content__DFg1r`
};
export default ___CSS_LOADER_EXPORT___;
