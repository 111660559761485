import {Tooltip, useMediaQuery} from '@mui/material'
import { memo, useCallback, useMemo, useState } from 'react'
import styled, {css} from 'styled-components'

const PodcastContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  cursor: pointer;
  padding: 32px;
  display: flex;
  justify-content: center;
  width: min(1312px, 100vw);
  gap: 24px;
  opacity: 0.5;
  transition: 0.2s all ease;
  &:hover {
    opacity: 1;
    scale: 1.02;
  }
  @media (max-width: 515px) {
    gap: 12px;
  }
`

const PosterImage = styled.img`
  width: 200px;
  height: 200px;
  @media (max-width: 600px) {
    width: 80px;
    height: 80px;
  }
`

const TextContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  * {
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    @media (max-width: 515px) {
      line-height: 100%;
      font-size: 18px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    @media (max-width: 515px) {
      font-size: 14px;
    }
  }
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  img {
    width: 40px;
    height: 40px;
    @media (max-width: 500px) {
      width: 28px;
      height: 28px;
    }
  }
  @media (max-width: 500px) {
    gap: 16px;
  }
`

const SpotifyContainer = styled.div`
  background-color: #444444;
  padding: 64px;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
  transition: 0.5s transform ease;
  display: grid;
  place-items: center;
  ${({ $expand }) => $expand && css`
    transform: translateX(0);
  `}
  @media (max-width: 600px) {
    padding: 32px;
  }
`

const Podcast = ({
  posterSrc,
  title,
  guest,
  description,
  youtubeLink,
  spotifyLink,
  embedFragment,
  comingSoon = false,
}) => {
  const [expand, setExpand] = useState(false)

  const handleToggleExpand = useCallback(() => {
    setExpand(prev => !prev)
  }, [])

  const handleOpenYoutube = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    const fallbackYoutubeLink = 'https://www.youtube.com/@Sofixitcompany'
    window.open(youtubeLink ?? fallbackYoutubeLink, '_blank')
  }, [youtubeLink])

  const handleOpenSpotify = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    const fallbackSpotifyLink = 'https://open.spotify.com/show/6nDDhuQettcx1NhZpSMW9H'
    window.open(spotifyLink ?? fallbackSpotifyLink, '_blank')
  }, [spotifyLink])

  const tooltipTitle = useMemo(() => {
    if (comingSoon) {
      return 'Coming soon!'
    }
    if (!expand) {
      return 'Click to listen'
    }
    return undefined
  }, [comingSoon, expand])

  const mediaQuery715 = useMediaQuery('(max-width: 715px)')

  return (
    <Tooltip 
      title={tooltipTitle}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -40],
              },
            },
          ],
        },
      }}
    >
      <PodcastContainer onClick={handleToggleExpand}>
        {embedFragment && (
          <SpotifyContainer $expand={expand}>
            {embedFragment}
          </SpotifyContainer>
        )}
        <PosterImage
          src={posterSrc}
          alt="Podcast poster"
        />
        <TextContainer>
          <h1>{title}</h1>
          <h3>{guest}</h3>
          {!mediaQuery715 && <p>{description}</p>}
        </TextContainer>
        <LinksContainer>
          <Tooltip title='Listen on Spotify'>
            <img
              src="/spotify-icon.svg"
              alt="Listen on Spotify"
              onClick={handleOpenSpotify}
            />
          </Tooltip>
          <Tooltip title='Listen on YouTube'>
            <img
              src="/youtube-icon.svg"
              alt="Listen on YouTube"
              onClick={handleOpenYoutube}
            />
          </Tooltip>
        </LinksContainer>
      </PodcastContainer>
    </Tooltip>
  )
}

export default memo(Podcast)
