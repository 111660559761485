import { useMemo } from "react";
import TypeCloudEMigration from "./TypeCloudEMigration";
import styles from "./StateDefault.module.css";
import Flipcard from "../addons/Flipcard";

const StateDefault = ({
  icon = '/services.svg',
  heading,
  text,
  fullDescription,
  headingFontWeight = "500",
  onCardClick = (() => {}),
}) => {
  const heading1Style = useMemo(() => {
    return {
      fontWeight: headingFontWeight,
    };
  }, [headingFontWeight]);

  const front = (
    <div className={styles.statedefault} onClick={onCardClick}>
      <div className={styles.content}>
        <div className={styles.contentTop}>
          <div className={styles.services}>
            <div className={styles.services}>
              <TypeCloudEMigration
                itCons={icon}
                typeCloudEMigrationWidth="87px"
                typeCloudEMigrationHeight="80.1px"
                itConsIconWidth="unset"
                itConsIconHeight="80.1px"
                itConsIconAlignSelf="stretch"
                itConsIconOverflow="hidden"
              />
            </div>
          </div>
          <div className={styles.content2}>
            <div className={styles.heading} style={heading1Style}>
              {heading}
            </div>
            <div className={styles.text}>{text}</div>
          </div>
        </div>
      </div>
    </div>
  );

  const back = (
    <div className={styles.statedefault} onClick={onCardClick}>
      <div className={styles.content}>
        <div className={styles.contentTop}>
          <div className={styles.content2}>
            <div className={styles.text}>{fullDescription}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Flipcard
      front={front}
      back={back}
      width={304}
      height={328}
    />
  )
};

export default StateDefault;
