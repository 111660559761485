import { useState } from "react";
import PopUpContact from "./PopUpContact";
import PortalPopup from "./PortalPopup";
import CardStart from "./CardStart";
import styles from "./StartProjectContainer.module.css";
import Jumpscare from "../addons/Jumpscare";

const StartProjectContainer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Jumpscare variant={'bottom'}>
          <CardStart
            cardStartBorder="none"
            cardStartPadding="0"
            headingDisplay="inline-block"
            textDisplay="inline-block"
          />
        </Jumpscare>
        <div className={styles.doYouWantContainer}>
          <Jumpscare>
            <span className={styles.doYou}><b>Looking to initiate a new project, or grappling with challenges in an ongoing one? </b></span>
            <span className={styles.doYou}>
              Our approach is to analyze, design, modernize, and construct, leveraging the diverse expertise of our team.
              Comprising innovative, passionate, and professional individuals, we cater to a wide range of clients -
              from startups and enterprise companies to large agencies. Let us elevate your brand to unprecedented heights
            </span>
          </Jumpscare>
        </div>
      </div>
    </div>
  );
};

export default StartProjectContainer;
