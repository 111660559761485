import TypeCloudEMigration from "./TypeCloudEMigration";
import StylePrimarySmallTrueDar from "./StylePrimarySmallTrueDar";
import styles from "./OutsourceForm.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";

const OutsourceForm = () => {
  const openPopup = useSetPopupState()

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content1}>
            <div className={styles.content2}>
              <b className={styles.heading}>
                <Jumpscare transitionTime={1}>Outsource with Ease</Jumpscare>
              </b>
              <div className={styles.ifYouNeed}>
                <Jumpscare delay={0.75}>If you need to build or expand your project or product team, we
                  can help you,whether you only need one expert or the entire
                  team.</Jumpscare>
              </div>
            </div>
            <div className={styles.servicesWrapper}>
              <Jumpscare delay={1}><TypeCloudEMigration
                itCons="/icon.svg"
                typeCloudEMigrationWidth="87px"
                typeCloudEMigrationHeight="80.1px"
                itConsIconWidth="86.8px"
                itConsIconHeight="75.2px"
                itConsIconAlignSelf="unset"
                itConsIconOverflow="unset"
              /></Jumpscare>
            </div>
            <div className={styles.button}>
              <Jumpscare delay={1.25}><StylePrimarySmallTrueDar
                button="Hire a Specialist"
                stylePrimarySmallTrueDarPosition="absolute"
                stylePrimarySmallTrueDarTop="-1px"
                stylePrimarySmallTrueDarRight="0px"
                stylePrimarySmallTrueDarWidth="unset"
                buttonDisplay="inline-block"
                onClick={() => openPopup({
                  title: "Hire Our Specialist",
                  description: "Reach us today to hire best professionals!"
                })}
              /></Jumpscare>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutsourceForm;
