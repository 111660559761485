import styles from "./StyleTertiarySmallTrueDa2.module.css";

const StyleTertiarySmallTrueDa2 = ({ iconRelume, onClose }) => {
  return (
    <div className={styles.styletertiarySmalltrueDa} onClick={onClose}>
      <img className={styles.iconRelume} alt="" src={iconRelume} />
    </div>
  );
};

export default StyleTertiarySmallTrueDa2;
