import {useState, useMemo, useCallback} from "react";
import PopUpContact from "./PopUpContact";
import PortalPopup from "./PortalPopup";
import StyleSecondarySmallFalse1 from "./StyleSecondarySmallFalse1";
import styles from "./ContainerCTA.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";

const ContainerCTA = ({
  descriptionText,
  businessSolutionsText,
  propBackgroundImage,
}) => {
  const cTAStyle = useMemo(() => {
    return {
      backgroundImage: propBackgroundImage,
    };
  }, [propBackgroundImage]);

  const openPopup = useSetPopupState()

  return (
    <div style={{ width: '100%' }}>
      <Jumpscare
        threshold={0.2}
        variant={'left'}
        transitionDistance={50}
        transitionTime={1}
      >
        <section className={styles.cta} style={cTAStyle}>
          <div className={styles.column}>
            <div className={styles.content}>
              <Jumpscare delay={0.75}>
                <h2 className={styles.heading}>
                  {descriptionText}
                </h2>
              </Jumpscare>
              <Jumpscare delay={1}>
                <div className={styles.text}>{businessSolutionsText}</div>
              </Jumpscare>
            </div>
            <div className={styles.actions}>
              <Jumpscare delay={1.25} threshold={0.2}>
                <StyleSecondarySmallFalse1
                  button="Contact Us"
                  styleSecondarySmallFalseBackgroundColor="transparent"
                  buttonDisplay="inline-block"
                  onClick={() => openPopup ({
                    title: "Transform your business",
                    description: "We are here to help you optimize your IT infrastructure and find the perfect solutions for your business."
                  })}
                />
              </Jumpscare>
            </div>
          </div>
        </section>
      </Jumpscare>
    </div>
  );
};

export default ContainerCTA;
