import styles from "./StyleLinkSmallFalseDark1.module.css";

const StyleLinkSmallFalseDark1 = ({ iconRelume }) => {
  return (
    <div className={styles.stylelinkSmallfalseDark}>
      <img className={styles.iconRelume} alt="" src={iconRelume} />
    </div>
  );
};

export default StyleLinkSmallFalseDark1;
