import { useCallback, useEffect, useMemo, useState } from 'react'

const Carousel = ({
  data,
  dataRepeat = 2,
  speed = 15,
  itemWidth,
  ItemComponent,
}) => {
  const dataRepeated = useMemo(() => {
    const output = []
    for (let i = 0; i < dataRepeat * 2; i++) {
      output.push(...data)
    }
    return output
  }, [data, dataRepeat])

  const rootStyle = useMemo(() => ({
    overflowX: 'hidden',
    width: `${itemWidth * data.length * dataRepeat}px`,
  }), [itemWidth, data.length, dataRepeat])

  const [hover, setHover] = useState(false)
  const handleMouseEnter = useCallback(() => {
    setHover(true)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])

  const [shift, setShift] = useState(0)
  useEffect(() => {
    if (hover) return
    const handler = setInterval(() => {
      setShift(prev => {
        if (prev >= itemWidth * data.length * dataRepeat) {
          return 0
        }
        return prev + speed * 0.03
      })
    }, 30)
    return () => clearInterval(handler)
  }, [data.length, dataRepeat, itemWidth, speed, hover])

  return (
    <div style={rootStyle}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          display: 'flex',
          width: `${itemWidth * data.length * dataRepeat * 2}px`,
          transform: `translateX(-${shift}px)`
        }}
      >
        { dataRepeated.map((item, index) => (
          <ItemComponent
            key={index}
            {...item}
          />
        )) }
      </div>
    </div>
  )
}

export default Carousel