import AgileBestPracticesContainer from "./AgileBestPracticesContainer";
import styles from "./StreamlineForm.module.css";
import Jumpscare from "../addons/Jumpscare";
import Bubble from "../addons/Bubble";
import {useMediaQuery} from "@mui/material";

const StreamlineForm = () => {
  const mediaQuery1440 = useMediaQuery('(max-width: 1439px)')
  const mediaQuery700  = useMediaQuery('(max-width: 700px)')

  return (
    <div className={styles.streamline}>
      <div className={styles.sectionTitle}>
        <div className={styles.subheading}>Efficient</div>
        <div className={styles.content}>
          <b className={styles.heading}>
            <Jumpscare threshold={mediaQuery700 ? 0.3 : 0.9}>
              Streamlined Consulting Approach for Optimal Results
            </Jumpscare>
          </b>
          <div className={styles.text}>
            <Jumpscare threshold={0.9}>
              Our consulting approach follows Agile best practices and emphasizes
              collaboration with client teams. We believe in delivering optimal
              results through streamlined processes.
            </Jumpscare>
          </div>
        </div>
      </div>
      <div className={styles.content1}>
        <div className={styles.row}>
          <Jumpscare variant={mediaQuery1440 ? 'bottom' : 'left'}><AgileBestPracticesContainer
            titleText="Agile Best Practices for Effective Collaboration"
            collaborationDescription="We utilize Agile best practices to ensure effective collaboration with our clients, resulting in successful outcomes."
          /></Jumpscare>
          <Jumpscare variant={'bottom'} delay={mediaQuery1440 ? 0 : 0.25}><AgileBestPracticesContainer
            titleText="Client-Centric Approach for Tailored Solutions"
            collaborationDescription="We prioritize our clients' needs and preferences, offering tailored solutions that align with their goals."
          /></Jumpscare>
          <Jumpscare variant={mediaQuery1440 ? 'bottom' : 'right'} delay={mediaQuery1440 ? 0 : 0.5}><AgileBestPracticesContainer
            titleText="Proven Expertise and Industry Knowledge"
            collaborationDescription="Our trusted team brings extensive expertise and industry knowledge to every consulting project."
          /></Jumpscare>
        </div>
      </div>
      <Jumpscare transitionDistance={0} threshold={mediaQuery700 ? 0.2 : 0.6}>
        <div className={styles.undrawEngineeringTeamA7n2Parent}>
          <img
            className={styles.undrawEngineeringTeamA7n2Icon}
            alt=""
            src="/undraw-engineering-team-a7n2.svg"
          />
          <Bubble>
            <div className={styles.badge}>
              <img className={styles.iconAzure} alt="" src="/icon-azure1@2x.png"/>
            </div>
          </Bubble>
          <Bubble><img className={styles.badgeIcon} alt="" src="/badge@2x.png"/></Bubble>
          <Bubble><img className={styles.badgeIcon1} alt="" src="/badge1@2x.png"/></Bubble>
          <Bubble><img className={styles.badgeIcon2} alt="" src="/badge2@2x.png"/></Bubble>
          <Bubble><img className={styles.badgeIcon3} alt="" src="/badge3@2x.png"/></Bubble>
          <Bubble>
            <div className={styles.badge1}>
              <img className={styles.iconReact} alt="" src="/icon-react1.svg"/>
            </div>
          </Bubble>
          <Bubble><img className={styles.badgeIcon4} alt="" src="/badge4@2x.png"/></Bubble>
          <Bubble>
            <div className={styles.badge2}>
              <img className={styles.iconScala} alt="" src="/icon-scala1.svg"/>
            </div>
          </Bubble>
        </div>
      </Jumpscare>
    </div>
  );
};

export default StreamlineForm;
