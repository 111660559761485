// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StylePrimarySmallFalseDa_button__8yhXm {
  position: relative;
  font-size: 16px;
  line-height: 150%;
  font-family: Roboto;
  color: #fefefe;
  text-align: left;
}
.StylePrimarySmallFalseDa_styleprimarySmallfalseDa__I--Zx {
  cursor: pointer;
  border: 1px solid #1c1c1c;
  padding: 12px 24px;
  background-color: #1c1c1c;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.StylePrimarySmallFalseDa_styleprimarySmallfalseDa__I--Zx:hover {
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    #1c1c1c;
}

@media screen and (max-width: 420px) {
  .StylePrimarySmallFalseDa_styleprimarySmallfalseDa__I--Zx {
    padding: 6px 12px;
  }

  .StylePrimarySmallFalseDa_button__8yhXm {
    font-size: 14px;
  }
}

.StylePrimarySmallFalseDa_styleprimarySmallfalseDa__I--Zx:disabled {
  background-color: grey;
}`, "",{"version":3,"sources":["webpack://./src/components/StylePrimarySmallFalseDa.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE;;;;WAIS;AACX;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".button {\n  position: relative;\n  font-size: 16px;\n  line-height: 150%;\n  font-family: Roboto;\n  color: #fefefe;\n  text-align: left;\n}\n.styleprimarySmallfalseDa {\n  cursor: pointer;\n  border: 1px solid #1c1c1c;\n  padding: 12px 24px;\n  background-color: #1c1c1c;\n  border-radius: 4px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n}\n.styleprimarySmallfalseDa:hover {\n  background: linear-gradient(\n      rgba(255, 255, 255, 0.3),\n      rgba(255, 255, 255, 0.3)\n    ),\n    #1c1c1c;\n}\n\n@media screen and (max-width: 420px) {\n  .styleprimarySmallfalseDa {\n    padding: 6px 12px;\n  }\n\n  .button {\n    font-size: 14px;\n  }\n}\n\n.styleprimarySmallfalseDa:disabled {\n  background-color: grey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `StylePrimarySmallFalseDa_button__8yhXm`,
	"styleprimarySmallfalseDa": `StylePrimarySmallFalseDa_styleprimarySmallfalseDa__I--Zx`
};
export default ___CSS_LOADER_EXPORT___;
