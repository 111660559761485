import TypeCloudEMigration from "./TypeCloudEMigration";
import StylePrimarySmallTrueDar from "./StylePrimarySmallTrueDar";
import styles from "./SeamlessCloudSolutionsContaine.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useSetPopupState} from "../addons/ContactPopup";

const SeamlessCloudSolutionsContaine = () => {
  const openPopup = useSetPopupState()

  return (
    <div className={styles.layout406}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content1}>
            <div className={styles.content2}>
              <b className={styles.heading}>
                <Jumpscare transitionTime={1}>Seamless Cloud Solutions</Jumpscare>
              </b>
              <Jumpscare delay={0.5}>
                <div
                  className={styles.costPerformance}
                >{`Cost  & performance optimization of your IT infrastructure. Discover IaaS and SaaS`}</div>
              </Jumpscare>
            </div>
            <div className={styles.servicesWrapper}>

              <Jumpscare delay={1}><TypeCloudEMigration
                itCons="/it-cons.svg"
                typeCloudEMigrationWidth="unset"
                typeCloudEMigrationHeight="80.1px"
                itConsIconWidth="87px"
                itConsIconHeight="74.9px"
                itConsIconAlignSelf="unset"
                itConsIconOverflow="unset"
              /></Jumpscare>
            </div>
            <Jumpscare delay={1.25}><StylePrimarySmallTrueDar
              onClick={() => openPopup ({
                title: "Get a free quote",
                description: "Contact us right now!"
              })}
              button="Get a free quote"
              stylePrimarySmallTrueDarPosition="unset"
              stylePrimarySmallTrueDarTop="unset"
              stylePrimarySmallTrueDarRight="unset"
              stylePrimarySmallTrueDarWidth="unset"
              buttonDisplay="inline-block"
            /></Jumpscare>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeamlessCloudSolutionsContaine;
