// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgileBestPracticesContainer_heading__IbMr6,
.AgileBestPracticesContainer_text__Rtcvn {
  align-self: stretch;
  position: relative;
  line-height: 140%;
  background: linear-gradient(#1c1c1c, #1c1c1c), #000;
  -webkit-background-clip: text;
}
.AgileBestPracticesContainer_text__Rtcvn {
  font-size: 16px;
  line-height: 150%;
}
.AgileBestPracticesContainer_column__rdOO9,
.AgileBestPracticesContainer_content__yDd98 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.AgileBestPracticesContainer_content__yDd98 {
  align-self: stretch;
  align-items: center;
  gap: 24px;
}
.AgileBestPracticesContainer_column__rdOO9 {
  flex: 1 1;
  overflow: hidden;
  align-items: flex-start;
  min-width: 300px;
  max-width: 405px;
  text-align: center;
  font-size: 24px;
  font-family: Roboto;
}
.AgileBestPracticesContainer_text__Rtcvn {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1C1C1C;
}`, "",{"version":3,"sources":["webpack://./src/components/AgileBestPracticesContainer.module.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,mDAAmD;EACnD,6BAA6B;AAC/B;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;;EAEE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,SAAO;EACP,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".heading,\n.text {\n  align-self: stretch;\n  position: relative;\n  line-height: 140%;\n  background: linear-gradient(#1c1c1c, #1c1c1c), #000;\n  -webkit-background-clip: text;\n}\n.text {\n  font-size: 16px;\n  line-height: 150%;\n}\n.column,\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.content {\n  align-self: stretch;\n  align-items: center;\n  gap: 24px;\n}\n.column {\n  flex: 1;\n  overflow: hidden;\n  align-items: flex-start;\n  min-width: 300px;\n  max-width: 405px;\n  text-align: center;\n  font-size: 24px;\n  font-family: Roboto;\n}\n.text {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  text-align: center;\n  color: #1C1C1C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `AgileBestPracticesContainer_heading__IbMr6`,
	"text": `AgileBestPracticesContainer_text__Rtcvn`,
	"column": `AgileBestPracticesContainer_column__rdOO9`,
	"content": `AgileBestPracticesContainer_content__yDd98`
};
export default ___CSS_LOADER_EXPORT___;
