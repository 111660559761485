import GlobalReachCard from "./GlobalReachCard";
import ConsultationCard from "./ConsultationCard";
import styles from "./WorkProcessContainer.module.css";
import Jumpscare from "../addons/Jumpscare";

const WorkProcessContainer = () => {
  return (
    <section className={styles.workProcess}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.sectionTitle}>
            <div className={styles.sectionTitle}>
              <div className={styles.sectionTitle}>
                <div className={styles.content3}>
                  <Jumpscare>
                  <h2 className={styles.heading}>
                    Check out our work process!
                  </h2>
                  </Jumpscare>
                  <div className={styles.text}>
                    <Jumpscare>
                    <p className={styles.withOurGlobal}>
                      With our global reach and deep expertise in technology
                      optimization, we are the perfect partner to help your
                      business thrive. Our consultative approach ensures that we
                      deliver tailored solutions that meet your unique needs.
                    </p>
                    </Jumpscare>
                    <p className={styles.withOurGlobal}>&nbsp;</p>
                    <Jumpscare>
                    <p className={styles.withOurGlobal}>
                      Our software development process is guided by Agile
                      methodologies and the SDLC framework, ensuring efficient
                      project management and high-quality results.
                    </p>
                    </Jumpscare>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GlobalReachCard
            imageDescription="/-icon-globe.svg"
            featureDescription="Global Reach"
            businessDescription="Expand your business horizons with our global reach and extensive network of partners."
            imageDimensions="/-icon-medal.svg"
            featureIcon="Expertise"
            technologyOptimizationDes="Leverage our deep expertise in technology optimization to stay ahead of the competition."
            propFlexDirection="row"
            propFlexWrap="wrap"
            propFlex="1"
            propFlex1="1"
          />
        </div>
        <ConsultationCard />
      </div>
    </section>
  );
};

export default WorkProcessContainer;
