import styles from "./DeliveringIndustryContainer.module.css";
import Jumpscare from "../addons/Jumpscare";

const DeliveringIndustryContainer = () => {
  return (
    <section className={styles.deliveringIndustry}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Jumpscare>
            <h2 className={styles.heading}>
              Delivering Industry-Leading Solutions with Extensive Technological
              and Domain Knowledge
            </h2>
          </Jumpscare>
          <div className={styles.text}>
            <Jumpscare variant={'left'} threshold={1}>
            <b>
              <span className={styles.span}>2</span>
              <span className={styles.span1}>{`   `}</span>
            </b>
            <span className={styles.locationsInPoland}>
              Locations in Poland
            </span>
            </Jumpscare>
          </div>
          <div className={styles.text}>
            <Jumpscare variant={'left'} threshold={1}>
            <b>
              <span className={styles.span}>6</span>
              <span className={styles.span1}>{`   `}</span>
            </b>
            <span className={styles.locationsInPoland}>
              Scrum teams developing product for customers
            </span>
            </Jumpscare>
          </div>
          <div className={styles.text2}>
            <Jumpscare variant={'left'} threshold={1}>
            <b>
              <span className={styles.span4}>60</span>
              <span className={styles.span5}>{` `}</span>
            </b>
            <span className={styles.highlySkilledItConsultants}>
              <span className={styles.span6}>{` `}</span>
              <span className={styles.highlySkilledIt}>
                Highly skilled It consultants
              </span>
            </span>
            </Jumpscare>
          </div>
        </div>
        <Jumpscare variant={'right'}>
          <img className={styles.imageIcon} alt="" src="/image@2x.png"/>
        </Jumpscare>
      </div>
    </section>
  );
};

export default DeliveringIndustryContainer;
