// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StyleSecondarySmallFalse1_button__uDKwp {
  position: relative;
  line-height: 150%;
}
.StyleSecondarySmallFalse1_stylesecondarySmallfalse__QfEZ2 {
  border-radius: 4px;
  border: 1px solid #fefefe;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  text-align: left;
  font-size: 16px;
  color: #fefefe;
  font-family: Roboto;
  transition: 0.1s background-color ease-in-out;
}

.StyleSecondarySmallFalse1_stylesecondarySmallfalse__QfEZ2:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/StyleSecondarySmallFalse1.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,qDAAqD;EACrD,eAAe;AACjB","sourcesContent":[".button {\n  position: relative;\n  line-height: 150%;\n}\n.stylesecondarySmallfalse {\n  border-radius: 4px;\n  border: 1px solid #fefefe;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 12px 24px;\n  text-align: left;\n  font-size: 16px;\n  color: #fefefe;\n  font-family: Roboto;\n  transition: 0.1s background-color ease-in-out;\n}\n\n.stylesecondarySmallfalse:hover {\n  background-color: rgba(255, 255, 255, 0.3) !important;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `StyleSecondarySmallFalse1_button__uDKwp`,
	"stylesecondarySmallfalse": `StyleSecondarySmallFalse1_stylesecondarySmallfalse__QfEZ2`
};
export default ___CSS_LOADER_EXPORT___;
