// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CareerBusinessAnalystWeb_businessAnalyst__pxgWp,
.CareerBusinessAnalystWeb_subheading__vQ4iJ {
  align-self: stretch;
  position: relative;
}
.CareerBusinessAnalystWeb_subheading__vQ4iJ {
  line-height: 150%;
  font-weight: 600;
}
.CareerBusinessAnalystWeb_businessAnalyst__pxgWp {
  line-height: 120%;
}
.CareerBusinessAnalystWeb_salary15k20kPln__jisqS {
  margin: 0;
}
.CareerBusinessAnalystWeb_content__nhA1\\+,
.CareerBusinessAnalystWeb_text__OlpM1 {
  align-self: stretch;
}
.CareerBusinessAnalystWeb_text__OlpM1 {
  position: relative;
  font-size: 18px;
  line-height: 150%;
}
.CareerBusinessAnalystWeb_content__nhA1\\+ {
  align-items: center;
  gap: 24px;
  font-size: 48px;
}
.CareerBusinessAnalystWeb_careerBusinessAnalystWeb__y0Hmk,
.CareerBusinessAnalystWeb_content__nhA1\\+,
.CareerBusinessAnalystWeb_header__sTpA1,
.CareerBusinessAnalystWeb_sectionTitle__PYNgJ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.CareerBusinessAnalystWeb_sectionTitle__PYNgJ {
  align-self: stretch;
  align-items: center;
  gap: 32px;
  min-width: 300px;
}
.CareerBusinessAnalystWeb_careerBusinessAnalystWeb__y0Hmk,
.CareerBusinessAnalystWeb_header__sTpA1 {
  overflow: hidden;
}
.CareerBusinessAnalystWeb_header__sTpA1 {
  align-self: stretch;
  background-color: #f6f6f6;
  align-items: center;
  padding: 80px 32px;
}
.CareerBusinessAnalystWeb_careerBusinessAnalystWeb__y0Hmk {
  width: 100%;
  position: relative;
  background-color: #fff;
  align-items: flex-start;
  text-align: center;
  font-size: 16px;
  color: #1c1c1c;
  font-family: Roboto;
}

@media screen and (max-width: 499px) {
  .CareerBusinessAnalystWeb_businessAnalyst__pxgWp {
    font-size: 36px;
    line-height: 43.2px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/CareerBusinessAnalystWeb.module.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,SAAS;AACX;AACA;;EAEE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;AACA;;;;EAIE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;AACA;;EAEE,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;EACrB;AACF","sourcesContent":[".businessAnalyst,\n.subheading {\n  align-self: stretch;\n  position: relative;\n}\n.subheading {\n  line-height: 150%;\n  font-weight: 600;\n}\n.businessAnalyst {\n  line-height: 120%;\n}\n.salary15k20kPln {\n  margin: 0;\n}\n.content,\n.text {\n  align-self: stretch;\n}\n.text {\n  position: relative;\n  font-size: 18px;\n  line-height: 150%;\n}\n.content {\n  align-items: center;\n  gap: 24px;\n  font-size: 48px;\n}\n.careerBusinessAnalystWeb,\n.content,\n.header,\n.sectionTitle {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.sectionTitle {\n  align-self: stretch;\n  align-items: center;\n  gap: 32px;\n  min-width: 300px;\n}\n.careerBusinessAnalystWeb,\n.header {\n  overflow: hidden;\n}\n.header {\n  align-self: stretch;\n  background-color: #f6f6f6;\n  align-items: center;\n  padding: 80px 32px;\n}\n.careerBusinessAnalystWeb {\n  width: 100%;\n  position: relative;\n  background-color: #fff;\n  align-items: flex-start;\n  text-align: center;\n  font-size: 16px;\n  color: #1c1c1c;\n  font-family: Roboto;\n}\n\n@media screen and (max-width: 499px) {\n  .businessAnalyst {\n    font-size: 36px;\n    line-height: 43.2px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"businessAnalyst": `CareerBusinessAnalystWeb_businessAnalyst__pxgWp`,
	"subheading": `CareerBusinessAnalystWeb_subheading__vQ4iJ`,
	"salary15k20kPln": `CareerBusinessAnalystWeb_salary15k20kPln__jisqS`,
	"content": `CareerBusinessAnalystWeb_content__nhA1+`,
	"text": `CareerBusinessAnalystWeb_text__OlpM1`,
	"careerBusinessAnalystWeb": `CareerBusinessAnalystWeb_careerBusinessAnalystWeb__y0Hmk`,
	"header": `CareerBusinessAnalystWeb_header__sTpA1`,
	"sectionTitle": `CareerBusinessAnalystWeb_sectionTitle__PYNgJ`
};
export default ___CSS_LOADER_EXPORT___;
