import { useMemo } from "react";
import styles from "./StyleTertiarySmallTrueDa1.module.css";

const StyleTertiarySmallTrueDa1 = ({
  button,
  styleTertiarySmallTrueDaBorder,
  styleTertiarySmallTrueDaBackgroundColor,
  buttonDisplay,
  onButton1Click,
}) => {
  const styleTertiarySmallTrueDaStyle = useMemo(() => {
    return {
      border: styleTertiarySmallTrueDaBorder,
      backgroundColor: styleTertiarySmallTrueDaBackgroundColor,
    };
  }, [styleTertiarySmallTrueDaBorder, styleTertiarySmallTrueDaBackgroundColor]);

  const button3Style = useMemo(() => {
    return {
      display: buttonDisplay,
    };
  }, [buttonDisplay]);

  return (
    <div
      className={styles.styletertiarySmalltrueDa}
      style={styleTertiarySmallTrueDaStyle}
      onClick={onButton1Click}
    >
      <div className={styles.button} style={button3Style}>
        {button}
      </div>
    </div>
  );
};

export default StyleTertiarySmallTrueDa1;
