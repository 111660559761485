import styles from "./ContainerCompanies.module.css";
import Carousel from "../addons/Carousel";
import styled from "styled-components";
import {useMediaQuery} from "@mui/material";

const companyImages = [
  { className: styles.image3054Icon, alt: "", src: "/image-3054@2x.png" },
  { className: styles.imageIcon, alt: "", src: "/gestamp-logo.svg" },
  { className: styles.image3057Icon, alt: "", src: "/image-3057@2x.png" },
  { className: styles.image3058Icon, alt: "", src: "/image-3058@2x.png" },
  { className: styles.image3056Icon, alt: "", src: "/image-3056@2x.png" },
  { className: styles.image3055Icon, alt: "", src: "/image-3055@2x.png" },
  { className: styles.imageIcon, alt: "", src: "/my-harmony-logo.svg" }
]


const ContainerCompanies = () => {
  const mediaQuery550 = useMediaQuery('(max-width: 549px)')

  return (
    <section className={styles.companies}>
      <div className={styles.heading}>
        <div className={styles.heading1}>
          Preferred by leading multinational corporations
        </div>
      </div>
      {/* <div className={styles.content}>
        <img className={styles.image3054Icon} alt="" src="/image-3054@2x.png" />
        <img className={styles.image3055Icon} alt="" src="/image-3055@2x.png" />
        <img className={styles.image3056Icon} alt="" src="/image-3056@2x.png" />
        <img className={styles.image3057Icon} alt="" src="/image-3057@2x.png" />
        <img className={styles.image3058Icon} alt="" src="/image-3058@2x.png" />
      </div> */}
      { mediaQuery550 ? (
        <MobileCompanies />
      ) : (
        <Carousel
          data={companyImages}
          dataRepeat={4}
          itemWidth={250}
          ItemComponent={CompanyImage}
        />
      ) }
    </section>
  );
};

export default ContainerCompanies;

const MobileCompanies = () => {
  return (
    <MobileRoot>
      { companyImages.map((props, index) => (
        <img
          key={index}
          {...props}
        />
      )) }
    </MobileRoot>
  )
}

const MobileRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  padding: 0 20px;
  
  img {
    padding: 6px;
    height: 60px;
    width: auto;
  }
`

const CompanyImage = (props) => {
  return (
    <ImageWrapper>
      <img {...props} />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  width: 250px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
