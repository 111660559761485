import styled from 'styled-components'
import {useEffect, useRef, useState} from 'react'

export const JumpscareVariant = Object.freeze({
  BOTTOM: 'bottom',
  LEFT:   'left',
  RIGHT:  'right',
  UP:     'up'
})

const Jumpscare = ({
                     children,
                     delay = 0.0,
                     transitionTime = 0.5,
                     transitionDistance = 12,
                     // threshold = 0.5,
                     threshold = 0.3,
                     variant = JumpscareVariant.BOTTOM,
                     stretch = false
                   }) => {
  const ref = useRef()
  const [entered, setEntered] = useState(false)

  useEffect(() => {
    if (!ref.current) return

    const observer = new window.IntersectionObserver(([entry], observer) => {
      if (!entry.isIntersecting) return
      setEntered(true)
      observer.unobserve(entry.target)
      observer.disconnect()
    }, { root: null, threshold })

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref.current, threshold])

  return (
    <Wrapper
      ref={ref}
      $entered={entered}
      $delay={delay}
      $transitionTime={transitionTime}
      $transitionDistance={transitionDistance}
      $variant={variant}
      $stretch={stretch}
    >
      {children}
    </Wrapper>
  )
}

export default Jumpscare

const Wrapper = styled.div`
  opacity: ${({ $entered }) => $entered ? 1 : 0};
  transform: ${props => {
    if (props.$entered) return 'translate(0)'
    const [translate, sign] = (() => {
      switch (props.$variant) {
        case JumpscareVariant.BOTTOM:
          return ['translateY', '' ]
        case JumpscareVariant.UP:
          return ['translateY', '-']
        case JumpscareVariant.LEFT:
          return ['translateX', '-']
        default:
          return ['translateX', '' ]
      }
    })()
    return `${translate}(${sign}${props.$transitionDistance}px)`
  }};

  ${({ $transitionTime, $delay }) => `
    transition: all ${$transitionTime}s ease-out ${$delay}s;
  `}
  
  ${({ $stretch }) => $stretch && `
    width: 100%;
  `}
`