import { useMemo } from "react";
import styles from "./StylePrimarySmallFalseDa.module.css";

const StylePrimarySmallFalseDa = ({
  button,
  stylePrimarySmallFalseDaAlignSelf,
  onClick = (() => {}),
  disabled = false,
}) => {
  const stylePrimarySmallFalseDaStyle = useMemo(() => {
    return {
      alignSelf: stylePrimarySmallFalseDaAlignSelf,
    };
  }, [stylePrimarySmallFalseDaAlignSelf]);

  return (
    <button
      className={styles.styleprimarySmallfalseDa}
      style={stylePrimarySmallFalseDaStyle}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.button}>{button}</div>
    </button>
  );
};

export default StylePrimarySmallFalseDa;
