import styles from "./EngagementContainer.module.css";

const EngagementContainer = ({
  serviceDescription,
  clientEngagementDescripti,
}) => {
  return (
    <div className={styles.column}>
      <div className={styles.content}>
        <b className={styles.heading}>{serviceDescription}</b>
        <div className={styles.text}>{clientEngagementDescripti}</div>
      </div>
    </div>
  );
};

export default EngagementContainer;
