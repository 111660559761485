import styled, {css} from 'styled-components'
import { useState, useCallback, Children, useEffect } from 'react'

const Slideshow = ({
                     children,
                     slideWidth,
                     slideHeight,
                     GoBackComponent    = DefaultGoBack,
                     GoForwardComponent = DefaultGoForward,
                     ControlsComponent  = DefaultControls,
                     bounds             = true,
                     softness           = 60,
                     transitionTime     = 0.5,
                     autoSlideTime      = 3
                   }) => {
  const childrenLength = Children.count(children)

  const [pointer, setPointer] = useState(0)
  const handleGoBack = useCallback(() => {
    setPointer(prev => {
      const next = prev - 1
      if (next < 0 &&  bounds) return 0
      if (next < 0 && !bounds) return childrenLength - 1
      return next
    })
  }, [childrenLength, bounds])
  const handleGoForward = useCallback(() => {
    setPointer(prev => {
      const next = prev + 1
      if (next >= childrenLength &&  bounds) return childrenLength - 1
      if (next >= childrenLength && !bounds) return 0
      return next
    })
  }, [childrenLength, bounds])

  const [mouseEntered, setMouseEntered] = useState(false)
  const handleMouseEnter = useCallback(() => {
    setMouseEntered(true)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setMouseEntered(false)
  }, [])

  useEffect(() => {
    if (mouseEntered) return
    const handle = setInterval(() => {
      setPointer(prev => prev + 1 >= childrenLength ? 0 : prev + 1)
    }, autoSlideTime * 1000)
    return () => clearInterval(handle)
  }, [mouseEntered, childrenLength, autoSlideTime])

  const offset = pointer * slideWidth

  return (
    <Window
      $width={slideWidth}
      $height={slideHeight}
      $softness={softness}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tape
        $width={slideWidth * childrenLength}
        $height={slideHeight}
        $offset={offset}
        $transitionTime={transitionTime}
      >
        {children}
      </Tape>
      <GoBackComponent    onClick={handleGoBack}    />
      <GoForwardComponent onClick={handleGoForward} />
      <ControlsComponent
        onClick={setPointer}
        childrenCount={childrenLength}
        pointer={pointer}
      />
    </Window>
  )
}

export default Slideshow

const Tape = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  height: 100%;
  position: absolute;
  transition-duration: ${({ $transitionTime }) => $transitionTime}s;
  transition-property: left;
  transition-timing-function: ease-out;
  left: ${({ $offset }) => -$offset}px;
`

const Window = styled.div`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;

  overflow-x: hidden;
  position: relative;

  &:before, &:after {
    content: "";
    position: absolute;
    display: block;
    z-index: 1;
    top: 0; bottom: 0;
    width: ${({ $softness }) => $softness}px;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, white 0%, transparent 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, white 0%, transparent 100%);
  }
`

const DefaultGoBack = ({ onClick }) => {
  return (
    <DefaultButton
      onClick={onClick}
      $orientation='left'
    >
      Prev
    </DefaultButton>
  )
}

const DefaultGoForward = ({ onClick }) => {
  return (
    <DefaultButton
      onClick={onClick}
      $orientation='right'
    >
      Next
    </DefaultButton>
  )
}

const DefaultButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ $orientation }) => $orientation === 'left'
  ? 'left: 0'
  : 'right: 0'
};
  border: none;
  z-index: 2;
  padding: 3px 5px;
`

const DefaultControls = ({ onClick, childrenCount, pointer }) => {
  return (
    <DefaultControlsRoot>
      { Array(childrenCount).fill(null).map((_, index) => (
        <DefaultControlsPiece
          key={index}
          onClick={() => onClick(index)}
          $active={pointer === index}
        >{index + 1}</DefaultControlsPiece>
      )) }
    </DefaultControlsRoot>
  )
}

const DefaultControlsPiece = styled.a`
  text-decoration: underline;
  margin: 5px;
  cursor: pointer;
  ${({ $active }) => $active && css`
    color: orange;
  `}
`

const DefaultControlsRoot = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
  display: flex;
  flex-direction: row;
`
