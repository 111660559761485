import TypeCloudEMigration from "./TypeCloudEMigration";
import styles from "./ProductDevelopmentContainer.module.css";
import Jumpscare from "../addons/Jumpscare";
import {useMediaQuery} from "@mui/material";

const ProductDevelopmentContainer = () => {
  const mediaQuery1440 = useMediaQuery('(max-width: 1439px)')

  const mediaQuery500 = useMediaQuery('(max-width: 500px)')

  return (
    <div className={styles.header}>
      <div className={styles.sectionTitle}>
        <div className={styles.content}>
          <Jumpscare transitionTime={1}>
            <b className={styles.heading}>
              <p className={styles.yourBusinessOurExpertiseI}>
                <span>{`Your business, our expertise in `}</span>
                <span className={styles.productDevelopment}>
                  Product Development
                </span>
                <span className={styles.span}>.</span>
              </p>
              <p className={styles.yourBusinessOurExpertiseI}>
              Let's build together!
            </p>
            </b>
          </Jumpscare>
          <div className={styles.textDuisCursus}>
            {mediaQuery500 ? (
              <Jumpscare delay={0.5}>
                We specialize in tailored IT product development services,
                crafting custom software solutions to meet your unique
                business needs.
              </Jumpscare>
            ) : (
              <Jumpscare delay={0.5}>
                We specialize in tailored IT product development services, crafting
                custom software solutions to meet your unique business needs.
                Leverage our expertise to optimize your technology and reach your
                business goals.
              </Jumpscare>
            )}
          </div>
        </div>
      </div>
      <Jumpscare delay={mediaQuery1440 ? 1 : 3.25}>
        <TypeCloudEMigration
          itCons="/services.svg"
          typeCloudEMigrationWidth="87px"
          typeCloudEMigrationHeight="unset"
          itConsIconWidth="unset"
          itConsIconHeight="80.1px"
          itConsIconAlignSelf="stretch"
          itConsIconOverflow="hidden"
        /></Jumpscare>
      <div className={styles.content1}>
        <div className={styles.tabs}>
          <div className={styles.tab}>
            <div className={styles.heading1}>
              <Jumpscare delay={mediaQuery1440 ? 0 : 1}>
                Agile Development Approach
              </Jumpscare>
            </div>
            <div className={styles.text}>
              <Jumpscare delay={mediaQuery1440 ? 0 : 2.5} variant={mediaQuery1440 ? 'bottom' : 'left'}>
                We follow Agile principles to ensure efficient and collaborative
                software development processes.
              </Jumpscare>
            </div>
          </div>
          <div className={styles.tab}>
            <div className={styles.heading1}>
              <Jumpscare delay={mediaQuery1440 ? 0 : 1.5}>
                Expert Team of Developers
              </Jumpscare>
            </div>
            <div className={styles.text}>
              <Jumpscare delay={mediaQuery1440 ? 0 : 2.75} variant={'bottom'}>
                Our team of skilled developers has extensive experience in
                building high-quality software solutions.
              </Jumpscare>
            </div>
          </div>
          <div className={styles.tab}>
            <div className={styles.heading1}>
              <Jumpscare delay={mediaQuery1440 ? 0 : 2}>
                Custom Software Development
              </Jumpscare>
            </div>
            <div className={styles.text}>
              <Jumpscare delay={mediaQuery1440 ? 0 : 3} variant={mediaQuery1440 ? 'bottom' : 'right'}>
                We specialize in creating innovative and scalable software
                solutions that drive business growth.
              </Jumpscare>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDevelopmentContainer;
